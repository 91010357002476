import React from "react";

import {
  MemberFormCreateView,
  MemberFormUpdateView
} from "../../../modules/member/form";

import { MemberCardView } from "../../../modules/member/card";
import { Secured } from "../../secure/Secured";

export const PageMember = props => (
  <Secured {...props}>
    <MemberCardView inverted={true} {...props} />
  </Secured>
);

export const PageCreateMember = props => (
  <Secured {...props}>
    <MemberFormCreateView {...props} />
  </Secured>
);

export const PageEditMember = props => (
  <Secured {...props}>
    <MemberFormUpdateView prefixCode="IIS" inverted={true} {...props} />
  </Secured>
);

export default PageMember;
