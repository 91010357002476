import React from "react";
import { Segment, List, Grid } from "semantic-ui-react";
import { Logo } from "../logo";
import { AppContext } from "../../App";
import { federations } from "../../utils/federationFooter";

export class Footer extends React.Component {
  constructor({ inverted = false, federationId }) {
    super();
    this.state = { inverted, federationId };
  }

  render() {
    const { inverted, federationId } = this.state;
    let integrityPolicy, mail, address, addressLink;
    if (federations && federations[federationId]) {
      const config = federations[federationId];
      integrityPolicy = config.integrityPolicy;
      mail = config.mail;
      address = config.address;
      addressLink = config.addressLink;
    }
    return (
      <AppContext.Consumer>
        {app => (
          <Segment
            inverted={inverted}
            className="footer"
            vertical
            style={{ position: "absolute", bottom: "0", width: "100%" }}
          >
            <Grid padded stackable columns={1}>
              <Grid.Row>
                <Grid.Column width={2} />
                <Grid.Column
                  width={12}
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                >
                  <List
                    inverted={inverted}
                    horizontal
                    divided
                    link
                    style={{ width: "100%", textAlign: "center" }}
                    verticalAlign="middle"
                  >
                    <List.Item as="a" href={integrityPolicy} target="blank">
                      Integritetspolicy
                    </List.Item>
                    <List.Item as="a" href={`mailto:${mail}`}>
                      {mail}
                    </List.Item>
                    <List.Item as="a" href={addressLink} target="blank">
                      {address}
                    </List.Item>
                    <List.Item
                      as="a"
                      href="https://internetstiftelsen.se/"
                      target="_blank"
                    >
                      <List.Content
                        verticalAlign="middle"
                        content={<Logo tiny />}
                      />
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={2} />
              </Grid.Row>
            </Grid>
          </Segment>
        )}
      </AppContext.Consumer>
    );
  }
}
