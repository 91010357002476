import { call, put } from "redux-saga/effects";

import {
  uploadMetadataApi,
  fetchMetadataApi,
  fetchMetadataValidateApi,
  updateMetadataApi,
  deleteMetadataApi,
  approveMetadataApi,
  fetchMemberMetadataListApi,
  fetchFederationMetadataListApi,
  fetchFederationMDCountApi
} from "./api";

import {
  loadMemberMetadataListSuccess,
  loadMemberMetadataListFailure,
  loadFederationMetadataListSuccess,
  loadFederationMetadataListFailure,
  fetchFederationMDCountSuccess,
  fetchFederationMDCountFailure,
  uploadMetadataSuccess,
  uploadMetadataFailure,
  loadMetadataSuccess,
  loadMetadataFailure,
  loadMetadataValidateSuccess,
  loadMetadataValidateFailure,
  updateMetadataSuccess,
  updateMetadataFailure,
  deleteMetadataSuccess,
  deleteMetadataFailure,
  approveMetadataSuccess,
  approveMetadataFailure
} from "../../ducks/metadata/actions";

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

// FETCH_MEMBER_METADATA_LIST
function* fetchMemberMetadataList(action) {
  const { federationId, customerId } = action;
  const { resp, error } = yield call(fetchMemberMetadataListApi, {
    federationId,
    customerId
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      loadMemberMetadataListSuccess({
        data
      })
    );
  } else if (error) {
    logError("fetchMemberMetadataList", error);
    yield put(
      loadMemberMetadataListFailure({
        error: yield error
      })
    );
  }
}

// FETCH_FEDERATION_METADATA_LIST
function* fetchFederationMetadataList(action) {
  const { federationId } = action;
  const { resp, error } = yield call(fetchFederationMetadataListApi, {
    federationId
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      loadFederationMetadataListSuccess({
        data
      })
    );
  } else if (error) {
    logError("fetchFederationMetadataList", error);
    yield put(
      loadFederationMetadataListFailure({
        error: yield error
      })
    );
  }
}

// UPLOAD_METADATA
function* uploadMetadata(action) {
  const { federationId, customerId, formData, publishFile } = action;
  const { resp, error } = yield call(uploadMetadataApi, {
    federationId,
    customerId,
    formData,
    publishFile
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      uploadMetadataSuccess({
        customerId,
        data,
        filePublished: publishFile
      })
    );
  } else if (error) {
    logError("uploadMetadata", error);
    yield put(
      uploadMetadataFailure({
        customerId,
        error: yield error,
        filePublished: publishFile
      })
    );
  }
}

// FETCH_METADATA
function* fetchMetadata(action) {
  const { federationId, customerId, entityId } = action;
  const { resp, error } = yield call(fetchMetadataApi, {
    federationId,
    customerId,
    entityId
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      loadMetadataSuccess({
        customerId,
        entityId,
        data
      })
    );
  } else if (error) {
    logError("loadMetadata", error);
    yield put(
      loadMetadataFailure({
        customerId,
        entityId,
        error: yield error
      })
    );
  }
}

function* fetchMetadataValidate(action) {
  const { federationId, customerId, entityId, approval } = action;
  const { resp, error } = yield call(fetchMetadataValidateApi, {
    federationId,
    customerId,
    entityId,
    approval
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      loadMetadataValidateSuccess({
        customerId,
        entityId,
        data
      })
    );
  } else if (error) {
    logError("loadMetadata", error);
    yield put(
      loadMetadataValidateFailure({
        customerId,
        entityId,
        error: yield error
      })
    );
  }
}

// UPDATE_METADATA
function* updateMetadata(action) {
  const { federationId, customerId, entityId, active } = action;
  const { resp, error } = yield call(updateMetadataApi, {
    federationId,
    customerId,
    entityId,
    active
  });
  if (resp) {
    // const data = yield resp.json();
    yield put(
      updateMetadataSuccess({
        entityId,
        active
      })
    );
  } else if (error) {
    logError("updateMetadata", error);
    yield put(
      updateMetadataFailure({
        entityId,
        error: yield error
      })
    );
  }
}

// DELETE_METADATA
function* deleteMetadata(action) {
  const { federationId, customerId, entityId, approved } = action;
  const { resp, error } = yield call(deleteMetadataApi, {
    federationId,
    customerId,
    entityId,
    approved
  });
  if (resp) {
    yield put(
      deleteMetadataSuccess({
        entityId
      })
    );
  } else if (error) {
    logError("deleteMetadata", error);
    yield put(
      deleteMetadataFailure({
        entityId,
        error: yield error
      })
    );
  }
}

// APPROVE_METADATA
function* approveMetadata(action) {
  const { federationId, entityId, approve, message } = action;
  const { resp, error } = yield call(approveMetadataApi, {
    federationId,
    entityId,
    approve,
    message
  });
  if (resp) {
    yield put(
      approveMetadataSuccess({
        entityId
      })
    );
  } else if (error) {
    logError("approveMetadata", error);
    yield put(
      approveMetadataFailure({
        entityId,
        error: yield error
      })
    );
  }
}

// FETCH_FEDERATION_MD_COUNT
function* fetchFederationMDCount(action) {
  const { federationId } = action;
  const { resp, error } = yield call(fetchFederationMDCountApi, {
    federationId
  });
  if (resp) {
    const mdata = yield resp.json();
    yield put(
      fetchFederationMDCountSuccess({
        mdata
      })
    );
  } else if (error) {
    logError("fetchFederationMDCount", error);
    yield put(
      fetchFederationMDCountFailure({
        error: yield error
      })
    );
  }
}

export {
  fetchMemberMetadataList,
  fetchFederationMetadataList,
  fetchFederationMDCount,
  fetchMetadataValidate,
  uploadMetadata,
  fetchMetadata,
  updateMetadata,
  deleteMetadata,
  approveMetadata
};
