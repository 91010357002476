import {
  LOAD_RESELLERS_SUCCESS,
  LOAD_RESELLERS_FAILURE,
  LOAD_RESELLER_SUCCESS,
  LOAD_RESELLER_FAILURE,
  LOAD_RESELLER_USERS_SUCCESS,
  LOAD_RESELLER_USERS_FAILURE,
  CREATE_RESELLER_SUCCESS,
  CREATE_RESELLER_FAILURE,
  UPDATE_RESELLER_SUCCESS,
  UPDATE_RESELLER_FAILURE,
  DELETE_RESELLER_SUCCESS,
  DELETE_RESELLER_FAILURE,
  CREATE_RESELLER_USER_SUCCESS,
  CREATE_RESELLER_USER_FAILURE,
  DELETE_RESELLER_USER_SUCCESS,
  DELETE_RESELLER_USER_FAILURE,
  UPDATE_RESELLER_USER_SUCCESS,
  UPDATE_RESELLER_USER_FAILURE
} from "./actionTypes";

import {
  loadSuccessReducer,
  loadFailureReducer,
  loadSuccessReducerById,
  loadFailureReducerById,
  updateSuccessReducerById,
  updateFailureReducerById,
  deleteFailureReducerById,
  deleteSuccessReducerById,
  createFailureReducer,
  createSuccessReducer,
  createFailureReducerById,
  createSuccessReducerById
} from "../common/reducer";

export const resellersFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_RESELLERS_SUCCESS:
      return loadSuccessReducer(state, action);
    case LOAD_RESELLERS_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const resellerFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_RESELLER_SUCCESS:
      return loadSuccessReducerById(state, action.prefixCode, action);
    case LOAD_RESELLER_FAILURE:
      return loadFailureReducerById(state, action.prefixCode, action);
    default:
      return state;
  }
};

export const resellerCreateResp = (state = {}, action) => {
  switch (action.type) {
    case CREATE_RESELLER_SUCCESS:
      return createSuccessReducerById(state, action.prefixCode, action);
    case CREATE_RESELLER_FAILURE:
      return createFailureReducerById(state, action.prefixCode, action);
    default:
      return state;
  }
};

export const resellerUpdateResp = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RESELLER_SUCCESS:
      return updateSuccessReducerById(state, action.prefixCode, action);
    case UPDATE_RESELLER_FAILURE:
      return updateFailureReducerById(state, action.prefixCode, action);
    default:
      return state;
  }
};

export const resellerDeleteResp = (state = {}, action) => {
  switch (action.type) {
    case DELETE_RESELLER_SUCCESS:
      return deleteSuccessReducerById(state, action.prefixCode, action);
    case DELETE_RESELLER_FAILURE:
      return deleteFailureReducerById(state, action.prefixCode, action);
    default:
      return state;
  }
};

export const resellerUsersFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_RESELLER_USERS_SUCCESS:
      return loadSuccessReducer(state, action);
    case LOAD_RESELLER_USERS_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const resellerUserCreateResp = (state = {}, action) => {
  switch (action.type) {
    case CREATE_RESELLER_USER_SUCCESS:
      return createSuccessReducer(state, action);
    case CREATE_RESELLER_USER_FAILURE:
      return createFailureReducer(state, action);
    default:
      return state;
  }
};

export const resellerUserDeleteResp = (state = {}, action) => {
  switch (action.type) {
    case DELETE_RESELLER_USER_SUCCESS:
      return deleteSuccessReducerById(state, action.userId, action);
    case DELETE_RESELLER_USER_FAILURE:
      return deleteFailureReducerById(state, action.userId, action);
    default:
      return state;
  }
};

export const resellerUserUpdateResp = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_RESELLER_USER_SUCCESS:
      return updateSuccessReducerById(state, action.userId, action);
    case UPDATE_RESELLER_USER_FAILURE:
      return updateFailureReducerById(state, action.userId, action);
    default:
      return state;
  }
};
