import React from "react";
import { Route, Switch } from "react-router-dom";

import { ResellerEditFormView } from "../form";
import { ResellerCreateView } from "../form";
import { ResellersTableView } from "../list/ResellersTableView";

import { keycloakInstance } from "../../../keycloak";

export const ResellerRouts = props => {
  const keycloak = keycloakInstance && keycloakInstance.keycloak;
  const federationId = keycloak && keycloak.realm;
  const baseUrl = `/federation/${federationId}/resellers`;
  return (
    <React.Fragment>
      <Switch>
        <Route
          path={`${baseUrl}/list`}
          render={() => <ResellersTableView {...props} />}
        />
        <Route
          path={`${baseUrl}/:prefixCode/edit/`}
          render={routeState => (
            <ResellerEditFormView
              prefixCode={routeState.match.params.prefixCode}
              federationId={federationId}
              {...props}
            />
          )}
        />
        <Route path={`${baseUrl}/create/`}>
          <ResellerCreateView {...props} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

export default ResellerRouts;
