import React from "react";
import PropTypes from "prop-types";
import { Header, Container, Tab, Message, Loader } from "semantic-ui-react";
import { MemberDeleteView } from "../form/MemberDeleteView";
import { MemberActivateView } from "../form/MemberActivateView";
import { JsonView } from "../../../helpers/json/JsonView";
import { MemberFormEditView } from "../form/MemberFormEditView";
import { TechnicalContactFormView } from "../form/TechnicalContactFormView";
import { StatusPanel } from "./StatusPanel";

import { authorize } from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";

import { ErrorMessage } from "../../../common/error";
import { federationInfoRespState } from "../../metadata/federationInfoMapper";

const panes = [
  {
    menuItem: "METADATA"
  },
  {
    menuItem: "GENERAL INFORMATION"
  },
  {
    menuItem: "TECHNICAL CONTACT"
  },
  {
    menuItem: "TECHNICAL AGENTS"
  }
];

export class MemberCardView extends React.PureComponent {
  constructor({ customerId }) {
    super();
    this.state = {
      customerId,
      activeIndex: 1,
      panes,
      validationError: false,
      loadMetadata: false,
      federationType: ""
    };
    this.handleMemberFormSubmit = this.handleMemberFormSubmit.bind(this);
    this.handleTechnicalContactSubmit = this.handleTechnicalContactSubmit.bind(
      this
    );
    this.handleTechnicalAgentsTableReload = this.handleTechnicalAgentsTableReload.bind(
      this
    );
  }

  componentDidMount() {
    const { customerId } = this.state;
    const { memberFetchResp, memberUpdateResp } = this.props;

    const federationId = this.props.userSession.realm;

    if (federationId && customerId && !this.state.memberFetched) {
      this.setState({ federationId, memberFetched: true, loadingMember: true });
      this.props.loadMemberRequest({ federationId, customerId });
      this.props.loadTechnicalAgentsRequest({ federationId, customerId });
    }

    if (!customerId) {
      console.warn("Technical error, fail to retrieve customerId");
    }
    this.loadData({ customerId, memberFetchResp });
    this.loadUpdateResp({ memberUpdateResp, customerId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      memberFetchResp,
      memberUpdateResp,
      memberActivateResp,
      customerId,
      technicalAgentsFetchResp,
      technicalContactUpdateResp,
      memberMetadataListFetchResp,
      userSession
    } = nextProps;
    this.loadData({
      customerId,
      memberFetchResp,
      technicalAgentsFetchResp,
      memberMetadataListFetchResp
    });
    this.loadUpdateResp({
      memberUpdateResp,
      memberActivateResp,
      customerId,
      technicalContactUpdateResp
    });

    if (userSession) {
      const federationId = userSession.realm;
      if (!this.state.metadataFetched) {
        this.setState({ federationId, metadataFetched: true });
        this.props.loadMemberMetadataListRequest({ federationId, customerId });
      }
      if (!this.state.memberFetched) {
        this.setState({ loadingMember: true });
        this.props.loadMemberRequest({ federationId, customerId });
        this.props.loadTechnicalAgentsRequest({ federationId, customerId });
        this.setState({ federationId, memberFetched: true });
      }
    }
    if (federationInfoRespState(nextProps)) {
      // alert(JSON.stringify(federationInfoRespState(newProps)));
      this.setState(federationInfoRespState(nextProps));
      if (this.state.federation) {
        this.setState({ federationType: this.state.federation.federationType });
      }
    }
  }

  loadData({
    memberFetchResp,
    customerId,
    technicalAgentsFetchResp,
    memberMetadataListFetchResp
  }) {
    if (memberFetchResp) {
      const resp = memberFetchResp[customerId];
      if (resp) {
        const data = resp.data;
        if (resp.fetched) {
          this.setState({
            data: {
              fetchMemberSuccess: data,
              fetchMemberError: null
            },
            loadingMember: false
          });
        } else {
          this.setState({
            data: {
              fetchMemberSuccess: null,
              fetchMemberError: resp.error
            },
            loadingMember: false
          });
        }
      }
    }

    if (technicalAgentsFetchResp) {
      const resp = technicalAgentsFetchResp[customerId];
      if (resp) {
        this.setState({
          numberOfTechnicalAgents: resp.data.length
        });
      }
    }
    if (memberMetadataListFetchResp) {
      const metadataEntities = memberMetadataListFetchResp.data;
      const total = metadataEntities ? metadataEntities.length : 0;
      this.setState({
        metadataEntities,
        numberOfMetaDataEntities: total
      });
    }
  }

  loadUpdateResp({
    memberUpdateResp,
    memberActivateResp,
    customerId,
    technicalContactUpdateResp
  }) {
    if (memberUpdateResp) {
      const resp = memberUpdateResp[customerId];
      if (resp && resp.updated) {
        this.setState({
          updateMemberSuccess: resp.data,
          updateMemberError: undefined,
          data: { fetchMemberSuccess: resp.data },
          loadingMember: false
        });
      } else if (resp && !resp.updated) {
        this.setState({
          updateMemberSuccess: undefined,
          updateMemberError: resp.error,
          loadingMember: false
        });
      }
    }
    if (memberActivateResp) {
      const resp = memberActivateResp[customerId];
      if (resp && resp.updated) {
        this.setState({
          updateMemberSuccess: resp.data,
          updateMemberError: undefined,
          data: { fetchMemberSuccess: resp.data },
          loadingMember: false
        });
      }
    }
    if (technicalContactUpdateResp) {
      const resp = technicalContactUpdateResp[this.state.userId];
      if (resp) {
        if (resp.updated) {
          this.setState({
            updateTechContactSuccess: resp.data,
            updateTechContactError: undefined
          });
        } else {
          this.setState({
            updateTechContactSuccess: undefined,
            updateTechContactError: resp.error
          });
        }
      }
    }
  }

  handleChange = (e, { activeIndex }) => {
    if (activeIndex === 0) {
      import("../../metadata").then(({ MetaDataView }) =>
        this.setState({
          activeIndex,
          MetaDataView
        })
      );
    }

    if (activeIndex === 3) {
      import("../list/TechnicalAgentsTable").then(({ TechnicalAgentsTable }) =>
        this.setState({
          TechnicalAgentsTable,
          updateMemberSuccess: undefined,
          updateMemberError: undefined
        })
      );
    }
    this.setState({
      activeIndex: activeIndex,
      updateMemberSuccess: undefined,
      updateMemberError: undefined
    });
  };

  handleMemberFormSubmit(payload) {
    const { federationId, customerId, federationType } = this.state;

    let validationErrorMessage = "Error";
    if (federationType && federationType === "FedTLS") {
      validationErrorMessage =
        "One of Client or Server has to be marked as allowed";
    } else if (federationType && federationType === "SAML") {
      validationErrorMessage = "One of Idp or Sp has to be marked as allowed";
    }
    let validationError =
      !payload.hasAo && !payload.hasSp ? validationErrorMessage : false;
    if (!validationError) {
      this.props.updateMemberRequest({
        federationId,
        customerId,
        data: payload
      });

      this.setState({ validationError: undefined });
    } else {
      this.setState({
        validationError: validationError,
        updateMemberSuccess: undefined
      });
    }
  }

  handleTechnicalContactSubmit(payload) {
    const { federationId, customerId } = this.state;
    const { userId } = payload;
    this.props.updateTechnicalContactRequest({
      federationId,
      customerId,
      userId,
      data: payload
    });
    this.setState({ userId });
  }

  handleTechnicalAgentsTableReload() {
    const { federationId, customerId } = this.state;
    this.props.loadTechnicalAgentsRequest({ federationId, customerId });
  }

  handleActivation = (customerId, active) => {
    const { federationId } = this.state;
    this.props.activateMemberRequest({
      federationId,
      customerId,
      active
    });
  };

  render() {
    const {
      data = {},
      updateMemberSuccess,
      updateMemberError,
      updateTechContactSuccess,
      updateTechContactError,
      TechnicalAgentsTable,
      numberOfMetaDataEntities = 0,
      MetaDataView,
      numberOfTechnicalAgents = 0,
      customerId,
      metadataEntities,
      federationId,
      loadingMember,
      federationType
    } = this.state;

    const {
      fetchMemberSuccess,
      fetchMemberError = null,
      fetchTechnicalAgentsError = null
    } = data;

    const roles = this.props.userSession.roles;
    // const federationId = this.props.userSession.federationId;

    return (
      <React.Fragment>
        {fetchMemberError && (
          <ErrorMessage title="Failed to fetch Member" {...fetchMemberError} />
        )}
        {false && <JsonView title="Card state" json={this.state} />}
        {loadingMember && <Loader active inline="centered" />}
        {fetchMemberSuccess && (
          <Container fluid>
            {fetchMemberSuccess && (
              <Container fluid>
                <Header as="h1" floated="left">
                  {fetchMemberSuccess.name}
                </Header>
                {authorize(
                  authMapping.DELETE_MEMBER,
                  roles,
                  <MemberDeleteView
                    title="Delete member"
                    deleteText="Delete"
                    cancelText="Cancel"
                    id={fetchMemberSuccess.customerId}
                    name={fetchMemberSuccess.name}
                    floated="right"
                    federationId={federationId}
                    {...this.props}
                  />
                )}
                {authorize(
                  authMapping.ACTIVATE_MEMBER,
                  roles,
                  <MemberActivateView
                    active={fetchMemberSuccess.active}
                    handleSubmit={this.handleActivation}
                    memberActivateResp={this.props.memberActivateResp}
                    customerId={customerId}
                    customerName={fetchMemberSuccess.name}
                  />
                )}
                <div style={{ clear: "both" }} />
              </Container>
            )}
            <StatusPanel
              numberOfMetaDataEntities={numberOfMetaDataEntities}
              fetchMemberSuccess={updateMemberSuccess || fetchMemberSuccess}
              numberOfTechnicalAgents={numberOfTechnicalAgents}
              federationType={federationType}
            />
            <Container fluid>
              <Tab
                renderActiveOnly={true}
                activeIndex={this.state.activeIndex}
                panes={panes}
                onTabChange={this.handleChange}
              />
              {this.state.activeIndex === 0 && MetaDataView && (
                <MetaDataView
                  federationId={federationId}
                  customerId={customerId}
                  entities={metadataEntities}
                  {...this.props}
                />
              )}

              {this.state.activeIndex === 1 && federationType && (
                <MemberFormEditView
                  title={
                    "Update general information" + fetchMemberSuccess.customerId
                  }
                  form={updateMemberSuccess || fetchMemberSuccess}
                  error={fetchMemberError}
                  saveText="Update"
                  updateSuccess={updateMemberSuccess}
                  updateError={updateMemberError}
                  handleSubmit={this.handleMemberFormSubmit}
                  validationError={this.state.validationError}
                  userSession={this.props.userSession}
                  federationType={federationType}
                />
              )}

              {this.state.activeIndex === 2 && (
                <TechnicalContactFormView
                  title="Update technical contact"
                  saveText="Update"
                  updateSuccess={updateTechContactSuccess}
                  updateError={updateTechContactError}
                  form={
                    updateTechContactSuccess ||
                    fetchMemberSuccess.technicalContact
                  }
                  error={fetchMemberError}
                  handleSubmit={this.handleTechnicalContactSubmit}
                  userSession={this.props.userSession}
                />
              )}
              {fetchTechnicalAgentsError && (
                <Message
                  error
                  header={`${fetchMemberError.status}: Fail to fetch member`}
                  content={
                    <JsonView title="Show more" json={fetchMemberError} />
                  }
                />
              )}
              {this.state.activeIndex === 3 && TechnicalAgentsTable && (
                <TechnicalAgentsTable
                  customerId={fetchMemberSuccess.customerId}
                  {...this.props}
                  reloadData={this.handleTechnicalAgentsTableReload}
                  roles={roles}
                  federationId={federationId}
                />
              )}
            </Container>
          </Container>
        )}
      </React.Fragment>
    );
  }
}

MemberCardView.propTypes = {
  customerId: PropTypes.string.isRequired
};

export default MemberCardView;
