import Keycloak from "keycloak-js";

const keycloakInstance = {};

const initKeyCloak = (realm, callback) => {
  if (process.env.NODE_ENV === "production") {
    return fetch("/keycloak.json")
      .then(res => res.json())
      .then(json => {
        const keycloakConfig = {
          realm: realm,
          url: json["auth-server-url"],
          "ssl-required": json["ssl-required"],
          clientId: json["resource"],
          resource: json["resource"],
          "public-client": json["public-client"]
        };

        keycloakInstance.keycloak = new Keycloak(keycloakConfig);
        if (callback) {
          callback(keycloakInstance.keycloak);
        }
      });
  } else {
    return fetch("/keycloak-dev.json")
      .then(res => res.json())
      .then(json => {
        const keycloakConfig = {
          realm: realm,
          url: json["auth-server-url"],
          "ssl-required": json["ssl-required"],
          clientId: json["resource"],
          resource: json["resource"],
          "public-client": json["public-client"]
        };

        keycloakInstance.keycloak = new Keycloak(keycloakConfig);
        if (callback) {
          callback(keycloakInstance.keycloak);
        }
      });
  }
};
export { keycloakInstance, initKeyCloak };
