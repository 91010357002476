import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks/index";
import { TechnicalAgentFormView } from "../../modules/member/form/TechnicalAgentFormView";

function mapStateToProps(state) {
  return {
    technicalAgentsFetchResp: state.technicalAgentsFetchResp,
    technicalAgentCreateResp: state.technicalAgentCreateResp,
    technicalAgentUpdateResp: state.technicalAgentUpdateResp,
    technicalAgentDeleteResp: state.technicalAgentDeleteResp,
    userSession: state.userSession,
    dynamicConfig: state.dynamicConfig,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const TechnicalAgentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TechnicalAgentFormView);

export default TechnicalAgentContainer;
