import {
  FEDERATION_INFO_SUCCESS,
  FEDERATION_INFO_FAILURE,
  FEDERATION_INFO_REQUEST
} from "./actionTypes";

///////////////////////////////////////////////////////////////////////////////
// Fetch Federation Info
///////////////////////////////////////////////////////////////////////////////

export const loadFederationInfoRequest = ({ federationId }) => {
  return { federationId, type: FEDERATION_INFO_REQUEST };
};

export const loadFederationInfoSuccess = ({ federationId, data }) => {
  return { type: FEDERATION_INFO_SUCCESS, federationId, data };
};

export const loadFederationInfoFailure = ({ federationId, error }) => {
  return { type: FEDERATION_INFO_FAILURE, federationId, error };
};
