import React from "react";
// import PropTypes from "prop-types";
import { ResellerRouts } from "../routs";

export const MembersContext = React.createContext();

export class ResellersContainer extends React.Component {
  constructor({ history }) {
    super();
    this.state = {
      mock: false,
      history: history
    };
  }

  render() {
    const { history } = this.state;

    return (
      <div>
        <ResellerRouts history={history} {...this.props} />
      </div>
    );
  }
}

// ResellersContainer.propTypes = {
//   keycloak: PropTypes.object.isRequired
// };

export default ResellersContainer;
