export const LOAD_RESELLER_REQUEST = "LOAD_RESELLER_REQUEST";
export const LOAD_RESELLER_SUCCESS = "LOAD_RESELLER_SUCCESS";
export const LOAD_RESELLER_FAILURE = "LOAD_RESELLER_FAILURE";

export const UPDATE_RESELLER_REQUEST = "UPDATE_RESELLER_REQUEST";
export const UPDATE_RESELLER_SUCCESS = "UPDATE_RESELLER_SUCCESS";
export const UPDATE_RESELLER_FAILURE = "UPDATE_RESELLER_FAILURE";

export const CREATE_RESELLER_REQUEST = "CREATE_RESELLER_REQUEST";
export const CREATE_RESELLER_SUCCESS = "CREATE_RESELLER_SUCCESS";
export const CREATE_RESELLER_FAILURE = "CREATE_RESELLER_FAILURE";

export const DELETE_RESELLER_REQUEST = "DELETE_RESELLER_REQUEST";
export const DELETE_RESELLER_SUCCESS = "DELETE_RESELLER_SUCCESS";
export const DELETE_RESELLER_FAILURE = "DELETE_RESELLER_FAILURE";

export const LOAD_RESELLERS_REQUEST = "LOAD_RESELLERS_REQUEST";
export const LOAD_RESELLERS_SUCCESS = "LOAD_RESELLERS_SUCCESS";
export const LOAD_RESELLERS_FAILURE = "LOAD_RESELLERS_FAILURE";

export const CREATE_RESELLER_USER_REQUEST = "CREATE_RESELLER_USER_REQUEST";
export const CREATE_RESELLER_USER_SUCCESS = "CREATE_RESELLER_USER_SUCCESS";
export const CREATE_RESELLER_USER_FAILURE = "CREATE_RESELLER_USER_FAILURE";

export const UPDATE_RESELLER_USER_REQUEST = "UPDATE_RESELLER_USER_REQUEST";
export const UPDATE_RESELLER_USER_SUCCESS = "UPDATE_RESELLER_USER_SUCCESS";
export const UPDATE_RESELLER_USER_FAILURE = "UPDATE_RESELLER_USER_FAILURE";

export const DELETE_RESELLER_USER_REQUEST = "DELETE_RESELLER_USER_REQUEST";
export const DELETE_RESELLER_USER_SUCCESS = "DELETE_RESELLER_USER_SUCCESS";
export const DELETE_RESELLER_USER_FAILURE = "DELETE_RESELLER_USER_FAILURE";

export const LOAD_RESELLER_USER_REQUEST = "LOAD_RESELLER_USER_REQUEST";
export const LOAD_RESELLER_USER_SUCCESS = "LOAD_RESELLER_USER_SUCCESS";
export const LOAD_RESELLER_USER_FAILURE = "LOAD_RESELLER_USER_FAILURE";

export const LOAD_RESELLER_USERS_REQUEST = "LOAD_RESELLER_USERS_REQUEST";
export const LOAD_RESELLER_USERS_SUCCESS = "LOAD_RESELLER_USERS_SUCCESS";
export const LOAD_RESELLER_USERS_FAILURE = "LOAD_RESELLER_USERS_FAILURE";
