import {
  SEARCH_MEMBER_REQUEST,
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_FAILURE,
  CREATE_MEMBER_REQUEST,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_WARNING,
  CREATE_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  ACTIVATE_MEMBER_REQUEST,
  ACTIVATE_MEMBER_SUCCESS,
  ACTIVATE_MEMBER_FAILURE,
  UPDATE_TECHNICAL_CONTACT_REQUEST,
  UPDATE_TECHNICAL_CONTACT_SUCCESS,
  UPDATE_TECHNICAL_CONTACT_FAILURE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  LOAD_MEMBER_REQUEST,
  LOAD_MEMBER_SUCCESS,
  LOAD_MEMBER_FAILURE,
  VALIDATE_MEMBER_REQUEST,
  VALIDATE_MEMBER_SUCCESS,
  VALIDATE_MEMBER_FAILURE,
  CREATE_TECHNICAL_AGENT_REQUEST,
  CREATE_TECHNICAL_AGENT_SUCCESS,
  CREATE_TECHNICAL_AGENT_FAILURE,
  UPDATE_TECHNICAL_AGENT_REQUEST,
  UPDATE_TECHNICAL_AGENT_SUCCESS,
  UPDATE_TECHNICAL_AGENT_FAILURE,
  DELETE_TECHNICAL_AGENT_REQUEST,
  DELETE_TECHNICAL_AGENT_SUCCESS,
  DELETE_TECHNICAL_AGENT_FAILURE,
  LOAD_TECHNICAL_AGENT_REQUEST,
  LOAD_TECHNICAL_AGENT_SUCCESS,
  LOAD_TECHNICAL_AGENT_FAILURE,
  LOAD_TECHNICAL_AGENTS_REQUEST,
  LOAD_TECHNICAL_AGENTS_SUCCESS,
  LOAD_TECHNICAL_AGENTS_FAILURE
} from "./actionTyps";

///////////////////////////////////////////////////////////////////////////////
// SEARCH member action
///////////////////////////////////////////////////////////////////////////////
export const searchMemberRequest = ({ federationId, query, filterBy }) => {
  return {
    federationId,
    type: SEARCH_MEMBER_REQUEST,
    query,
    filterBy
  };
};

export const searchMemberSuccess = ({ data }) => {
  return {
    type: SEARCH_MEMBER_SUCCESS,
    data
  };
};

export const searchMemberFailure = ({ error }) => {
  return {
    type: SEARCH_MEMBER_FAILURE,
    error
  };
};
///////////////////////////////////////////////////////////////////////////////
// Fetch Member action
///////////////////////////////////////////////////////////////////////////////
export const loadMemberRequest = ({ federationId, customerId }) => {
  return { federationId, type: LOAD_MEMBER_REQUEST, customerId };
};

export const loadMemberSuccess = ({ customerId, data }) => {
  return { type: LOAD_MEMBER_SUCCESS, customerId, data };
};

export const loadMemberFailure = ({ customerId, error }) => {
  return { type: LOAD_MEMBER_FAILURE, customerId, error };
};
///////////////////////////////////////////////////////////////////////////////
// Validate Member action
///////////////////////////////////////////////////////////////////////////////
export const validateMemberRequest = ({ federationId, customerId }) => {
  return { federationId, type: VALIDATE_MEMBER_REQUEST, customerId };
};

export const validateMemberSuccess = ({ customerId, data }) => {
  return { type: VALIDATE_MEMBER_SUCCESS, customerId, data };
};

export const validateMemberFailure = ({ customerId, error }) => {
  return { type: VALIDATE_MEMBER_FAILURE, customerId, error };
};
///////////////////////////////////////////////////////////////////////////////
// Update Member action
///////////////////////////////////////////////////////////////////////////////
export const updateMemberRequest = ({ federationId, customerId, data }) => {
  return {
    federationId,
    type: UPDATE_MEMBER_REQUEST,
    customerId,
    data
  };
};

export const updateMemberSuccess = ({ customerId, data }) => {
  return { type: UPDATE_MEMBER_SUCCESS, customerId, data };
};

export const updateMemberFailure = ({ customerId, error }) => {
  return { type: UPDATE_MEMBER_FAILURE, customerId, error };
};
///////////////////////////////////////////////////////////////////////////////
// Activate Member action
///////////////////////////////////////////////////////////////////////////////
export const activateMemberRequest = ({ federationId, customerId, active }) => {
  return {
    federationId,
    type: ACTIVATE_MEMBER_REQUEST,
    customerId,
    active
  };
};

export const activateMemberSuccess = ({ customerId, data, active }) => {
  return {
    type: ACTIVATE_MEMBER_SUCCESS,
    customerId,
    active: active,
    data: data
  };
};

export const activateMemberFailure = ({ customerId, error }) => {
  return { type: ACTIVATE_MEMBER_FAILURE, customerId, error };
};

///////////////////////////////////////////////////////////////////////////////
// Update Technical Contact action
///////////////////////////////////////////////////////////////////////////////
export const updateTechnicalContactRequest = ({
  federationId,
  customerId,
  userId,
  data
}) => {
  return {
    type: UPDATE_TECHNICAL_CONTACT_REQUEST,
    federationId,
    customerId,
    userId,
    data
  };
};

export const updateTechnicalContactSuccess = ({ customerId, userId, data }) => {
  return { type: UPDATE_TECHNICAL_CONTACT_SUCCESS, customerId, userId, data };
};

export const updateTechnicalContactFailure = ({
  customerId,
  userId,
  error
}) => {
  return { type: UPDATE_TECHNICAL_CONTACT_FAILURE, customerId, userId, error };
};
///////////////////////////////////////////////////////////////////////////////
// Create Member action
///////////////////////////////////////////////////////////////////////////////
export const createMemberRequest = ({ federationId, data, createMemberId }) => {
  return {
    federationId,
    type: CREATE_MEMBER_REQUEST,
    createMemberId,
    data
  };
};

export const createdMemberSuccess = ({ data, createMemberId }) => {
  return {
    type: CREATE_MEMBER_SUCCESS,
    createMemberId,
    data
  };
};

export const createMemberWarning = ({ data, createMemberId, info }) => {
  return {
    type: CREATE_MEMBER_WARNING,
    createMemberId,
    data,
    info
  };
};

export const createMemberFailure = ({ error, createMemberId }) => {
  return {
    type: CREATE_MEMBER_FAILURE,
    createMemberId,
    error
  };
};
///////////////////////////////////////////////////////////////////////////////
// Delete Member action
///////////////////////////////////////////////////////////////////////////////
export const deleteMemberRequest = ({ federationId, customerId }) => {
  return {
    federationId,
    type: DELETE_MEMBER_REQUEST,
    customerId
  };
};

export const deleteMemberFailure = ({ customerId, error }) => {
  return {
    type: DELETE_MEMBER_FAILURE,
    customerId,
    error
  };
};

export const deleteMemberSuccess = ({ customerId }) => {
  return {
    type: DELETE_MEMBER_SUCCESS,
    customerId
  };
};

///////////////////////////////////////////////////////////////////////////////
// Technical Agent actions
///////////////////////////////////////////////////////////////////////////////
export const createTechnicalAgentRequest = ({
  federationId,
  customerId,
  data
}) => {
  return {
    federationId,
    type: CREATE_TECHNICAL_AGENT_REQUEST,
    customerId,
    data
  };
};

export const createTechnicalAgentSuccess = ({ customerId, data }) => {
  return { type: CREATE_TECHNICAL_AGENT_SUCCESS, customerId, data };
};

export const createTechnicalAgentFailure = ({ error }) => {
  return { type: CREATE_TECHNICAL_AGENT_FAILURE, error };
};

export const updateTechnicalAgentRequest = ({
  federationId,
  customerId,
  userId,
  data
}) => {
  return {
    federationId,
    type: UPDATE_TECHNICAL_AGENT_REQUEST,
    customerId,
    userId,
    data
  };
};

export const updateTechnicalAgentSuccess = ({ customerId, userId, data }) => {
  return {
    type: UPDATE_TECHNICAL_AGENT_SUCCESS,
    customerId,
    userId,
    data
  };
};

export const updateTechnicalAgentFailure = ({ customerId, userId, error }) => {
  return { type: UPDATE_TECHNICAL_AGENT_FAILURE, customerId, userId, error };
};

export const deleteTechnicalAgentRequest = ({
  federationId,
  customerId,
  userId
}) => {
  return {
    federationId,
    type: DELETE_TECHNICAL_AGENT_REQUEST,
    customerId,
    userId
  };
};

export const deleteTechnicalAgentSuccess = ({ customerId, userId, data }) => {
  return {
    type: DELETE_TECHNICAL_AGENT_SUCCESS,
    customerId,
    userId,
    data
  };
};

export const deleteTechnicalAgentFailure = ({ error }) => {
  return { type: DELETE_TECHNICAL_AGENT_FAILURE, error };
};

export const loadTechnicalAgentRequest = ({
  federationId,
  customerId,
  data
}) => {
  return {
    federationId,
    type: LOAD_TECHNICAL_AGENT_REQUEST,
    customerId,
    data
  };
};

export const loadTechnicalAgentSuccess = ({ customerId, data }) => {
  return { type: LOAD_TECHNICAL_AGENT_SUCCESS, customerId, data };
};

export const loadTechnicalAgentFailure = ({ error }) => {
  return { type: LOAD_TECHNICAL_AGENT_FAILURE, error };
};

export const loadTechnicalAgentsRequest = ({
  federationId,
  customerId,
  data
}) => {
  return {
    federationId,
    type: LOAD_TECHNICAL_AGENTS_REQUEST,
    customerId,
    data
  };
};

export const loadTechnicalAgentsSuccess = ({ customerId, data }) => {
  return { type: LOAD_TECHNICAL_AGENTS_SUCCESS, customerId, data };
};

export const loadTechnicalAgentsFailure = ({ customerId, error }) => {
  return { type: LOAD_TECHNICAL_AGENTS_FAILURE, error };
};
