import React from "react";
import { Table, Container, Header, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import { TechnicalAgentFormView, TechnicalAgentDeleteView } from "../form";
import {
  authorize,
  isUserAuthorized
} from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";

export const TechnicalAgentsTable = ({
  header = [
    { id: 1, name: "AGENT NAME" },
    { id: 2, name: "AGENT EMAIL" },
    { id: 3, name: "AGENT PHONE NUMBER" }
  ],
  customerId,
  handleDelete,
  handleAdd,
  handleUpdate,
  reloadData,
  createTechnicalAgentRequest,
  deleteTechnicalAgentRequest,
  updateTechnicalAgentRequest,
  loadTechnicalAgentsRequest,
  technicalAgentCreateResp,
  technicalAgentUpdateResp,
  technicalAgentDeleteResp,
  technicalAgentsFetchResp = {},
  roles,
  federationId
}) => {
  if (isUserAuthorized(authMapping.UPDATE_TECH_AGENT, roles)) {
    header.push({ id: 4, name: "EDIT" });
  }

  return (
    <Segment>
      <Header as="h2">Technical agents</Header>
      {technicalAgentsFetchResp &&
        technicalAgentsFetchResp[customerId] &&
        technicalAgentsFetchResp[customerId].data.length > 0 && (
          <Table celled padded="very" striped>
            <Table.Header>
              <Table.Row>
                {header.map(({ id, name }) => (
                  <Table.HeaderCell key={id}>{name}</Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {technicalAgentsFetchResp[customerId].data.map(
                ({ userId, firstName, lastName, email, phone }, index) => (
                  <Table.Row key={userId}>
                    <Table.Cell>
                      {firstName} {lastName}
                    </Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>{phone}</Table.Cell>
                    {authorize(
                      authMapping.UPDATE_TECH_AGENT,
                      roles,
                      <Table.Cell>
                        <TechnicalAgentFormView
                          title="Edit"
                          saveText="Update"
                          technicalAgent={
                            technicalAgentsFetchResp[customerId].data[index]
                          }
                          customerId={customerId}
                          handleUpdate={handleUpdate}
                          updateTechnicalAgentRequest={
                            updateTechnicalAgentRequest
                          }
                          technicalAgentUpdateResp={technicalAgentUpdateResp}
                          loadTechnicalAgentsRequest={
                            loadTechnicalAgentsRequest
                          }
                          reloadData={reloadData}
                          federationId={federationId}
                        />
                        {authorize(
                          authMapping.DELETE_TECH_AGENT,
                          roles,
                          <TechnicalAgentDeleteView
                            title="Delete"
                            customerId={customerId}
                            userId={userId}
                            firstname={
                              technicalAgentsFetchResp[customerId].data[index]
                                .firstName
                            }
                            lastname={
                              technicalAgentsFetchResp[customerId].data[index]
                                .lastName
                            }
                            handleDelete={handleDelete}
                            deleteTechnicalAgentRequest={
                              deleteTechnicalAgentRequest
                            }
                            technicalAgentDeleteResp={technicalAgentDeleteResp}
                            loadTechnicalAgentsRequest={
                              loadTechnicalAgentsRequest
                            }
                            reloadData={reloadData}
                            federationId={federationId}
                          />
                        )}
                      </Table.Cell>
                    )}
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        )}

      {authorize(
        authMapping.CREATE_TECH_AGENT,
        roles,
        <TechnicalAgentFormView
          title="Add technical agent"
          saveText="Save"
          technicalAgent={{}}
          customerId={customerId}
          handleAdd={handleAdd}
          createTechnicalAgentRequest={createTechnicalAgentRequest}
          technicalAgentCreateResp={technicalAgentCreateResp}
          reloadData={reloadData}
          federationId={federationId}
        />
      )}
    </Segment>
  );
};

TechnicalAgentsTable.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array
};

export const TechnicalAgentsReadTable = ({
  header = [{ id: 2, name: "Name" }],
  data = []
}) => (
  <Container>
    <Table celled padded="very" striped>
      <Table.Header>
        <Table.Row>
          {header.map(({ id, name }) => (
            <Table.HeaderCell key={id}>{name}</Table.HeaderCell>
          ))}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map(({ name }, index) => (
          <Table.Row key={index}>
            <Table.Cell>{name}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  </Container>
);

TechnicalAgentsReadTable.propTypes = {
  header: PropTypes.array,
  data: PropTypes.array
};

export default TechnicalAgentsTable;
