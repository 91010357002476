import { call, put } from "redux-saga/effects";
import { fetchFederationInfoApi } from "../federation/api";
import {
  loadFederationInfoFailure,
  loadFederationInfoSuccess
} from "../../ducks/federation/actions";

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

function* fetchFederationInfo(action) {
  const { federationId } = action;
  const { resp = false, error = false } = yield call(fetchFederationInfoApi, {
    federationId
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      loadFederationInfoSuccess({
        federationId,
        data
      })
    );
  } else if (error) {
    logError("fetchFederationInfo: ", error);
    yield put(
      loadFederationInfoFailure({
        federationId,
        error: yield error
      })
    );
  }
}

export { fetchFederationInfo };
