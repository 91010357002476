import React from "react";
import {
  Button,
  Container,
  Header,
  Modal,
  Message,
  Loader,
  Dimmer
} from "semantic-ui-react";
// import PropTypes from "prop-types";
import { ErrorMessage } from "../../../common/error";

export class MemberActivateView extends React.Component {
  constructor({ active, customerId, customerName }) {
    super();

    this.state = { active, customerId, customerName };
  }
  UNSAFE_componentWillReceiveProps({ memberActivateResp }) {
    if (memberActivateResp) {
      const resp = memberActivateResp[this.state.customerId];
      if (resp && resp.updated !== undefined) {
        if (resp.updated) {
          const { active } = resp;
          if (active !== this.state.active) {
            this.setState({
              updateSuccess: true,
              updateError: undefined
            });

            this.setState({ active });
            this.handleClose();
          }
        } else {
          this.setState({
            updateSuccess: undefined,
            updateError: resp.error,
            loading: false
          });
        }
      }
    }
  }

  submit = (customerId, active) => {
    this.setState({ loading: true });
    this.props.handleSubmit(customerId, active);
  };

  handleOpen = () => this.setState({ modalOpen: true, loading: false });
  handleClose = () => {
    this.setState({
      updateSuccess: undefined,
      updateError: undefined,
      modalOpen: false
    });
  };
  render() {
    const {
      customerId,
      customerName,
      active,
      updateError,
      loading
    } = this.state;
    const title = active ? "Inactivate" : "Activate";
    return (
      <Modal
        trigger={
          <Button primary floated="right" onClick={this.handleOpen}>
            {title} member
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description>
            {active && <Header as="h1">Inactivate member</Header>}
            {!active && <Header as="h1">Activate member</Header>}
            {updateError && (
              <ErrorMessage title="Failed to update member" {...updateError} />
            )}
            <Message
              header={
                active
                  ? `Are you sure you want to inactivate ${customerName}?`
                  : `Are you sure you want to activate ${customerName}?`
              }
              content={
                active
                  ? `No metadata on this member will be available for publish`
                  : `All metadata on this member will be published`
              }
            />

            <Container fluid>
              {loading && (
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              )}
              <Button
                type="submit"
                primary
                onClick={() => this.submit(customerId, !active)}
              >
                {title}
              </Button>
              <Button type="cancel" secondary onClick={this.handleClose}>
                Cancel
              </Button>
            </Container>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default MemberActivateView;
