import {
  LOAD_MEMBER_METADATA_LIST_REQUEST,
  LOAD_MEMBER_METADATA_LIST_SUCCESS,
  LOAD_MEMBER_METADATA_LIST_FAILURE,
  LOAD_FEDERATION_METADATA_LIST_REQUEST,
  LOAD_FEDERATION_METADATA_LIST_SUCCESS,
  LOAD_FEDERATION_METADATA_LIST_FAILURE,
  LOAD_METADATA_VALIDATE_REQUEST,
  LOAD_METADATA_VALIDATE_SUCCESS,
  LOAD_METADATA_VALIDATE_FAILURE,
  UPLOAD_METADATA_REQUEST,
  UPLOAD_METADATA_SUCCESS,
  UPLOAD_METADATA_FAILURE,
  LOAD_METADATA_REQUEST,
  LOAD_METADATA_SUCCESS,
  LOAD_METADATA_FAILURE,
  UPDATE_METADATA_REQUEST,
  UPDATE_METADATA_SUCCESS,
  UPDATE_METADATA_FAILURE,
  DELETE_METADATA_REQUEST,
  DELETE_METADATA_SUCCESS,
  DELETE_METADATA_FAILURE,
  APPROVE_METADATA_REQUEST,
  APPROVE_METADATA_SUCCESS,
  APPROVE_METADATA_FAILURE,
  LOAD_FEDERATION_MD_COUNT_REQUEST,
  LOAD_FEDERATION_MD_COUNT_SUCCESS,
  LOAD_FEDERATION_MD_COUNT_FAILURE
} from "./actionTypes";

///////////////////////////////////////////////////////////////////////////////
// Load member metadata list action
///////////////////////////////////////////////////////////////////////////////
export const loadMemberMetadataListRequest = ({ federationId, customerId }) => {
  return {
    federationId: federationId,
    customerId: customerId,
    type: LOAD_MEMBER_METADATA_LIST_REQUEST
  };
};

export const loadMemberMetadataListSuccess = ({ customerId, data }) => {
  return {
    customerId: customerId,
    type: LOAD_MEMBER_METADATA_LIST_SUCCESS,
    data
  };
};

export const loadMemberMetadataListFailure = ({ customerId, error }) => {
  return {
    customerId: customerId,
    type: LOAD_MEMBER_METADATA_LIST_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Load metadata validate action
///////////////////////////////////////////////////////////////////////////////
export const loadMetadataValidateRequest = ({
  federationId,
  customerId,
  entityId,
  approval
}) => {
  return {
    federationId: federationId,
    customerId: customerId,
    entityId: entityId,
    approval:
      approval === undefined || approval === null || !approval ? false : true,
    type: LOAD_METADATA_VALIDATE_REQUEST
  };
};

export const loadMetadataValidateSuccess = ({ customerId, data }) => {
  return {
    customerId: customerId,
    type: LOAD_METADATA_VALIDATE_SUCCESS,
    data
  };
};

export const loadMetadataValidateFailure = ({ customerId, error }) => {
  return {
    customerId: customerId,
    type: LOAD_METADATA_VALIDATE_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Load federation metadata list action
///////////////////////////////////////////////////////////////////////////////
export const loadFederationMetadataListRequest = ({ federationId }) => {
  return {
    federationId: federationId,
    type: LOAD_FEDERATION_METADATA_LIST_REQUEST
  };
};

export const loadFederationMetadataListSuccess = ({ federationId, data }) => {
  return {
    federationId: federationId,
    type: LOAD_FEDERATION_METADATA_LIST_SUCCESS,
    data
  };
};

export const loadFederationMetadataListFailure = ({ federationId, error }) => {
  return {
    federationId: federationId,
    type: LOAD_FEDERATION_METADATA_LIST_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Load federation member active metadata count for whole federation
///////////////////////////////////////////////////////////////////////////////
export const fetchFederationMDCountRequest = ({ federationId }) => {
  return {
    federationId: federationId,
    type: LOAD_FEDERATION_MD_COUNT_REQUEST
  };
};
export const fetchFederationMDCountSuccess = ({ mData }) => {
  return {
    type: LOAD_FEDERATION_MD_COUNT_SUCCESS,
    mData
  };
};
export const fetchFederationMDCountFailure = ({ error }) => {
  return {
    type: LOAD_FEDERATION_MD_COUNT_FAILURE,
    error: error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Upload metadata action
///////////////////////////////////////////////////////////////////////////////
export const uploadMetadataRequest = ({
  federationId,
  customerId,
  formData,
  publishFile
}) => {
  return {
    type: UPLOAD_METADATA_REQUEST,
    federationId: federationId,
    customerId: customerId,
    formData: formData,
    publishFile: publishFile
  };
};

export const uploadMetadataSuccess = ({ customerId, data, filePublished }) => {
  return {
    type: UPLOAD_METADATA_SUCCESS,
    customerId: customerId,
    data,
    filePublished
  };
};

export const uploadMetadataFailure = ({ customerId, error, filePublished }) => {
  return {
    type: UPLOAD_METADATA_FAILURE,
    customerId: customerId,
    error,
    filePublished
  };
};

///////////////////////////////////////////////////////////////////////////////
// Load metadata entity action
///////////////////////////////////////////////////////////////////////////////
export const loadMetadataRequest = ({ federationId, customerId, entityId }) => {
  return {
    federationId: federationId,
    customerId: customerId,
    entityId: entityId,
    type: LOAD_METADATA_REQUEST
  };
};

export const loadMetadataSuccess = ({ customerId, entityId, data }) => {
  return {
    customerId: customerId,
    entityId: entityId,
    type: LOAD_METADATA_SUCCESS,
    data
  };
};

export const loadMetadataFailure = ({ customerId, entityId, error }) => {
  return {
    customerId: customerId,
    entityId: entityId,
    type: LOAD_METADATA_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Update metadata entity action
///////////////////////////////////////////////////////////////////////////////
export const updateMetadataRequest = ({
  federationId,
  customerId,
  entityId,
  active
}) => {
  return {
    federationId: federationId,
    customerId: customerId,
    entityId: entityId,
    active: active,
    type: UPDATE_METADATA_REQUEST
  };
};

export const updateMetadataSuccess = ({ entityId, active }) => {
  return {
    entityId: entityId,
    active: active,
    type: UPDATE_METADATA_SUCCESS
  };
};

export const updateMetadataFailure = ({ entityId, error }) => {
  return {
    entityId: entityId,
    type: UPDATE_METADATA_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Delete metadata entity action
///////////////////////////////////////////////////////////////////////////////
export const deleteMetadataRequest = ({
  federationId,
  customerId,
  entityId,
  approved
}) => {
  return {
    federationId: federationId,
    customerId: customerId,
    entityId: entityId,
    approved: approved,
    type: DELETE_METADATA_REQUEST
  };
};

export const deleteMetadataSuccess = ({ entityId }) => {
  return {
    entityId: entityId,
    type: DELETE_METADATA_SUCCESS
  };
};

export const deleteMetadataFailure = ({ entityId, error }) => {
  return {
    entityId: entityId,
    type: DELETE_METADATA_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Approve metadata entity action
///////////////////////////////////////////////////////////////////////////////
export const approveMetadataRequest = ({
  federationId,
  entityId,
  approve,
  message
}) => {
  console.log("message: " + message);
  return {
    federationId: federationId,
    entityId: entityId,
    approve: approve,
    message: message,
    type: APPROVE_METADATA_REQUEST
  };
};

export const approveMetadataSuccess = ({ entityId, approve }) => {
  return {
    entityId: entityId,
    approve: approve,
    type: APPROVE_METADATA_SUCCESS
  };
};

export const approveMetadataFailure = ({ entityId, error }) => {
  return {
    entityId: entityId,
    type: APPROVE_METADATA_FAILURE,
    error
  };
};
