import { call, put } from "redux-saga/effects";
import { fetchShoutoutApi } from "./api";
import {
  loadShoutoutFailure,
  loadShoutoutSuccess
} from "../../ducks/contentadmin/actions";

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

function* fetchShoutout(action) {
  const { id } = action;
  const { resp = false, error = false } = yield call(fetchShoutoutApi, {
    id
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      loadShoutoutSuccess({
        id,
        data
      })
    );
  } else if (error) {
    logError("fetchShoutout: ", error);
    yield put(
      loadShoutoutFailure({
        id,
        error: yield error
      })
    );
  }
}

export { fetchShoutout };
