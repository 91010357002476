export const LOAD_MEMBER_REQUEST = "LOAD_MEMBER_REQUEST";
export const LOAD_MEMBER_SUCCESS = "LOAD_MEMBER_SUCCESS";
export const LOAD_MEMBER_FAILURE = "LOAD_MEMBER_FAILURE";

export const VALIDATE_MEMBER_REQUEST = "VALIDATE_MEMBER_REQUEST";
export const VALIDATE_MEMBER_SUCCESS = "VALIDATE_MEMBER_SUCCESS";
export const VALIDATE_MEMBER_FAILURE = "VALIDATE_MEMBER_FAILURE";

export const UPDATE_MEMBER_REQUEST = "UPDATE_MEMBER_REQUEST";
export const UPDATE_MEMBER_SUCCESS = "UPDATE_MEMBER_SUCCESS";
export const UPDATE_MEMBER_FAILURE = "UPDATE_MEMBER_FAILURE";

export const ACTIVATE_MEMBER_REQUEST = "ACTIVATE_MEMBER_REQUEST";
export const ACTIVATE_MEMBER_SUCCESS = "ACTIVATE_MEMBER_SUCCESS";
export const ACTIVATE_MEMBER_FAILURE = "ACTIVATE_MEMBER_FAILURE";

export const UPDATE_TECHNICAL_CONTACT_REQUEST =
  "UPDATE_TECHNICAL_CONTACT_REQUEST";
export const UPDATE_TECHNICAL_CONTACT_SUCCESS =
  "UPDATE_TECHNICAL_CONTACT_SUCCESS";
export const UPDATE_TECHNICAL_CONTACT_FAILURE =
  "UPDATE_TECHNICAL_CONTACT_FAILURE";

export const CREATE_MEMBER_REQUEST = "CREATE_MEMBER_REQUEST";
export const CREATE_MEMBER_SUCCESS = "CREATE_MEMBER_SUCCESS";
export const CREATE_MEMBER_WARNING = "CREATE_MEMBER_WARNING";
export const CREATE_MEMBER_FAILURE = "CREATE_MEMBER_FAILURE";

export const DELETE_MEMBER_REQUEST = "DELETE_MEMBER_REQUEST";
export const DELETE_MEMBER_SUCCESS = "DELETE_MEMBER_SUCCESS";
export const DELETE_MEMBER_FAILURE = "DELETE_MEMBER_FAILURE";

export const SEARCH_MEMBER_REQUEST = "SEARCH_MEMBER_REQUEST";
export const SEARCH_MEMBER_SUCCESS = "SEARCH_MEMBER_SUCCESS";
export const SEARCH_MEMBER_FAILURE = "SEARCH_MEMBER_FAILURE";

export const CREATE_TECHNICAL_AGENT_REQUEST = "CREATE_TECHNICAL_AGENT_REQUEST";
export const CREATE_TECHNICAL_AGENT_SUCCESS = "CREATE_TECHNICAL_AGENT_SUCCESS";
export const CREATE_TECHNICAL_AGENT_FAILURE = "CREATE_TECHNICAL_AGENT_FAILURE";

export const UPDATE_TECHNICAL_AGENT_REQUEST = "UPDATE_TECHNICAL_AGENT_REQUEST";
export const UPDATE_TECHNICAL_AGENT_SUCCESS = "UPDATE_TECHNICAL_AGENT_SUCCESS";
export const UPDATE_TECHNICAL_AGENT_FAILURE = "UPDATE_TECHNICAL_AGENT_FAILURE";

export const DELETE_TECHNICAL_AGENT_REQUEST = "DELETE_TECHNICAL_AGENT_REQUEST";
export const DELETE_TECHNICAL_AGENT_SUCCESS = "DELETE_TECHNICAL_AGENT_SUCCESS";
export const DELETE_TECHNICAL_AGENT_FAILURE = "DELETE_TECHNICAL_AGENT_FAILURE";

export const LOAD_TECHNICAL_AGENT_REQUEST = "LOAD_TECHNICAL_AGENT_REQUEST";
export const LOAD_TECHNICAL_AGENT_SUCCESS = "LOAD_TECHNICAL_AGENT_SUCCESS";
export const LOAD_TECHNICAL_AGENT_FAILURE = "LOAD_TECHNICAL_AGENT_FAILURE";

export const LOAD_TECHNICAL_AGENTS_REQUEST = "LOAD_TECHNICAL_AGENTS_REQUEST";
export const LOAD_TECHNICAL_AGENTS_SUCCESS = "LOAD_TECHNICAL_AGENTS_SUCCESS";
export const LOAD_TECHNICAL_AGENTS_FAILURE = "LOAD_TECHNICAL_AGENTS_FAILURE";
