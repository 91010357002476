import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks/index";
import { PageCreateMember } from "../../pages/federation/members";

function mapStateToProps(state) {
  return {
    memberCreateResp: state.memberCreateResp,
    memberFetchResp: state.memberFetchResp,
    memberValidateResp: state.memberValidateResp,
    technicalAgentCreateResp: state.technicalAgentCreateResp,
    technicalAgentUpdateResp: state.technicalAgentUpdateResp,
    technicalAgentDeleteResp: state.technicalAgentDeleteResp,
    technicalAgentsFetchResp: state.technicalAgentsFetchResp,
    resellersFetchResp: state.resellersFetchResp,
    userSession: state.userSession,
    dynamicConfig: state.dynamicConfig,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const PageCreateMemberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageCreateMember);

export default PageCreateMemberContainer;
