import React from "react";

import { Image } from "semantic-ui-react";
import iisLogo from "./images/iis.png";

const Logo = ({ verticalAlign = "top", left = "true", size = "tiny" }) => (
  <Image
    inline
    verticalAlign={verticalAlign}
    left={left}
    size={size}
    src={iisLogo}
  />
);

export { Logo };
