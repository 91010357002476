import {
  SHOUTOUT_FAILURE,
  SHOUTOUT_SUCCESS
} from "./actionTypes";
import { loadFailureReducer, loadSuccessReducer } from "../common/reducer";

export const shoutoutResp = (state = {}, action) => {
  switch (action.type) {
    case SHOUTOUT_SUCCESS:
      return loadSuccessReducer(state, action);
    case SHOUTOUT_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};
