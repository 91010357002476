import React, { Component } from "react";
import { IntlProvider } from "react-intl";
import { MainRouts } from "./routs";
import "./App.css";

export const AppContext = React.createContext({ version: 12344 });

class App extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <IntlProvider locale="en">
        <AppContext.Provider
          value={
            ({ ...this.state },
            {
              config: this.props.config
            })
          }
        >
          <MainRouts {...this.props} />
        </AppContext.Provider>
      </IntlProvider>
    );
  }
}

export default App;
