import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Segment,
  SegmentGroup,
  Header,
  Modal,
  Form,
  Icon,
  Dimmer,
  Loader
} from "semantic-ui-react";
// import { ErrorMessage } from "../../../common/error";
import { WarningMessageWrappedAsList } from "../../../common/error";
import { JsonView } from "../../../helpers/json/JsonView";
import { DetermineRealmTrial } from "../../../utils/common/DetermineRealmTrial";
import { validatePnr } from "../../../helpers/pnrvalidator";

export class ResellerUserFormView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: props.title,
      saveText: props.saveText,
      form: props.form,
      formOrginal: props.form,
      dirtyForm: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = () => {
    if (this.props.form && this.props.form.userId) {
      this.setState({
        ssnValid: validatePnr(this.props.form.ssn).valid,
        ssnRequired: DetermineRealmTrial(this.props.federationId),
        icon: <Icon onClick={this.handleOpen} size="large" name="pencil" />
      });
    } else {
      this.setState({
        ssnRequired: DetermineRealmTrial(this.props.federationId),
        icon: (
          <Button onClick={this.handleOpen} secondary>
            Add reseller user
          </Button>
        )
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resellerUserUpdateResp, resellerUserCreateResp } = nextProps;
    this.loadUpdateData({ resellerUserUpdateResp });
    this.loadCreateData({ resellerUserCreateResp });
  }

  loadCreateData({ resellerUserCreateResp }) {
    const prevResponse = this.state.resellerUserCreateResp;
    if (
      resellerUserCreateResp &&
      JSON.stringify(resellerUserCreateResp) !== JSON.stringify(prevResponse)
    ) {
      if (resellerUserCreateResp.created !== undefined) {
        if (resellerUserCreateResp.created) {
          this.props.handleCreateResp(resellerUserCreateResp.data);
          this.setState({
            saveResellerUserSuccess: resellerUserCreateResp.data,
            saveError: undefined,
            resellerUserCreateResp,
            loading: false
          });
          this.handleClose();
        } else {
          this.setState({
            saveResellerUserSuccess: undefined,
            saveError: resellerUserCreateResp.error,
            resellerUserCreateResp,
            loading: false
          });
        }
      }
    }
  }
  loadUpdateData({ resellerUserUpdateResp }) {
    if (this.props.userId) {
      const prevResponse = this.state.resellerUserUpdateResp;
      if (
        resellerUserUpdateResp &&
        JSON.stringify(resellerUserUpdateResp) !== JSON.stringify(prevResponse)
      ) {
        const resp = resellerUserUpdateResp[this.props.userId];
        if (resp && resp.updated !== undefined) {
          if (resp.updated) {
            this.props.handleUpdateResp(this.props.userId, resp.data);
            this.setState({
              saveResellerUserSuccess: resp.data,
              saveError: undefined,
              resellerUserUpdateResp,
              dirtyForm: false,
              loading: false,
              formOrginal: resp.data
            });
            // Set state is asyncron handleClose also update state,
            // thas way we pass updated props to handleClose
            this.handleCloseAndUpdateState({
              updatedForm: resp.data,
              formOrginal: this.state.formOrginal,
              dirtyForm: false
            });
          } else {
            this.setState({
              saveResellerUserSuccess: undefined,
              saveError: resp.error,
              resellerUserUpdateResp,
              loading: false
            });
          }
        }
      }
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    let validSSN = {}; // result from pnr validate
    let validSSNInput = true;

    event.preventDefault();

    if (name === "ssn" && value !== undefined) {
      validSSN = validatePnr(value, {
        strict: true,
        forgiving: true
      });
      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && value.length < 12) {
        validSSNInput = false;
      }
    } else if (this.state.form.ssn) {
      validSSN = validatePnr(this.state.form.ssn, {
        strict: true,
        forgiving: true
      });
      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && this.state.form.ssn.length < 12) {
        validSSNInput = false;
      }
    }

    this.setState({
      saveError: undefined,
      saveSuccess: undefined,
      ssnValid: validSSNInput,
      form: {
        ...this.state.form,
        ...{ [name]: value }
      },
      dirtyForm: true
    });
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    if (this.props.userId) {
      this.props.handleSave(this.state.form, this.props.userId);
    } else {
      this.props.handleSave(this.state.form);
    }
  };

  setError(err) {
    this.setState({
      updateError: err,
      updateSuccess: undefined,
      loading: false
    });
  }
  handleOpen = () =>
    this.setState({
      modalOpen: true,
      saveSuccess: undefined,
      saveError: undefined
    });

  handleClose = () => {
    let resetedForm;
    // if userId then its edit mode
    const isEditMode = this.state.form.userId ? true : false;
    //const dirtyForm = this.state.dirtyForm;
    const formOrginal = this.state.formOrginal;
    resetedForm = isEditMode ? formOrginal : {};
    this.setState({
      form: resetedForm,
      modalOpen: false,
      loading: false
    });
  };

  handleCloseAndUpdateState = ({ updatedForm, formOrginal, dirtyForm }) => {
    if (updatedForm && formOrginal) {
      let resetedForm = dirtyForm ? formOrginal : updatedForm;
      this.setState({
        form: resetedForm,
        modalOpen: false,
        loading: false
      });
    } else {
      alert("Technical error! props not set correct!");
    }
  };

  render() {
    const {
      title,
      icon,
      form,
      saveError,
      dirtyForm,
      ssnRequired,
      ssnValid,
      saveText = saveError ? "Save" : "Close",
      loading
    } = this.state;

    let showButton = false;
    if (ssnRequired && form && form.ssn) {
      showButton = dirtyForm && ssnValid;
    } else if (dirtyForm && form && form.ssn) {
      showButton = dirtyForm && ssnValid;
    } else if (dirtyForm || (form && form.ssn)) {
      showButton = dirtyForm;
    }

    return (
      <Modal
        trigger={icon}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description>
            <Header as="h1">{title}</Header>
            {false && <JsonView title="STATE" json={this.state} />}
            {false && <JsonView title="PROPS" json={this.props} />}
            <SegmentGroup disabled={true}>
              <Segment>
                {saveError && (
                  <WarningMessageWrappedAsList
                    error
                    title="Warning! Reseller User created!"
                    {...saveError}
                  />
                )}
                {form && (
                  <Form onSubmit={() => this.handleSubmit()}>
                    <Form.Field>
                      <Form.Input
                        required
                        name="firstName"
                        label="First name"
                        placeholder="First name"
                        onChange={this.handleChange}
                        value={form.firstName}
                        maxLength="36"
                        autoFocus
                        autoComplete="off"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        required
                        name="lastName"
                        label="Last name"
                        placeholder="Last name"
                        onChange={this.handleChange}
                        value={form.lastName}
                        maxLength="36"
                        autoComplete="off"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        required
                        name="email"
                        label="E-mail"
                        placeholder="email@email.com"
                        onChange={this.handleChange}
                        value={form.email}
                        type="email"
                        maxLength="70"
                        autoComplete="off"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        required
                        name="phone"
                        label="Phone number"
                        placeholder="000 00 00 00"
                        onChange={this.handleChange}
                        value={form.phone}
                        maxLength="36"
                        autoComplete="off"
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        fluid
                        required={ssnRequired}
                        name="ssn"
                        label="Personal Identity Number"
                        placeholder="YYYYMMDDNNNN"
                        onChange={this.handleChange}
                        value={form.ssn}
                        minLength="12"
                        maxLength="12"
                        autoComplete="off"
                      />
                    </Form.Field>
                    <Segment>
                      {loading && (
                        <Dimmer active inverted>
                          <Loader active inline />
                        </Dimmer>
                      )}
                      {saveError && (
                        <Button type="close" primary onClick={this.handleClose}>
                          Close
                        </Button>
                      )}
                      {!saveError && (
                        <Button type="submit" primary disabled={!showButton}>
                          {saveText}
                        </Button>
                      )}
                    </Segment>
                  </Form>
                )}
              </Segment>
            </SegmentGroup>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

ResellerUserFormView.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  form: PropTypes.object
};

export default ResellerUserFormView;
