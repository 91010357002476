import { call, put, all } from "redux-saga/effects";
import { push } from "react-router-redux";
import {
  createMemberApi,
  updateMemberApi,
  activateMemberApi,
  updateTechnicalContactApi,
  fetchMemberApi,
  validateCustomerId,
  fetchMemberSearchApi,
  deleteMemberApi
} from "./api";

// FETCH_MEMBER

import {
  createdMemberSuccess,
  createMemberWarning,
  createMemberFailure,
  updateMemberSuccess,
  updateMemberFailure,
  activateMemberSuccess,
  activateMemberFailure,
  updateTechnicalContactSuccess,
  updateTechnicalContactFailure,
  deleteMemberSuccess,
  deleteMemberFailure,
  loadMemberSuccess,
  loadMemberFailure,
  validateMemberSuccess,
  validateMemberFailure,
  searchMemberSuccess,
  searchMemberFailure
} from "../../ducks/member/actions";

// COUNT MD for federation
import { fetchFederationMDCountApi } from "../metadata/api";

const mergeByProperty = (member, metadata, prop) => {
  let countAttribute = "count";
  for (let i = 0; i < member.length; i++) {
    let targetElement = member[i];
    for (let j = 0; j < metadata.length; j++) {
      let sourceElement = metadata[j];
      if (targetElement[prop] === sourceElement[prop]) {
        targetElement
          ? Object.assign(targetElement, sourceElement)
          : member[i].push(sourceElement);
      }
    }
    if (!targetElement.hasOwnProperty(countAttribute)) {
      targetElement.count = 0;
    }
  }
};

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);
const logWarning = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

function* fetchMember(action) {
  const { federationId, customerId } = action;
  const { resp = false, error = false } = yield call(fetchMemberApi, {
    federationId,
    customerId
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      loadMemberSuccess({
        customerId,
        data
      })
    );
  } else if (error) {
    logError("fetchMember", error);
    yield put(
      loadMemberFailure({
        customerId,
        error: yield error
      })
    );
  }
}

function* validateMember(action) {
  const { federationId, customerId } = action;
  const { resp = false, error = false } = yield call(validateCustomerId, {
    federationId,
    customerId
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      validateMemberSuccess({
        customerId,
        data
      })
    );
  } else if (error) {
    yield put(
      validateMemberFailure({
        customerId,
        error: yield error
      })
    );
  }
}

// CREATE_MEMBER
function* createMember(action) {
  const { federationId, data } = action;
  const { resp = false, error = false } = yield call(createMemberApi, {
    federationId,
    data
  });

  if (resp) {
    yield resp.json();
    yield put(
      createdMemberSuccess({
        createMemberId: action.createMemberId,
        data
      })
    );
  } else if (
    error &&
    error.statusCode !== undefined &&
    error.statusCode === 400
  ) {
    logWarning("createMemberWarning", error);
    yield put(
      createMemberWarning({
        createMemberId: action.createMemberId,
        data,
        info: error
      })
    );
  } else if (error) {
    logError("createMember", error);
    yield put(
      createMemberFailure({
        createMemberId: action.createMemberId,
        error: yield error
      })
    );
  }
}
// Update member
function* updateMember(action) {
  const { federationId, customerId } = action;
  const { resp = false, error = false } = yield call(updateMemberApi, {
    federationId,
    customerId,
    data: action.data
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      updateMemberSuccess({
        customerId: customerId,
        data
      })
    );
  } else if (error) {
    logError("updateMember", error);
    yield put(
      updateMemberFailure({
        customerId: customerId,
        error: yield error
      })
    );
  }
}
// Activate member
function* activateMember(action) {
  const { federationId, customerId, active } = action;
  const { resp = false, error = false } = yield call(activateMemberApi, {
    federationId,
    customerId,
    active
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      activateMemberSuccess({
        customerId: customerId,
        data,
        active
      })
    );
  } else if (error) {
    logError("activateMember", error);
    yield put(
      activateMemberFailure({
        customerId: customerId,
        error: yield error
      })
    );
  }
}
// Update member
function* updateTechnicalContact(action) {
  const { federationId, customerId, userId } = action;
  const { resp = false, error = false } = yield call(
    updateTechnicalContactApi,
    {
      federationId,
      customerId,
      userId,
      data: action.data
    }
  );

  if (resp) {
    const data = yield resp.json();
    yield put(
      updateTechnicalContactSuccess({
        customerId: customerId,
        userId: userId,
        data
      })
    );
  } else if (error) {
    logError("updateTechnicalContact", error);
    yield put(
      updateTechnicalContactFailure({
        customerId: customerId,
        userId: userId,
        error: yield error
      })
    );
  }
}

function* deleteMember(action) {
  const { federationId, customerId } = action;
  const { resp = false, error = false } = yield call(deleteMemberApi, {
    federationId,
    customerId
  });
  if (resp) {
    yield put(
      deleteMemberSuccess({
        customerId
      })
    );
    yield put(push(`/federation/${federationId}`));
  } else {
    yield put(
      deleteMemberFailure({
        customerId,
        error: yield error
      })
    );
  }
}

// SEARCH_MEMBER
function* searchMember(action) {
  const { federationId, query, filterBy } = action;
  const { members, metadata } = yield all({
    members: call(fetchMemberSearchApi, { federationId, query, filterBy }),
    metadata: call(fetchFederationMDCountApi, { federationId })
  });
  if (members && metadata) {
    let data = yield members.resp.json();
    const mData = yield metadata.resp.json();

    // merge results -> data
    mergeByProperty(data, mData, "customerId");
    yield put(
      searchMemberSuccess({
        data
      })
    );
  } else if (members) {
    logError("searchMember no metadata", metadata.resp.error);
    const data = members.resp.json();
    yield put(
      searchMemberSuccess({
        data
      })
    );
  } else {
    logError("searchMember", members.resp.error);
    yield put(
      searchMemberFailure({
        error: yield members.resp.error
      })
    );
  }
}

export {
  fetchMember,
  validateMember,
  updateMember,
  activateMember,
  updateTechnicalContact,
  createMember,
  searchMember,
  deleteMember
};
