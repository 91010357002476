import React from "react";
import { ResellerRouts } from "../../../modules/reseller/routs";
import { Page } from "../../Page";

import PropTypes from "prop-types";

export const PageResellers = props => (
  <div>
    {props.userSession && props.userSession.realm && (
      <Page
        {...props}
        history={props.history}
        federationId={props.userSession.realm}
        {...props}
      >
        <ResellerRouts mock={props.mock} history={props.history} {...props} />
      </Page>
    )}
  </div>
);

PageResellers.propTypes = {
  history: PropTypes.object
};

export default PageResellers;
