import React, { Component } from "react";
import PropTypes from "prop-types";
import { Header, Table, Container } from "semantic-ui-react";
import { JsonView } from "../../../helpers/json/JsonView";
import { MetadataDiff } from "components/lib/metadatadiff";

const moment = require("moment");

export class MetaDataApproveDiffView extends Component {
  constructor(props) {
    super(props);

    const { entityResponse } = this.props;
    const entity = entityResponse.entityToApprove;
    this.state = {
      entityId: entity.entityId,
      entity: entity,
      diff:
        entityResponse &&
        entityResponse.diff &&
        entityResponse.diff.map(item => this.proccessDiffData(item)),
      currentEntity: entityResponse.currentEntity
    };
  }

  proccessDiffData(diff) {
    if (diff && this.isDiffScope(diff)) {
      return {
        field: diff.field,
        from: [...new Set(diff.from)],
        to: [...new Set(diff.to)]
      };
    } else {
      return diff;
    }
  }

  isDiffScope(diff) {
    return diff && diff.field && diff.field === "shibmd:Scope";
  }

  render() {
    const { entity, currentEntity, diff, entityId } = this.state;
    return (
      <React.Fragment>
        {false && <JsonView title="State:" json={this.state} />}
        <Header as="h3">Proposal</Header>
        <Table>
          <Table.Body>
            <Table.Row>
              <Table.Cell style={{ background: "#F5F5F5" }}>
                Submitted
              </Table.Cell>
              <Table.Cell>
                {moment(entity.submitted).format("YYYY-MM-DD kk:mm:ss")} by{" "}
                {entity.submittedBy}
              </Table.Cell>
            </Table.Row>
            {entity.aggregationValidationErrors !== null && (
              <Table.Row>
                <Table.Cell style={{ background: "#e51c23" }}>Error</Table.Cell>
                <Table.Cell>{entity.aggregationValidationErrors}</Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {currentEntity && (
          <Container>
            <Header as="h3">Previously Approved</Header>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ background: "#F5F5F5" }}>
                    Submitted
                  </Table.Cell>
                  <Table.Cell>
                    {currentEntity.submitted} by {currentEntity.submittedBy}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell style={{ background: "#F5F5F5" }}>
                    Approved
                  </Table.Cell>
                  <Table.Cell>
                    {currentEntity.approvedTime} by {currentEntity.approvedBy}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Container>
        )}

        <Header as="h3">Approval fields</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>From</Table.HeaderCell>
              <Table.HeaderCell>To</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {diff.map(({ field, from, to }, index) => (
              <Table.Row key={index}>
                <Table.Cell>{field}</Table.Cell>
                <Table.Cell>
                  {from.map(value => {
                    return <p>{value}</p>;
                  })}
                </Table.Cell>
                <Table.Cell>
                  {to.map((value, index) => {
                    return <p key={index}>{value}</p>;
                  })}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Header as="h3">DiffView</Header>
        <MetadataDiff
          file1={currentEntity ? currentEntity.metadata : ""}
          file2={entity.metadata}
          fileName={entityId}
        />
      </React.Fragment>
    );
  }
}

MetaDataApproveDiffView.propTypes = {
  entityId: PropTypes.string.isRequired
};

export default MetaDataApproveDiffView;
