import React, { Component } from "react";
import { Accordion, Header, Button, Grid } from "semantic-ui-react";
import { JsonView } from "../../../helpers/json/JsonView";

const moment = require("moment");

export class ApproveEntityList extends Component {
  constructor() {
    super();
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps = entities => {
    if (entities) {
      this.setState({ entities: entities.entities });
    }
  };

  render() {
    const { entities, handlePageChange } = this.props;
    return (
      <React.Fragment>
        {false && <JsonView title="State:" json={this.state} />}
        {entities && (
          <Accordion styled fluid>
            {entities.map(entity => (
              <React.Fragment key={entity.entityId}>
                <Accordion.Title>
                  <Grid stackable>
                    <Grid.Row>
                      <Grid.Column width="9">
                        <Header
                          as="h3"
                          style={{ padding: "10px", wordWrap: "break-word" }}
                        >
                          {entity.entityId}
                        </Header>
                      </Grid.Column>
                      <Grid.Column width="2" style={{ padding: "10px 0" }}>
                        <span>{entity.customerId}</span>
                      </Grid.Column>
                      <Grid.Column width="3" style={{ padding: "10px 0" }}>
                        <span>
                          {moment(entity.submittedTime).format(
                            "YYYY-MM-DD kk:mm:ss"
                          )}
                        </span>
                      </Grid.Column>
                      <Grid.Column width="2" style={{ textAlign: "right" }}>
                        <Button
                          secondary
                          onClick={() =>
                            handlePageChange(1, entity.entityId, entity)
                          }
                        >
                          Review
                        </Button>
                      </Grid.Column>

                      <div style={{ clear: "both" }} />
                    </Grid.Row>
                  </Grid>
                </Accordion.Title>
              </React.Fragment>
            ))}
          </Accordion>
        )}
      </React.Fragment>
    );
  }
}

export default ApproveEntityList;
