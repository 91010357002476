import React from "react";
import {
  Button,
  Segment,
  Header,
  Modal,
  Dimmer,
  Loader,
  Grid,
  Divider,
  TextArea
} from "semantic-ui-react";
// import PropTypes from "prop-types";
import { ErrorMessage } from "../../../common/error";
import { MetadataValidationStatus } from "./MetadataValidationStatus";

export class EntityApproveView extends React.Component {
  constructor({ entityId, approve, federationId, customerId, message }) {
    super();

    this.state = { entityId, approve, federationId, customerId, message };
    this.handleMetadataValidationChange = this.handleMetadataValidationChange.bind(
      this
    );
  }
  UNSAFE_componentWillReceiveProps({ metadataApproveResp }) {
    if (metadataApproveResp && this.state.isSubmitted) {
      const resp = metadataApproveResp[this.state.entityId];
      if (resp && resp.updated !== undefined) {
        if (resp.updated) {
          this.setState({
            approveSuccess: true,
            approveError: undefined,
            loading: false
          });
          this.handleClose();
          this.props.reloadMetadata(this.state.federationId);
        } else {
          this.setState({
            approveSuccess: undefined,
            approveError: resp.error,
            loading: false
          });
        }
      }
    }
  }

  handleChange(event) {
    this.setState({ message: event.target.value });
  }

  handleSubmit = (entityId, approve, message) => {
    this.setState({ isSubmitted: true, loading: true });
    if (approve) {
      this.props.handleSubmit(entityId, approve);
    } else {
      this.props.handleSubmit(entityId, approve, message);
    }
  };

  handleOpen = () => {
    this.setState({ modalOpen: true, validationReady: false });
  };

  handleClose = () => {
    this.setState({
      approveSuccess: undefined,
      approveError: undefined,
      modalOpen: false,
      entityValidationStatus: undefined,
      validationReady: undefined
    });
  };

  handleMetadataValidationChange = (event, value) => {
    if (event === "loading" && value !== undefined) {
      const currentValidationReady = this.state.validationReady;
      const validationReady = !value;
      if (currentValidationReady === undefined) {
        this.setState({ validationReady: false });
      } else if (currentValidationReady !== validationReady) {
        this.setState({ validationReady: validationReady });
      }
    }
  };

  render() {
    const {
      federationId,
      entityId,
      approve,
      approveError,
      customerId,
      loading,
      message
    } = this.state;

    return (
      <Modal
        trigger={
          approve ? (
            <Button onClick={this.handleOpen} primary>
              Approve
            </Button>
          ) : (
            <Button onClick={this.handleOpen} color="red">
              Reject
            </Button>
          )
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content>
          <Modal.Description>
            {approve && (
              <Header as="h1">Approve entity for member {customerId}</Header>
            )}
            {!approve && (
              <Header as="h1">Reject entity for member {customerId}</Header>
            )}

            <MetadataValidationStatus
              federationId={federationId}
              customerId={customerId}
              entityId={entityId}
              loadMetadataValidateRequest={
                this.props.loadMetadataValidateRequest
              }
              federationMetadataValidateFetchResp={
                this.props.federationMetadataValidateFetchResp
              }
              approval={true}
              onChange={(event, value) =>
                this.handleMetadataValidationChange(event, value)
              }
            />

            {approveError && (
              <ErrorMessage title="Failed to update entity" {...approveError} />
            )}
            {approve && (
              <React.Fragment>
                <Header as="h3">Are you sure you want to approve </Header>
                <div style={{ wordWrap: "break-word", font: "bold" }}>
                  {entityId}?
                </div>
                <p> </p>
                <div>The entity will become available for publish</div>
              </React.Fragment>
            )}
            {!approve && (
              <React.Fragment>
                <Header as="h3">Are you sure you want to reject </Header>
                <div style={{ wordWrap: "break-word", font: "bold" }}>
                  {entityId}?
                </div>
                <p> </p>
                <div>The entity will be removed</div>
              </React.Fragment>
            )}
            <Segment>
              {loading && (
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              )}
              {approve && (
                <React.Fragment>
                  <Button
                    disabled={!this.state.validationReady}
                    type="submit"
                    primary
                    onClick={() =>
                      this.handleSubmit(entityId, approve, message)
                    }
                  >
                    Approve
                  </Button>
                  <Button type="cancel" secondary onClick={this.handleClose}>
                    Cancel
                  </Button>
                </React.Fragment>
              )}
              {!approve && (
                <Grid align="left">
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <TextArea
                        placeholder="Message ..."
                        onBlur={this.handleChange.bind(this)}
                        rows={10}
                        cols={60}
                      ></TextArea>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column width={2}>
                      <Divider vertical hidden />
                      <Button
                        type="submit"
                        color="red"
                        onClick={() =>
                          this.handleSubmit(entityId, approve, message)
                        }
                      >
                        Reject
                      </Button>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <Button
                        type="button"
                        secondary
                        onClick={this.handleClose}
                      >
                        Cancel
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

// EntityApproveView.propTypes = {
// };

export default EntityApproveView;
