import React from "react";
import PropTypes from "prop-types";
import {
  Header,
  Table,
  Container,
  Icon,
  Message,
  Button
} from "semantic-ui-react";

import { ErrorMessage } from "../../../common/error";
import { JsonView } from "../../../helpers/json/JsonView";
import { Link } from "react-router-dom";
import { ResellerDeleteView } from "../form/ResellerDeleteView";
import {
  authorize,
  isUserAuthorized
} from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";

let header = [
  {
    id: 1,
    displayName: "RESELLER",
    textAlign: "left"
  },
  {
    id: 2,
    displayName: "MEMBERS",
    textAlign: "left"
  }
];
const headerAuthorized = [
  {
    id: 1,
    displayName: "RESELLER",
    textAlign: "left"
  },
  {
    id: 2,
    displayName: "MEMBERS",
    textAlign: "left"
  },
  {
    id: 3,
    displayName: "EDIT",
    textAlign: "center"
  },
  {
    id: 4,
    displayName: "DELETE",
    textAlign: "center"
  }
];

export class ResellersTableView extends React.Component {
  constructor(props) {
    super();

    this.state = {
      mock: props.mock,
      accessToken: props.accessToken,
      roles: props.userSession.roles,
      isReadOnly: !isUserAuthorized(
        authMapping.UPDATE_RESELLER,
        props.userSession.roles
      ),
      federationId: props.userSession.realm
    };
  }

  UNSAFE_componentWillReceiveProps({ resellersFetchResp, userSession }) {
    this.loadData(resellersFetchResp);
    if (userSession) {
      const { roles } = userSession;
      this.setState({ roles });
      if (
        authorize(authMapping.UPDATE_RESELLER, roles, <div />) !== undefined
      ) {
        header = headerAuthorized;
      }
      if (!this.state.resellersFetched) {
        const federationId = userSession.realm;
        this.setState({ federationId, resellersFetched: true });
        this.fetchResellers(federationId);
      }
    }
  }

  componentDidMount = () => {
    if (this.state.federationId) {
      this.setState({ resellersFetched: true });
      this.fetchResellers(this.state.federationId);
    }
  };

  loadData(resellersFetchResp) {
    if (resellersFetchResp) {
      if (resellersFetchResp.fetched) {
        const data = resellersFetchResp.data;
        this.setState({
          fetchResellersSuccess: data
        });
      } else {
        this.setState({
          fetchResellersError: resellersFetchResp.error
        });
      }
    }
  }

  fetchResellers = federationId => {
    if (this.state.mock) {
      this.setState({
        fetchResellersSuccess: [
          {
            prefixCode: "IIS",
            name: "Test agent"
          }
        ],
        total: 1
      });
    } else {
      this.props.loadResellersRequest({ federationId });
    }
  };

  reloadResellers = () => {
    this.props.loadResellersRequest({ federationId: this.state.federationId });
  };

  stopPropEvent = event => {
    event.stopPropagation();
  };

  render() {
    const {
      fetchResellersSuccess,
      fetchResellersError,
      roles,
      federationId
    } = this.state;
    return (
      <Container fluid>
        {false && <JsonView title="Component state:" json={this.state} />}
        <Header as="h1">
          Resellers
          {authorize(
            authMapping.CREATE_RESELLER,
            roles,
            <Link to={`/federation/${federationId}/resellers/create/`}>
              <Button floated="right" primary>
                Add reseller
              </Button>
            </Link>
          )}
        </Header>

        {fetchResellersSuccess && fetchResellersSuccess.length > 0 && (
          <Table celled striped selectable sortable>
            <Table.Header>
              <Table.Row>
                {header.map(({ id, displayName }) => (
                  <Table.HeaderCell key={id} textAlign="center">
                    {displayName}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>
            <Table.Body sortable={"true"}>
              {fetchResellersSuccess &&
                fetchResellersSuccess.map(
                  ({ prefixCode, name, numberOfMembers }) => (
                    <Table.Row
                      key={prefixCode}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        this.props.history.push(
                          `/federation/${federationId}/resellers/${prefixCode}/edit/`
                        );
                      }}
                    >
                      <Table.Cell textAlign="center">{name}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {numberOfMembers}
                      </Table.Cell>
                      {authorize(
                        authMapping.UPDATE_RESELLER,
                        roles,
                        <Table.Cell
                          textAlign="center"
                          style={{ width: "160px" }}
                        >
                          <Icon size="large" name="pencil" />
                        </Table.Cell>
                      )}

                      {authorize(
                        authMapping.DELETE_RESELLER,
                        roles,
                        <Table.Cell
                          textAlign="center"
                          style={{ width: "160px" }}
                          onClick={this.stopPropEvent}
                        >
                          <ResellerDeleteView
                            prefixCode={prefixCode}
                            reload={this.reloadResellers}
                            resellerDeleteResp={this.props.resellerDeleteResp}
                            deleteResellerRequest={
                              this.props.deleteResellerRequest
                            }
                            name={name}
                            noOfMembers={numberOfMembers}
                            federationId={federationId}
                          />
                        </Table.Cell>
                      )}
                    </Table.Row>
                  )
                )}
            </Table.Body>
          </Table>
        )}

        {fetchResellersSuccess && fetchResellersSuccess.length === 0 && (
          <Message warning>No resellers found!</Message>
        )}
        {fetchResellersError && (
          <ErrorMessage title="Error" {...fetchResellersError} />
        )}
        {false && <JsonView title="Component state:" json={this.state} />}
        {false && (
          <JsonView title="Search response" json={fetchResellersSuccess} />
        )}
      </Container>
    );
  }
}

ResellersTableView.propTypes = {
  loadResellersRequest: PropTypes.func.isRequired
};

export default ResellersTableView;
