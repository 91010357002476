import { call, put } from "redux-saga/effects";
import {
  createTechnicalAgentApi,
  updateTechnicalAgentApi,
  deleteTechnicalAgentApi,
  fetchTechnicalAgentApi,
  fetchTechnicalAgentsApi
} from "./api";

// FETCH_MEMBER

import {
  loadTechnicalAgentSuccess,
  loadTechnicalAgentFailure,
  loadTechnicalAgentsSuccess,
  loadTechnicalAgentsFailure,
  createTechnicalAgentSuccess,
  createTechnicalAgentFailure,
  updateTechnicalAgentSuccess,
  updateTechnicalAgentFailure,
  deleteTechnicalAgentSuccess,
  deleteTechnicalAgentFailure
} from "../../ducks/member/actions";

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

function* fetchTechnicalAgent(action) {
  const { federationId, customerId } = action;
  const { resp = false, error = false } = yield call(fetchTechnicalAgentApi, {
    federationId,
    customerId
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      loadTechnicalAgentSuccess({
        customerId,
        data
      })
    );
  } else if (error) {
    logError("fetchMember", error);
    yield put(
      loadTechnicalAgentFailure({
        customerId,
        error: yield error
      })
    );
  }
}

function* fetchTechnicalAgents(action) {
  const { federationId, customerId } = action;
  const { resp = false, error = false } = yield call(fetchTechnicalAgentsApi, {
    federationId,
    customerId
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      loadTechnicalAgentsSuccess({
        customerId,
        data
      })
    );
  } else if (error) {
    logError("fetchMember", error);
    yield put(
      loadTechnicalAgentsFailure({
        customerId,
        error: yield error
      })
    );
  }
}

function* createTechnicalAgent(action) {
  const { federationId, customerId } = action;
  const { resp, error } = yield call(createTechnicalAgentApi, {
    federationId,
    customerId,
    data: action.data
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      createTechnicalAgentSuccess({
        data
      })
    );
  } else if (error) {
    logError("createTechnicalAgent", error);
    yield put(
      createTechnicalAgentFailure({
        error: yield error
      })
    );
  }
}
// Update member
function* updateTechnicalAgent(action) {
  const { federationId, customerId, userId } = action;
  const { resp, error } = yield call(updateTechnicalAgentApi, {
    federationId,
    customerId,
    userId,
    data: action.data
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      updateTechnicalAgentSuccess({
        customerId,
        userId,
        data
      })
    );
  } else if (error) {
    logError("updateTechnicalAgent", error);
    yield put(
      updateTechnicalAgentFailure({
        customerId,
        userId,
        error: yield error
      })
    );
  }
}

function* deleteTechnicalAgent(action) {
  const { federationId, customerId, userId } = action;
  const { resp, error } = yield call(deleteTechnicalAgentApi, {
    federationId,
    customerId,
    userId
  });
  if (resp) {
    yield put(
      deleteTechnicalAgentSuccess({
        customerId,
        userId
      })
    );
  } else {
    yield put(
      deleteTechnicalAgentFailure({
        customerId,
        userId,
        error: yield error
      })
    );
  }
}

export {
  fetchTechnicalAgent,
  fetchTechnicalAgents,
  updateTechnicalAgent,
  createTechnicalAgent,
  deleteTechnicalAgent
};
