import {
  SHOUTOUT_SUCCESS,
  SHOUTOUT_FAILURE,
  SHOUTOUT_REQUEST
} from "./actionTypes";

///////////////////////////////////////////////////////////////////////////////
// Fetch Shoutout data
///////////////////////////////////////////////////////////////////////////////

export const loadShoutoutRequest = () => {
  return { type: SHOUTOUT_REQUEST };
};

export const loadShoutoutSuccess = ({ data }) => {
  return { type: SHOUTOUT_SUCCESS, data };
};

export const loadShoutoutFailure = ({ error }) => {
  return { type: SHOUTOUT_FAILURE, error };
};
