import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks/index";
import { PageMetadata } from "../../pages/metadata";

function mapStateToProps(state) {
  return {
    memberMetadataListFetchResp: state.memberMetadataListFetchResp,
    federationMetadataListFetchResp: state.federationMetadataListFetchResp,
    federationMetadataValidateFetchResp:
      state.federationMetadataValidateFetchResp,
    metadataUploadResp: state.metadataUploadResp,
    metadataApproveResp: state.metadataApproveResp,
    metadataFetchResp: state.metadataFetchResp,
    userSession: state.userSession,
    dynamicConfig: state.dynamicConfig,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const PageMetadataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageMetadata);

export default PageMetadataContainer;
