import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks/index";
import { PageMembers } from "../../pages/federation/members";

function mapStateToProps(state) {
  return {
    memberSearchResp: state.memberSearchResp,
    federationMemberMDCountResp: state.federationMemberMDCountResp,
    userSession: state.userSession,
    dynamicConfig: state.dynamicConfig,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const PageSearchMemberContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageMembers);

export default PageSearchMemberContainer;
