import { luhncheck } from "../luhn/luhncheck";
import { parseDate } from "./parseDate";

export function ParsePnr(data, options) {
  // correct type
  if (typeof data !== "number" && typeof data !== "string") {
    return { valid: false, reason: "invalid input type", input: data };
  }

  // string
  data = "" + data;

  const regexp = /^(\d{2}){0,1}(\d{2})(\d{2})(\d{2})([-|+]{0,1})?(\d{2})(\d{1})(\d{1})$/g;
  let group = regexp.exec(data);

  // checks if input is of correct personal number format
  if (!group) {
    return { valid: false, reason: "invalid format", input: data };
  }

  // unpacks regex groups into variables
  let century = group[1];
  let year = group[2];
  let monthNum = group[3];
  let dayNum = group[4];
  let sep = group[5];
  let serialNum = group[6];
  let genderNum = group[7];
  let checkNum = group[8];

  // parses and checks date
  let date = parseDate(+year, +monthNum, +dayNum, sep, century);
  if (!date.valid) {
    return { valid: false, reason: date.reason, input: data };
  }
  date = date.date;

  // luhn control
  if (
    luhncheck("" + year + monthNum + dayNum + serialNum + genderNum) !==
    +checkNum
  ) {
    return { valid: false, reason: "invalid checksum", input: data };
  }

  // calculates age
  let age = ~~((Date.now() - +date) / 31557600000);

  // if validation is forgiving, i.e. if the user possibly incorrectly used a '+' separator instead of a '-' separator
  // this will in most cases produce an age over 120
  if (options && options.forgiving) {
    if (age < 100 && sep === "+") {
      sep = "-";
    } else if (age >= 100 && sep === "-") {
      sep = "+";
    }

    if (!century && age > 120) {
      age -= 100;
      sep = "-";
      date = new Date(
        Date.UTC(date.getFullYear() + 100, date.getMonth(), date.getDate())
      );
    } else if (century && age < 0) {
      age += 100;
      sep = "-";
      date = new Date(
        Date.UTC(date.getFullYear() - 100, date.getMonth(), date.getDate())
      );
    }
  }

  if (options && options.strict) {
    if (
      !(century && options.forgiving) &&
      sep &&
      ((age >= 100 && sep === "-") || (age < 100 && sep === "+"))
    ) {
      return {
        valid: false,
        reason: "age and separator contradict",
        input: data
      };
    }
    if (date > new Date()) {
      return {
        valid: false,
        reason: "personal number is from the future",
        input: data
      };
    }
    if (age > 120) {
      return { valid: false, reason: "age is too old", input: data };
    }
  }

  let normalized =
    date.getFullYear() + monthNum + dayNum + serialNum + genderNum + checkNum;

  let gender;
  if (genderNum % 2 === 0) {
    gender = "female";
  } else {
    gender = "male";
  }

  let type = "personnummer";
  if (dayNum > 60) {
    type = "samordningsnummer";
  }

  return {
    valid: true,
    type: type,
    input: data,
    normalized: normalized,
    date: date,
    age: age,
    gender: gender
  };
}
