import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks/index";
import { getMemberById } from "../../store/ducks/member/selectors";
import { PageMemberDashboard } from "../../pages/federation/members";

function mapStateToProps(state) {
  return {
    userSession: state.userSession,
    memberDeleteResp: state.memberDeleteResp,
    memberFetchResp: state.memberFetchResp,
    memberUpdateResp: state.memberUpdateResp,
    memberActivateResp: state.memberActivateResp,
    technicalContactUpdateResp: state.technicalContactUpdateResp,
    technicalAgentsFetchResp: state.technicalAgentsFetchResp,
    technicalAgentCreateResp: state.technicalAgentCreateResp,
    technicalAgentUpdateResp: state.technicalAgentUpdateResp,
    technicalAgentDeleteResp: state.technicalAgentDeleteResp,
    memberMetadataListFetchResp: state.memberMetadataListFetchResp,
    federationMetadataValidateFetchResp:
      state.federationMetadataValidateFetchResp,
    metadataUploadResp: state.metadataUploadResp,
    metadataUpdateResp: state.metadataUpdateResp,
    metadataDeleteResp: state.metadataDeleteResp,
    getMemberById: getMemberById(state),
    dynamicConfig: state.dynamicConfig,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const PageMemberDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageMemberDashboard);

export default PageMemberDashboardContainer;
