import React from "react";
import { Grid, Segment, List, Image } from "semantic-ui-react";
import { LoggedInUserContainer } from "../../../containers/auth/LoggedInUserContainer";

import { authorize } from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";
import { keycloakInstance } from "../../../keycloak";

const styleEnvironmentWarning = {
  color: "red",
  fontSize: "20px",
  fontWeight: "bold",
  margin: 0,
  whiteSpace: "nowrap"
};

const styleShoutOutInfo = {
  color: "#fff",
  backgroundColor: "#47d147",
  fontSize: "16px",
  fontWeight: "bold",
  margin: 0,
  whiteSpace: "nowrap"
};

const styleEnvInfoTop = {
  fontSize: "20px",
  background: "red",
  color: "white",
  fontWeight: "700",
  opacity: "0.78",
  padding: "0",
  verticalAlign: "middle",
  height: "55px"
};

const styleEnvInfoCol = {
  padding: "10px !important"
};

function isEqual(incomingState, currentState) {
  return JSON.stringify(incomingState) === JSON.stringify(currentState);
}

const EnvironmentWarning = ({ environment }) => (
  <div style={styleEnvironmentWarning}>
    {environment && environment.displayName && environment.displayName}
  </div>
);

const ShoutOut = ({ config }) => (
  <React.Fragment>
    {config && config.text && (
      <Grid.Row style={config.style ? config.style : styleShoutOutInfo}>
        <Grid.Column width={2} />
        <Grid.Column width={12}>{config.text}</Grid.Column>
      </Grid.Row>
    )}
  </React.Fragment>
);

const AdminLink = ({ config, federationId }) => (
  <React.Fragment>
    {config.federations.map(
      (federation, index) =>
        federation &&
        federation.name === federationId && (
          <a
            key={index}
            href={federation.adminUrl}
            target="_BLANK"
            rel="noreferrer"
          >
            Admin
          </a>
        )
    )}
  </React.Fragment>
);

export class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.setDynamicConfig(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setDynamicConfig(nextProps);
    if (nextProps.shoutoutResp && nextProps.shoutoutResp.data) {
      this.setShoutout(nextProps);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // TODO make a object comparison
    if (nextState && this.state) {
      return !isEqual(nextState, this.state);
    }
    return true;
  }

  setDynamicConfig(props) {
    const incomingConfig = props.dynamicConfig;
    if (incomingConfig) {
      const { enabled, config } = incomingConfig;
      if (enabled === true && config) {
        this.setState({ config: { ...config } });
      }
    }
  }

  setShoutout({ shoutoutResp }) {
    const { data } = shoutoutResp;
    const shoutout = {
      text: data[0].message,
      active: data[0].active
    };
    this.setState({
      shoutout: shoutout
    });
  }

  render() {
    const keycloak = keycloakInstance && keycloakInstance.keycloak;
    const roles =
      keycloak && keycloak.realmAccess ? keycloak.realmAccess.roles : [];

    const realm = keycloak && keycloak.realm;
    const baseUrl = `/#/federation/${realm}`;

    //Get logo for federation
    let federationId;
    const allowedRealms = [
      "skolfed",
      "skolfed-trial",
      "sambi",
      "sambi-trial",
      "sambi-acc",
      "kontosynk",
      "kontosynk-trial",
      "fedvis",
      "fedvis-trial",
      "fedvis-acc",
      "supplier"
    ];
    if (allowedRealms.includes(realm)) {
      federationId = realm;
    } else {
      alert("Realm not allowed");
    }
    const trial = realm && realm.indexOf("-trial") >= 0;
    const acc = realm && realm.indexOf("-acc") >= 0;
    const { config } = this.state;
    const themeMap = {
      skolfed: require("themes-semantic-fedreg-skolfed/assets/images/skolfed/logo.png"),
      "skolfed-trial": require("themes-semantic-fedreg-skolfed/assets/images/skolfed-trial/logo.png"),
      kontosynk: require("themes-semantic-fedreg-skolfed/assets/images/kontosynk/logo.png"),
      "kontosynk-trial": require("themes-semantic-fedreg-skolfed/assets/images/kontosynk-trial/logo.png"),
      sambi: require("themes-semantic-fedreg-sambi/assets/images/sambi/logo.png"),
      "sambi-trial": require("themes-semantic-fedreg-sambi/assets/images/sambi-trial/logo.png"),
      "sambi-acc": require("themes-semantic-fedreg-sambi/assets/images/sambi-acc/logo.png"),
      fedvis: require("themes-semantic-fedreg-fedvis/assets/images/fedvis/logo.png"),
      "fedvis-trial": require("themes-semantic-fedreg-fedvis/assets/images/fedvis-trial/logo.png"),
      "fedvis-acc": require("themes-semantic-fedreg-fedvis/assets/images/fedvis-acc/logo.png"),
      supplier: require("themes-semantic-fedreg-skolfed/assets/images/skolfed/logo.png")
    };
    return (
      <Segment className="footer" vertical>
        <Grid padded stackable columns={1}>
          {this.state.shoutout && this.state.shoutout.active && (
            <ShoutOut config={this.state.shoutout} />
          )}

          {(trial || acc) && (
            // <Grid.Row className="env-info-top">
            <Grid.Row style={styleEnvInfoTop}>
              <Grid.Column width={2} />
              {trial && (
                <Grid.Column width={12} style={styleEnvInfoCol}>
                  This is a Trial environment. Be careful posting sensitive
                  data.
                </Grid.Column>
              )}
              {acc && (
                <Grid.Column width={12} style={styleEnvInfoCol}>
                  This is a Test environment. Be careful posting sensitive data.
                </Grid.Column>
              )}
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={2} />
            <Grid.Column width={9}>
              <div className="logo-container">
                {federationId && (
                  <Image
                    inline
                    verticalAlign="middle"
                    src={themeMap[federationId]}
                    bordered={false}
                    style={{ margin: 0 }}
                  />
                )}
                {config && <EnvironmentWarning {...config} />}
              </div>
            </Grid.Column>
            <Grid.Column width={3}>
              <LoggedInUserContainer history={this.props.history} />
            </Grid.Column>
            <Grid.Column width={2} floated="right" />
          </Grid.Row>

          <Grid.Row className="header-menu">
            <Grid.Column width={2} />
            <Grid.Column width={12} floated="left">
              <List
                horizontal
                divided
                link
                floated="left"
                verticalAlign="middle"
                size="big"
              >
                {authorize(
                  authMapping.LIST_MEMBER,
                  roles,
                  <List.Item as="a" href={baseUrl}>
                    Members
                  </List.Item>
                )}

                {authorize(
                  authMapping.CREATE_MEMBER,
                  roles,
                  <List.Item as="a" href={`${baseUrl}/members/create/`}>
                    Add member
                  </List.Item>
                )}

                {authorize(
                  authMapping.LIST_RESELLER,
                  roles,
                  <List.Item as="a" href={`${baseUrl}/resellers/list/`}>
                    Resellers
                  </List.Item>
                )}

                {authorize(
                  authMapping.APPROVE_METADATA,
                  roles,
                  <List.Item as="a" href={`${baseUrl}/metadata/`}>
                    Approve metadata
                  </List.Item>
                )}

                {authorize(
                  authMapping.READ_ADMIN,
                  roles,
                  <List.Item>
                    {config && config.federations && (
                      <AdminLink config={config} federationId={federationId} />
                    )}
                  </List.Item>
                )}
              </List>
            </Grid.Column>
            <Grid.Column width={2} floated="right" />
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}

export default Top;
