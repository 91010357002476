import React from "react";
// import PropTypes from "prop-types";

import {
  Header,
  Container,
  Segment,
  SegmentGroup,
  Step
} from "semantic-ui-react";

import { JsonView } from "../../../helpers/json/JsonView";
import { ResellerUserTableView } from "../list";
import { ResellerFormView } from "./ResellerFormView";

export class ResellerCreateView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      step: 1,
      federationId: props.federationId
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resellerCreateResp, userSession } = nextProps;
    this.loadData({ resellerCreateResp });

    if (userSession) {
      this.setState({ federationId: userSession.realm });
    }
  }

  loadData({ resellerCreateResp }) {
    if (resellerCreateResp && this.state.prefixCode) {
      const resp = resellerCreateResp[this.state.prefixCode];
      if (resp && resp.created !== undefined) {
        if (resp.created) {
          this.setState({
            createResellerSuccess: resp.data,
            createResellerError: undefined,
            prefixCode: resp.data.prefixCode
          });
          this.goToStep(2);
        } else {
          this.setState({
            createResellerSuccess: undefined,
            createResellerError: resp.error
          });
        }
      }
    }
  }

  handleStepChange = (e, { step }) => {
    this.setState({ step: step });
  };

  handleInputChange = event => {
    const target = event.target;
    this.setState({
      reseller: {
        ...this.state.reseller,
        [target.name]: target.value
      }
    });
  };

  toggleBoolean = () => {
    const newBool = !this.state.reseller.active;

    this.setState({
      reseller: {
        ...this.state.reseller,
        active: newBool
      }
    });
  };
  handleCreateReseller = form => {
    const { federationId } = this.state;
    this.props.createResellerRequest({
      federationId,
      data: { ...form },
      prefixCode: form.prefixCode
    });
    this.setState({ prefixCode: form.prefixCode });
  };

  goToStep = newStep => {
    this.setState({ step: newStep });
  };

  render() {
    const {
      reseller = {},
      step,
      createResellerError,
      prefixCode,
      federationId
    } = this.state;

    return (
      <React.Fragment>
        {false && <JsonView title="Create state:" json={this.state} />}
        {reseller && (
          <Container>
            <Header as="h1" floated="left">
              {reseller.name}
            </Header>
            <Step.Group attached="top">
              <Step active={step === 1} onClick={() => this.goToStep(1)}>
                <Step.Content>
                  <Step.Title>General information</Step.Title>
                  <Step.Description />
                </Step.Content>
              </Step>

              <Step
                active={step === 2}
                disabled={step < 2}
                onClick={() => this.goToStep(2)}
              >
                <Step.Content>
                  <Step.Title>Add reseller user</Step.Title>
                  <Step.Description />
                </Step.Content>
              </Step>
            </Step.Group>

            {step === 1 && (
              <ResellerFormView
                title="General information"
                submitText="Save"
                reseller={{}}
                handleSubmit={this.handleCreateReseller}
                saveError={createResellerError}
                userSession={this.props.userSession}
              />
            )}
            {step === 2 && (
              <React.Fragment>
                <SegmentGroup>
                  <Segment>
                    <ResellerUserTableView
                      {...this.props}
                      prefixCode={prefixCode}
                      federationId={federationId}
                    />
                  </Segment>
                </SegmentGroup>
              </React.Fragment>
            )}
          </Container>
        )}
      </React.Fragment>
    );
  }
}

// ResellerCreateView.propTypes = {
//   accessToken: PropTypes.string.isRequired
// };

export default ResellerCreateView;
