import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks";
import { LoggedInUser } from "../../layout/login";

function mapStateToProps(state) {
  return {
    userSession: state.userSession,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const LoggedInUserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoggedInUser);

export default LoggedInUserContainer;
