import React from "react";
import {
  Button,
  Segment,
  Header,
  Modal,
  Message,
  Dimmer,
  Loader
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../../common/error";

export class MemberDeleteView extends React.Component {
  constructor({
    title = "",
    id,
    name,
    deleteText,
    cancelText,
    floated,
    federationId
  }) {
    super();

    this.state = {
      title,
      id,
      name,
      deleteText,
      cancelText,
      floated,
      federationId
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  // DELETE /member/{customerId}
  handleChange = (e, { name, value }) =>
    this.setState({
      [name]: value
    });

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { id, name, memberDeleteResp } = nextProps;
    this.setState({ id, name });
    if (memberDeleteResp) {
      const resp = memberDeleteResp[this.state.id];
      if (resp && resp.deleted !== undefined) {
        if (resp.deleted) {
          this.setState({
            deleteSuccess: true,
            deleteError: undefined,
            loading: false
          });
        } else {
          this.setState({
            deleteSuccess: undefined,
            deleteError: resp.error,
            loading: false
          });
        }
      }
    }
  }

  handleSubmit = () => {
    const { id, federationId } = this.state;
    if (id) {
      this.setState({ loading: true });
      this.props.deleteMemberRequest({
        federationId: federationId,
        customerId: id
      });
    }
  };
  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () =>
    this.setState({
      deleteSuccess: undefined,
      deleteError: undefined,
      modalOpen: false,
      loading: false
    });
  render() {
    const {
      title = "Delete member",
      deleteText = "Save",
      cancelText = "Cancel",
      id,
      name,
      floated,
      deleteError,
      loading
    } = this.state;

    return (
      <Modal
        trigger={
          <Button color="red" floated={floated} onClick={this.handleOpen}>
            {title}
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description>
            <Header as="h1">
              {title} - ({id})
            </Header>
            {deleteError && (
              <ErrorMessage title="Failed to delete member" {...deleteError} />
            )}
            <Message>
              Do you really want do delete{" "}
              <strong>
                {name} ({id})
              </strong>
              ?
            </Message>

            <Segment>
              {loading && (
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              )}
              <Button type="submit" color="red" onClick={this.handleSubmit}>
                {deleteText}
              </Button>
              <Button type="cancel" secondary onClick={this.handleClose}>
                {cancelText}
              </Button>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

MemberDeleteView.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  form: PropTypes.object
};

export default MemberDeleteView;
