import React from "react";
import { Page } from "../Page";

import PropTypes from "prop-types";
import { MetaDataApproveView } from "../../modules/metadata/MetaDataApproveView";

export const PageMetadata = props => (
  <div>
    {props.userSession && props.userSession.realm && (
      <Page
        {...props}
        history={props.history}
        federationId={props.userSession.realm}
        {...props}
      >
        <MetaDataApproveView mock={false} {...props} />
      </Page>
    )}
  </div>
);

PageMetadata.propTypes = {
  history: PropTypes.object
};

export default PageMetadata;
