import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Form,
  Segment,
  SegmentGroup,
  Radio,
  Message,
  Header,
  Step,
  Dropdown,
  Loader,
  Container
} from "semantic-ui-react";
import { MemberCardView } from "../card/";
import { TechnicalAgentsTable } from "../list/TechnicalAgentsTable";
import {
  ErrorMessage,
  ErrorValidationMessage,
  WarningMessageWrappedAsList
} from "../../../common/error";
import { UserForm } from "./UserForm";
import { validatePnr } from "../../../helpers/pnrvalidator";
import { JsonView } from "../../../helpers/json/JsonView";
import { DetermineRealmTrial } from "../../../utils/common/DetermineRealmTrial";
import { federationInfoRespState } from "../../metadata/federationInfoMapper";

export class MemberFormView extends React.Component {
  constructor({ title, saveText = "Save", step = 1, form = {} }) {
    super();

    this.state = {
      title: title,
      saveText: saveText,
      step: step,
      form: {
        resellerPrefixCode: "",
        hasAo: false,
        hasSp: false,
        active: true,
        technicalContact: {}
      },
      validationError: false,
      technicalAgents: [],
      resellers: []
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleNextWithError = this.handleNextWithError.bind(this);
    this.goToStep = this.goToStep.bind(this);
    this.handleTechnicalAgentsTableReload = this.handleTechnicalAgentsTableReload.bind(
      this
    );
  }

  componentDidMount() {
    const { userSession } = this.props;
    if (userSession.realm && !this.state.resellersFetched) {
      const federationId = userSession.realm;
      this.setState({
        federationId,
        resellersFetched: true,
        loadingReseller: true
      });
      this.props.loadResellersRequest({ federationId });
    }
    if (federationInfoRespState(this.props)) {
      // alert(JSON.stringify(federationInfoRespState(this.props)));
      this.setState(federationInfoRespState(this.props));
      if (this.state.federation) {
        this.setState({ federationType: this.state.federation.federationType });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      memberCreateResp,
      memberValidateResp,
      resellersFetchResp,
      userSession
    } = nextProps;
    if (memberCreateResp) {
      const resp = memberCreateResp[this.state.createMemberId];
      if (resp && resp.created !== undefined) {
        if (resp.created && !resp.info) {
          const { step } = this.state;
          this.setState({
            success: resp.data,
            status: step === 2 && "success",
            step: 3,
            error: undefined,
            customerId: resp.data.customerId,
            loadingSubmit: false
          });
        } else if (resp && resp.info && resp.info.statusCode === 400) {
          const { step } = this.state;
          this.setState({
            success: resp.data,
            status: step === 2 && "success",
            step: 2,
            info: resp.info,
            customerId: resp.data.customerId,
            loadingSubmit: false
          });
        } else {
          this.setState({
            error: resp.error,
            info: undefined,
            success: undefined,
            message: resp.error,
            loadingSubmit: false
          });
        }
      }
    }
    if (memberValidateResp && this.state.step === 1) {
      const id =
        this.state.form.resellerPrefixCode + this.state.form.customerId;
      const resp = memberValidateResp[id];
      if (resp) {
        if (resp.fetched) {
          this.setState({
            error: undefined,
            status: "edit",
            step: this.state.step + 1
          });
        } else {
          this.setState({
            validationError: resp.error.message
          });
        }
      }
    }
    if (resellersFetchResp) {
      this.setState({ loadingReseller: false });
      this.loadResellersData(resellersFetchResp);
    }

    if (userSession) {
      this.setState({ roles: userSession.roles });
    }
    if (userSession && userSession.realm && !this.state.resellersFetched) {
      const federationId = userSession.realm;
      this.setState({
        federationId,
        resellersFetched: true,
        loadingReseller: true,
        ssnRequired: DetermineRealmTrial(federationId)
      });
      this.props.loadResellersRequest({ federationId });
    }
    if (federationInfoRespState(nextProps)) {
      // alert(JSON.stringify(federationInfoRespState(nextProps)));
      this.setState(federationInfoRespState(nextProps));
      if (this.state.federation) {
        this.setState({ federationType: this.state.federation.federationType });
      }
    }
  }

  loadResellersData(resellersFetchResp) {
    if (resellersFetchResp.fetched) {
      const resellers = [];
      resellersFetchResp.data.forEach(reseller => {
        const resellerOpt = {
          text: reseller.prefixCode,
          value: reseller.prefixCode
        };
        resellers.push(resellerOpt);
      });
      if (resellers.length === 1) {
        this.setState({
          resellers: [...resellers],
          form: {
            ...this.state.form,
            ...{ resellerPrefixCode: resellers[0].value }
          }
        });
      } else {
        this.setState({ resellers: [...resellers] });
      }
    } else {
      this.setState({ fetchResellersError: resellersFetchResp.error });
    }
  }

  handleChange = event => {
    let { name, value } = event.target;
    this.setState({
      status: "edit",
      form: {
        ...this.state.form,
        ...{ [name]: value }
      },
      error: undefined
    });
  };

  handlePrefixChange = (event, data) => {
    const name = data.name;
    const value = data.value;
    this.setState({
      status: "edit",
      form: {
        ...this.state.form,
        ...{ [name]: value }
      },
      error: undefined
    });
  };

  handleTechnicalContactChange = event => {
    let { name, value } = event.target;
    let validSSN = {}; // result from pnr validate
    let validSSNInput = true;

    event.preventDefault();

    if (name === "ssn" && value !== undefined) {
      validSSN = validatePnr(value, {
        strict: true,
        forgiving: true
      });
      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && value.length < 12) {
        validSSNInput = false;
      }
    }

    this.setState({
      status: "edit",
      formIsDirty: true,
      ssnValid: validSSNInput,
      form: {
        ...this.state.form,
        technicalContact: {
          ...this.state.form.technicalContact,
          ...{ [name]: value }
        }
      },
      error: undefined
    });
  };

  toggle = name => {
    const state = this.state.form[name];
    this.setState({ form: { ...this.state.form, ...{ [name]: !state } } });
  };

  handleSubmit = () => {
    const { step = 1, federationType } = this.state;

    if (step === 1) {
      const { customerId, name, resellerPrefixCode } = this.state.form;

      const error = {
        name: this.hasValidationError(name),
        prefix: this.hasValidationError(resellerPrefixCode),
        customerId: this.hasValidationErrorCustomerId(customerId),
        spAo: this.hasValidationErrorSpAo(
          this.state.form.hasAo,
          this.state.form.hasSp
        )
      };
      let errorText = undefined;
      if (error.prefix) {
        errorText = "You need to choose a prefix code for the member";
      } else if (error.customerId) {
        errorText =
          "CustomerId has to be between 1-16 characters and can only contain a-z and 0-9";
      }

      let validationMessage = "Error";
      if (federationType && federationType === "SAML") {
        validationMessage = "One of IdP or Sp has to be marked as allowed";
      } else if (federationType && federationType === "FedTLS") {
        validationMessage =
          "One of Client or Server has to be marked as allowed";
      }
      this.setState({
        prefixCode: resellerPrefixCode,
        validationError: errorText,
        validationErrorAoSp: error.spAo ? validationMessage : undefined
      });

      if (
        error.name !== false ||
        error.customerId !== false ||
        error.spAo ||
        error.prefix
      ) {
        this.setState({ error: error });
      }
      if (!error.name && !error.customerId && !error.spAo && !error.prefix) {
        const id = resellerPrefixCode + customerId;
        const { federationId } = this.state;
        this.props.validateMemberRequest({ federationId, customerId: id });
      }
    }

    if (step === 2) {
      const { resellerPrefixCode } = this.state.form;
      const createMemberId = `${resellerPrefixCode}${this.state.form.customerId}`;
      const { federationId } = this.state;
      this.setState({
        createMemberId,
        loadingSubmit: true,
        ssnRequired: DetermineRealmTrial(federationId)
      });
      this.props.createMemberRequest({
        federationId,
        data: { ...this.state.form, resellerPrefixCode },
        createMemberId
      });
    }
  };

  hasValidationError = value => {
    let status;
    status = value === undefined || value === "";
    return status;
  };

  hasValidationErrorCustomerId = value => {
    const regEx = /^[0-9a-zA-Z]+$/;
    const status =
      value === undefined ||
      !value.match(regEx) ||
      value.length < 1 ||
      value.length > 16;
    return status;
  };

  hasValidationErrorSpAo = (hasSp, hasAo) => {
    return !hasSp && !hasAo;
  };

  goToStep(step) {
    this.setState({
      step
    });
  }

  handleNextWithError() {
    const { step } = this.state;
    if (step === 2) {
      this.setState({
        step: 3,
        form: this.state.form
      });
    }
  }

  handleTechnicalAgentsTableReload() {
    const customerId = this.state.prefixCode + this.state.success.customerId;
    const { federationId } = this.state;
    this.props.loadTechnicalAgentsRequest({ federationId, customerId });
  }

  render() {
    const {
      step,
      status,
      form = undefined,
      error = undefined,
      info = undefined,
      success,
      validationError,
      validationErrorAoSp,
      resellers,
      fetchResellersError,
      federationId,
      roles,
      loadingReseller,
      loadingSubmit,
      prefixCode,
      formIsDirty,
      ssnRequired = DetermineRealmTrial(federationId),
      federationType
    } = this.state;

    let showButton = false;
    if (this.state.step === 2) {
      if (ssnRequired && this.state.form.technicalContact.ssn !== undefined) {
        showButton = formIsDirty && this.state.ssnValid;
      } else if (
        this.state.form.technicalContact.ssn !== undefined &&
        this.state.ssnValid &&
        formIsDirty
      ) {
        showButton = formIsDirty && this.state.ssnValid;
      } else if (
        formIsDirty &&
        !ssnRequired &&
        (this.state.form.technicalContact.ssn === undefined ||
          this.state.form.technicalContact.ssn === "")
      ) {
        showButton = true;
      }
    }

    return (
      <React.Fragment>
        <Header as="h1">Add Member</Header>
        <SegmentGroup>
          <Step.Group attached="top">
            <Step
              active={step === 1}
              disabled={step === 3}
              onClick={() => this.goToStep(1)}
            >
              <Step.Content>
                <Step.Title>Add general information</Step.Title>
                <Step.Description />
              </Step.Content>
            </Step>

            <Step
              active={step === 2}
              disabled={step !== 2}
              onClick={() => this.goToStep(2)}
            >
              <Step.Content>
                <Step.Title>Add technical contact</Step.Title>
                <Step.Description />
              </Step.Content>
            </Step>

            <Step
              active={step === 3}
              disabled={step < 3}
              onClick={() => this.goToStep(3)}
            >
              <Step.Content>
                <Step.Title>Add Technical agents</Step.Title>
              </Step.Content>
            </Step>
          </Step.Group>

          <Segment>
            {loadingReseller && <Loader active inline="centered" />}
            {!fetchResellersError &&
              !loadingReseller &&
              resellers.length === 0 && (
                <Message
                  warning
                  content="Members need to be connected to a reseller. Please add a reseller to be able to create new members."
                />
              )}
            {!loadingReseller && (
              <Form
                onSubmit={() => this.handleSubmit()}
                success={success}
                error={error !== undefined || validationError !== undefined}
                warning={
                  status === "warning" || (info && info.statusCode === 400)
                }
              >
                {step === 1 && (
                  <div>
                    <Form.Field />
                    {validationError && (
                      <ErrorValidationMessage
                        title=""
                        message={validationError}
                      />
                    )}
                    {fetchResellersError && (
                      <ErrorMessage {...fetchResellersError} />
                    )}
                    <Form.Group widths="2">
                      {resellers && resellers.length === 1 && (
                        <Form.Field
                          id="form-input-control-prefix"
                          control="input"
                          label="Prefix"
                          placeholder="Prefix code"
                          value={resellers[0].value}
                          maxLength="3"
                          style={{ background: "#DCDCDC" }}
                          readOnly={true}
                        />
                      )}
                      {resellers && resellers.length > 1 && (
                        <Form.Field required>
                          <label>Prefix</label>
                          <Dropdown
                            id="form-input-control-prefix"
                            name="resellerPrefixCode"
                            fluid
                            selection
                            value={form && form.resellerPrefixCode}
                            placeholder="Select prefix"
                            options={resellers}
                            onChange={this.handlePrefixChange}
                          />
                        </Form.Field>
                      )}
                      <Form.Field>
                        <Form.Input
                          name="customerId"
                          label="CustomerID"
                          placeholder="CustomerID"
                          onChange={this.handleChange}
                          value={form && form.customerId}
                          required
                          autoFocus
                          maxLength="16"
                        />
                      </Form.Field>
                    </Form.Group>
                    <Form.Field>
                      <Form.Input
                        name="name"
                        label="Name"
                        placeholder="Name"
                        onChange={this.handleChange}
                        value={form && form.name}
                        required
                        maxLength="255"
                      />
                    </Form.Field>
                    {!validationErrorAoSp &&
                      federationType &&
                      federationType === "SAML" && (
                        <Message content="One of Idp or Sp has to be marked as allowed" />
                      )}
                    {!validationErrorAoSp &&
                      federationType &&
                      federationType === "FedTLS" && (
                        <Message content="One of Client or Server has to be marked as allowed" />
                      )}
                    {validationErrorAoSp && (
                      <ErrorValidationMessage
                        title=""
                        message={validationErrorAoSp}
                      />
                    )}
                    {federationType && federationType === "FedTLS" && (
                      <div>
                        <Form.Field>
                          <Radio
                            name="hasAo"
                            toggle
                            label="Allow Client"
                            onChange={() => this.toggle("hasAo")}
                            checked={form && form.hasAo}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            name="hasSp"
                            toggle
                            label="Allow Server"
                            onChange={() => this.toggle("hasSp")}
                            checked={form && form.hasSp}
                          />
                        </Form.Field>
                      </div>
                    )}
                    {federationType && federationType === "SAML" && (
                      <div>
                        <Form.Field>
                          <Radio
                            name="hasAo"
                            toggle
                            label="Allow Idp"
                            onChange={() => this.toggle("hasAo")}
                            checked={form && form.hasAo}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Radio
                            name="hasSp"
                            toggle
                            label="Allow Sp"
                            onChange={() => this.toggle("hasSp")}
                            checked={form && form.hasSp}
                          />
                        </Form.Field>
                      </div>
                    )}
                  </div>
                )}
                {step === 2 && (
                  <div>
                    {false && <JsonView title="STATE" json={this.state} />}
                    {false && <JsonView title="PROPS" json={this.props} />}
                    {info && (
                      <WarningMessageWrappedAsList
                        title="Warning! Member created!"
                        {...info}
                      />
                    )}
                    {error && <ErrorMessage title="Error" {...error} />}
                    <UserForm
                      form={form}
                      error={error}
                      handleChange={this.handleTechnicalContactChange}
                      req={ssnRequired}
                    />
                  </div>
                )}
                {step === 3 && (
                  <div>
                    <div>
                      <Message
                        attached="top"
                        success
                        header="Member is successfully created! "
                      >
                        <p>
                          You could now add technical agents, or continue to
                          work.
                        </p>
                        <Button
                          secondary
                          onClick={() =>
                            (window.location = `/#/federation/${federationId}/members/show/${prefixCode +
                              this.state.success.customerId}/`)
                          }
                        >
                          View member {this.state.success.customerId}
                        </Button>
                      </Message>
                      <TechnicalAgentsTable
                        customerId={prefixCode + this.state.success.customerId}
                        {...this.props}
                        reloadData={this.handleTechnicalAgentsTableReload}
                        handleAdd={this.handleTechnicalAgentsTableReload}
                        roles={roles}
                        federationId={federationId}
                      />
                    </div>
                  </div>
                )}
                <Segment>
                  {step === 1 && (
                    <Button type="submit" primary>
                      Next
                    </Button>
                  )}
                  {step === 2 && (
                    <Container>
                      {!loadingSubmit && !(error || info) && (
                        <Button type="submit" primary disabled={!showButton}>
                          Save
                        </Button>
                      )}
                      {!loadingSubmit && info && (
                        <Button
                          type="button"
                          secondary
                          onClick={this.handleNextWithError}
                        >
                          Next
                        </Button>
                      )}
                      {loadingSubmit && (
                        <Loader active inline inverted={false} />
                      )}
                    </Container>
                  )}
                </Segment>
                {step === 4 && (
                  <MemberCardView customerId={this.state.customerId} />
                )}
              </Form>
            )}
          </Segment>
        </SegmentGroup>
        {false && <JsonView title="View component state" json={this.state} />}
        {false && <JsonView title="View component props" json={this.props} />}
      </React.Fragment>
    );
  }
}

MemberFormView.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  form: PropTypes.object,
  createMemberRequest: PropTypes.func,
  memberCreateResp: PropTypes.object
};

export default MemberFormView;
