import { combineReducers } from "redux";
import { userSession } from "./login/reducers";
import { dynamicConfig } from "./dynamicConfig/reducers";
import { federationInfoResp } from "./federation/reducers";
import { shoutoutResp } from "./contentadmin/reducers";

import {
  memberCreateResp,
  memberUpdateResp,
  memberActivateResp,
  technicalContactUpdateResp,
  memberDeleteResp,
  memberFetchResp,
  memberValidateResp,
  memberSearchResp,
  technicalAgentsFetchResp,
  technicalAgentCreateResp,
  technicalAgentUpdateResp,
  technicalAgentDeleteResp
} from "./member/reducers";

import {
  resellersFetchResp,
  resellerFetchResp,
  resellerCreateResp,
  resellerUpdateResp,
  resellerDeleteResp,
  resellerUsersFetchResp,
  resellerUserCreateResp,
  resellerUserDeleteResp,
  resellerUserUpdateResp
} from "./reseller/reducers";

import {
  memberMetadataListFetchResp,
  federationMetadataListFetchResp,
  federationMetadataValidateFetchResp,
  metadataUploadResp,
  metadataUpdateResp,
  metadataDeleteResp,
  metadataApproveResp,
  metadataFetchResp,
  federationMemberMDCountResp
} from "./metadata/reducers";

// EXPORT ALL ACTIONS ////////////////////////////////////////
export * from "./member/actions";
export * from "./reseller/actions";
export * from "./metadata/actions";
export * from "./login/actions";
export * from "./dynamicConfig/actions";
export * from "./federation/actions";
export * from "./contentadmin/actions";

export const rootReducer = combineReducers({
  dynamicConfig,
  userSession,
  federationInfoResp,
  memberCreateResp,
  memberUpdateResp,
  memberActivateResp,
  technicalContactUpdateResp,
  memberDeleteResp,
  memberFetchResp,
  memberValidateResp,
  memberSearchResp,
  technicalAgentsFetchResp,
  technicalAgentCreateResp,
  technicalAgentUpdateResp,
  technicalAgentDeleteResp,
  resellersFetchResp,
  resellerFetchResp,
  resellerCreateResp,
  resellerUpdateResp,
  resellerDeleteResp,
  resellerUsersFetchResp,
  resellerUserCreateResp,
  resellerUserDeleteResp,
  resellerUserUpdateResp,
  memberMetadataListFetchResp,
  federationMetadataListFetchResp,
  federationMetadataValidateFetchResp,
  metadataUploadResp,
  metadataUpdateResp,
  metadataDeleteResp,
  metadataApproveResp,
  metadataFetchResp,
  federationMemberMDCountResp,
  shoutoutResp
});
