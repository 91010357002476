import React from "react";
import PropTypes from "prop-types";
import { Container, Divider, Message } from "semantic-ui-react";

export const WarningMessageWrappedAsList = ({
  title = "Something went wrong.",
  message
}) => (
  <Container text>
    <Message warning header={title} list={[`Message: ${message}`]} />
    <Divider />
  </Container>
);

WarningMessageWrappedAsList.propTypes = {
  title: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  statusMessage: PropTypes.string,
  message: PropTypes.string
};

export default WarningMessageWrappedAsList;
