import React from "react";
import PropTypes from "prop-types";
import { Table, Icon } from "semantic-ui-react";

const style = {
  topStatusPanel: { border: "solid 2px #d4d4d5", marginBottom: "30px" }
};

export const StatusPanel = ({
  numberOfMetaDataEntities,
  fetchMemberSuccess,
  numberOfTechnicalAgents,
  federationType
}) => {
  // console.log("federationType_status", federationType);
  return (
    <Table style={style.topStatusPanel}>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell>
            Metadata entities ({numberOfMetaDataEntities})
          </Table.HeaderCell>
          <Table.HeaderCell>
            <span style={{ paddingRight: "10px" }}>Active</span>
            <Icon
              name={checkOrBanIcon(fetchMemberSuccess, "active")}
              color={checkOrBanColor(fetchMemberSuccess, "active")}
              size="large"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            {federationType && federationType === "FedTLS" && (
              <span style={{ paddingRight: "10px" }}>Client allowed</span>
            )}
            {federationType && federationType === "SAML" && (
              <span style={{ paddingRight: "10px" }}>Idp allowed</span>
            )}
            <Icon
              name={checkOrBanIcon(fetchMemberSuccess, "hasAo")}
              color={checkOrBanColor(fetchMemberSuccess, "hasAo")}
              size="large"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            {federationType && federationType === "FedTLS" && (
              <span style={{ paddingRight: "10px" }}>Server allowed</span>
            )}
            {federationType && federationType === "SAML" && (
              <span style={{ paddingRight: "10px" }}>Sp allowed</span>
            )}
            <Icon
              name={checkOrBanIcon(fetchMemberSuccess, "hasSp")}
              color={checkOrBanColor(fetchMemberSuccess, "hasSp")}
              size="large"
            />
          </Table.HeaderCell>
          <Table.HeaderCell>
            <span style={{ paddingRight: "10px" }}> Technical agents </span>(
            {numberOfTechnicalAgents})
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </Table>
  );
};

const checkOrBanIcon = (data, type) => (data[type] ? "check circle" : "ban");

const checkOrBanColor = (data, type) => (data[type] ? "green" : "red");

StatusPanel.propTypes = {
  numberOfMetaDataEntities: PropTypes.number,
  fetchMemberSuccess: PropTypes.object,
  numberOfTechnicalAgents: PropTypes.number
};

export default StatusPanel;
