import React, { Component } from "react";
import { Loader, Segment, Container } from "semantic-ui-react";
import Page from "../Page";
import { keycloakInstance } from "../../keycloak";

export class Secured extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: false };
  }
  render() {
    if (
      keycloakInstance &&
      keycloakInstance.keycloak &&
      this.props.userSession &&
      this.props.userSession.realm
    ) {
      return (
        <Page {...this.props} federationId={keycloakInstance.keycloak.realm}>
          {this.props.children}
        </Page>
      );
    }
    return (
      <Page>
        <Container>
          <Segment padded="very">
            <Loader active>Loading</Loader>
          </Segment>
        </Container>
      </Page>
    );
  }
}

export default Secured;
