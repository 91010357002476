import React from "react";
import { MembersTableView } from "./MembersTableView";
import { JsonView } from "../../../helpers/json/JsonView";
import { keycloakInstance } from "../../../keycloak";
import { federationInfoRespState } from "../../metadata/federationInfoMapper";

const WAIT_INTERVAL = 500;
const ENTER_KEY = 13;
const ITEMS_PER_PAGE = 25;

function isEqual(incomingState, currentState) {
  return JSON.stringify(incomingState) === JSON.stringify(currentState);
}

export class MemberSearch extends React.Component {
  constructor({
    query = "*",
    orderBy = "name",
    desc = true,
    mock = false,
    activePage = 1,
    sortState,
    federationId,
    federationType
  }) {
    super();
    this.state = {
      query,
      orderBy: orderBy,
      desc: desc,
      mock: mock,
      activeSortColumn: "name",
      sortState,
      activePage: activePage,
      federationId,
      federationType
    };
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.triggerChange = this.triggerChange.bind(this);
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  componentDidMount() {
    const { fetchSearch } = this.state;

    const keycloak = keycloakInstance && keycloakInstance.keycloak;
    const federationId = keycloak && keycloak.realm;
    if (federationId && !this.state.initFetched) {
      // Init search
      this.fetchSearch({ federationId, ...fetchSearch });
      this.setState({ federationId, initFetched: true });
    }
    if (federationInfoRespState(this.props)) {
      this.setState(federationInfoRespState(this.props));
      if (this.state.federation) {
        this.setState({ federationType: this.state.federation.federationType });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { fetchSearch, memberSearchResp, federationId } = newProps;

    if (fetchSearch && fetchSearch.query !== this.state.fetchSearch.query) {
      this.fetchSearch({
        federationId: this.state.federationId,
        ...fetchSearch
      });
    }

    this.loadSearchResp({ memberSearchResp });

    if (federationId && !this.state.initFetched) {
      this.setState({ federationId, initFetched: true, loadingMembers: true });

      // Init search
      this.props.searchMemberRequest({
        federationId,
        query: "*",
        filterBy: "name"
      });
    }

    if (federationInfoRespState(newProps)) {
      // alert(JSON.stringify(federationInfoRespState(newProps)));
      this.setState(federationInfoRespState(newProps));
      if (this.state.federation) {
        this.setState({ federationType: this.state.federation.federationType });
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // TODO make a object comparison
    if (nextState && this.state) {
      return !isEqual(nextState, this.state);
    }
    return true;
  }

  fetchSearch({ federationId, query = "*", filterBy = "name", desc = true }) {
    this.setState({ loadingMembers: true });
    this.props.searchMemberRequest({ federationId, query, filterBy });
  }

  loadSearchResp({ memberSearchResp }) {
    if (memberSearchResp) {
      const { fetched, data, error } = memberSearchResp;
      if (fetched) {
        this.setState({
          success: data,
          error: false,
          activePage: 1,
          loadingMembers: false
        });
      } else {
        this.setState({
          error: error,
          success: undefined, // message: error,
          activePage: 1,
          loadingMembers: false
        });
      }
    }
  }

  setSearchError(error) {
    this.setState({
      error: error,
      success: false, // message: error,
      activePage: 1,
      loadingMembers: false
    });
  }

  timer = null;

  handleSearch(e) {
    let { value } = e.target;
    clearTimeout(this.timer);
    this.setState({ query: value, loadingMembers: true });
    this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
  }

  handleKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      clearTimeout(this.timer);
      this.triggerChange();
    }
  }

  triggerChange() {
    const { query, loading, federationId } = this.state;
    this.fetchSearch({ federationId, query, loading });
  }

  handleSort({ name, initDecs }) {
    const { sortState } = this.state;
    if (sortState) {
      const newSortState = { [name]: !sortState[name] };
      this.setState({
        activeSortColumn: name,
        sortState: { ...sortState, ...newSortState },
        activePage: 1
      });
    } else {
      this.setState({
        activeSortColumn: name,
        sortState: { [name]: initDecs },
        activePage: 1
      });
    }
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ activePage: activePage });
  }

  render() {
    const {
      query = "*",
      activeSortColumn,
      sortState = { name: false },
      activePage,
      federationId,
      loadingMembers = false,
      federationType
    } = this.state;
    return (
      <div>
        <MembersTableView
          data={{ success: this.state.success, error: this.state.error }}
          handleSearch={this.handleSearch}
          handleSort={this.handleSort}
          handlePaginationChange={this.handlePaginationChange}
          activeSortColumn={activeSortColumn}
          activePage={activePage}
          page={ITEMS_PER_PAGE}
          sortState={sortState}
          federationId={federationId}
          loadingMembers={loadingMembers}
          federationType={federationType}
        />

        {false && (
          <JsonView
            title="Search params"
            json={{ query: query, activeSortColumn, sortState, activePage }}
          />
        )}
        {false && <JsonView title="Component props:" json={this.props} />}
        {false && <JsonView title="Component state:" json={this.state} />}
      </div>
    );
  }
}

export default MemberSearch;
