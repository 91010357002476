export function parseDate(year, month, date, sep, centuryNum) {
  // decrease the month
  month--;
  if (date > 60) {
    date -= 60;
  }

  // get century
  let currentCentury =
    new Date()
      .getFullYear()
      .toString()
      .slice(0, 2) * 100;

  if (!centuryNum) {
    let testDate = new Date(Date.UTC(currentCentury + year, month, date));
    // if the personal number date is larger than the current date it must mean that the personal number is referring to the previous century
    if (testDate > new Date()) {
      currentCentury -= 100;
    }
  } else {
    currentCentury = centuryNum * 100;
  }

  let validDate = new Date(Date.UTC(currentCentury + year, month, date));

  // checks if it is the same date as the input date
  if (
    parseInt(
      validDate
        .getFullYear()
        .toString()
        .slice(2, 4),
      10
    ) === year &&
    validDate.getMonth() === month &&
    validDate.getDate() === date
  ) {
    return { valid: true, date: validDate };
  }

  // if it is not any of those the date input is incorrect
  return { valid: false, reason: "date is incorrect" };
}
