import React from "react";
import {
  Button,
  Segment,
  Header,
  Modal,
  Message,
  Dimmer,
  Loader
} from "semantic-ui-react";
import PropTypes from "prop-types";

export class TechnicalAgentDeleteView extends React.Component {
  constructor({
    federationId,
    title = "",
    customerId,
    userId,
    firstname,
    lastname,
    handleDelete
  }) {
    super();

    this.state = {
      federationId,
      title,
      customerId,
      userId,
      firstname,
      lastname,
      handleDelete
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.firstname !== prevState.firstname ||
      prevProps.lastname !== prevState.lastname
    ) {
      this.setState({
        firstname: this.props.firstname,
        lastname: this.props.lastname
      });
    }
  }

  UNSAFE_componentWillReceiveProps({ technicalAgentDeleteResp }) {
    const { modalOpen } = this.state;
    if (modalOpen && technicalAgentDeleteResp) {
      if (technicalAgentDeleteResp !== this.state.technicalAgentDeleteResp) {
        this.loadTechnicalAgentDeleteResp({ technicalAgentDeleteResp });
      }
    }
  }

  loadTechnicalAgentDeleteResp({ technicalAgentDeleteResp }) {
    const resp = technicalAgentDeleteResp[this.state.userId];
    if (resp && resp.deleted) {
      this.setState({ loading: false });
      this.handleClose();
      this.props.reloadData();
    } else if (resp && !resp.deleted) {
      this.setState({
        technicalAgentDeleteSuccess: undefined,
        technicalAgentDeleteError: resp.error,
        loading: false
      });
    }
  }

  handleChange = (e, { name, value }) =>
    this.setState({
      [name]: value
    });

  handleSubmit = () => {
    const { federationId, customerId, userId } = this.state;
    if (customerId && userId) {
      this.setState({ loading: true });
      this.props.deleteTechnicalAgentRequest({
        federationId,
        customerId: customerId,
        userId: userId
      });
    }
  };
  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  render() {
    const {
      title,
      deleteText = "Delete",
      cancelText = "Cancel",
      firstname,
      lastname,
      deleteError = undefined,
      loading
    } = this.state;

    return (
      <Modal
        trigger={
          <Button color="red" onClick={this.handleOpen}>
            {title}
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description>
            <Header as="h1">{title}</Header>
            {deleteError && <Message error content={`Error: ${deleteError}`} />}
            <Message>
              Do you really want to delete {firstname} {lastname}?
            </Message>

            <Segment>
              {loading && (
                <Dimmer active inverted>
                  <Loader active inline />
                </Dimmer>
              )}
              <Button type="submit" color="red" onClick={this.handleSubmit}>
                {deleteText}
              </Button>
              <Button secondary type="cancel" onClick={this.handleClose}>
                {cancelText}
              </Button>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

TechnicalAgentDeleteView.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  form: PropTypes.object,
  deleteTechnicalAgentRequest: PropTypes.func.isRequired
};

export default TechnicalAgentDeleteView;
