import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Segment,
  Header,
  Modal,
  Message,
  Icon,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { ErrorMessage } from "../../../common/error";

export class ResellerUserDeleteView extends React.Component {
  constructor({
    userId,
    prefixCode,
    name,
    deleteSuccess,
    deleteError,
    resellerUserDeleteResp,
    federationId
  }) {
    super();

    this.state = {
      userId,
      prefixCode,
      name,
      deleteSuccess,
      deleteError,
      resellerUserDeleteResp,
      federationId
    };

    this.handleChange = this.handleChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps({ resellerUserDeleteResp }) {
    const resp = resellerUserDeleteResp[this.state.userId];
    if (resp) {
      if (resp.deleted) {
        this.setState({ loading: false });
        this.props.handleDelete(this.state.userId);
        this.handleClose();
      } else {
        this.setState({ deleteError: resp.error, loading: false });
      }
    }
  }

  handleChange = (e, { name, value }) =>
    this.setState({
      [name]: value
    });

  handleSubmit = () => {
    const { userId } = this.state;
    const { prefixCode } = this.state;
    if (userId) {
      this.setState({ loading: true });
      const { federationId } = this.state;
      this.props.deleteResellerUserRequest({
        federationId,
        userId,
        prefixCode
      });
    }
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    const { userId, name, deleteError, loading } = this.state;

    return (
      <Modal
        trigger={<Icon size="large" name="trash" onClick={this.handleOpen} />}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description style={{ maxWidth: "100%" }}>
            <Header as="h1">Delete reseller user - ({userId})</Header>
            {deleteError && <ErrorMessage error {...deleteError} />}
            <Message>
              Do you really want do delete <strong>{name}</strong>?
            </Message>

            <Segment>
              {loading && (
                <Dimmer active inverted>
                  <Loader active inline />
                </Dimmer>
              )}
              <Button type="submit" color="red" onClick={this.handleSubmit}>
                Delete
              </Button>
              <Button type="cancel" secondary onClick={this.handleClose}>
                Cancel
              </Button>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

ResellerUserDeleteView.propTypes = {
  deleteText: PropTypes.string,
  userId: PropTypes.string.isRequired
};

export default ResellerUserDeleteView;
