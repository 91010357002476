import React from "react";
import {
  Button,
  Form,
  Segment,
  SegmentGroup,
  Header,
  Message,
  Divider,
  Dimmer,
  Loader
} from "semantic-ui-react";
// import { member } from "../mock/memberData";
import { JsonView } from "../../../helpers/json/JsonView";
import PropTypes from "prop-types";
import { ErrorMessage } from "../../../common/error";
import {
  authorize,
  isUserAuthorized
} from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";
import { validatePnr } from "../../../helpers/pnrvalidator";
import { DetermineRealmTrial } from "../../../utils/common/DetermineRealmTrial";

export class TechnicalContactFormView extends React.Component {
  constructor({
    updateSuccess,
    showGeneralInfoForm = true,
    showTechnicalContactForm = false,
    form = null,
    customerId
  }) {
    super();

    this.state = {
      updateSuccess,
      showGeneralInfoForm,
      showTechnicalContactForm,
      form,
      dirtyForm: false,
      customerId
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  componentDidMount() {
    const { userSession } = this.props;
    if (userSession.realm && !this.state.resellersFetched) {
      const federationId = userSession.realm;

      this.setState({
        ssnRequired: DetermineRealmTrial(federationId),
        ssnValid: validatePnr(this.props.form.ssn).valid,
        isReadOnly: !isUserAuthorized(
          authMapping.UPDATE_MEMBER,
          this.props.userSession.roles
        )
      });
    }
  }

  UNSAFE_componentWillReceiveProps({
    form,
    updateSuccess,
    error,
    updateError
  }) {
    if (form && this.state.dirtyForm === false) {
      this.setState({ form });
    }

    if (updateSuccess) {
      this.setState({
        updateSuccess,
        updateError: undefined,
        validationError: undefined,
        dirtyForm: false,
        loading: false
      });
    }
    if (error) {
      this.setState({ error, updateError: undefined, loading: false });
    }
    if (updateError) {
      this.setState({ updateError, updateSuccess: undefined, loading: false });
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    let validSSN = {}; // result from pnr validate
    let validSSNInput = true;

    if (name === "ssn" && value !== undefined) {
      validSSN = validatePnr(value, {
        strict: true,
        forgiving: true
      });

      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && value.length < 12) {
        validSSNInput = false;
      }
    } else if (this.state.form.ssn) {
      validSSN = validatePnr(this.state.form.ssn, {
        strict: true,
        forgiving: true
      });
      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && this.state.form.ssn.length < 12) {
        validSSNInput = false;
      }
    }
    this.setState({
      dirtyForm: true,
      status: "edit",
      ssnValid: validSSNInput,
      validationError: undefined,
      updateSuccess: undefined,
      form: {
        ...this.state.form,
        ...{ [name]: value }
      }
    });
  }

  handleSave() {
    this.setState({ loading: true });
    this.props.handleSubmit(this.state.form);
  }

  render() {
    const {
      isReadOnly,
      updateSuccess,
      updateError,
      dirtyForm,
      ssnRequired,
      loading
    } = this.state;

    let showButton = false;
    if (ssnRequired && this.state.form.ssn !== undefined) {
      showButton = dirtyForm && this.state.ssnValid;
    } else if (
      this.state.form.ssn !== undefined &&
      this.state.ssnValid &&
      dirtyForm
    ) {
      showButton = dirtyForm && this.state.ssnValid;
    } else if (
      dirtyForm &&
      (this.state.form.ssn === undefined || this.state.form.ssn === "")
    ) {
      showButton = true;
    }

    const roles = this.props.userSession.roles;

    return (
      <React.Fragment>
        {this.state.form && (
          <React.Fragment>
            {false && <JsonView title="STATE" json={this.state} />}
            {false && <JsonView title="PROPS" json={this.props} />}
            <Header as="h2">Technical contact</Header>

            <SegmentGroup disabled={true}>
              <Segment>
                <Form
                  onSubmit={this.handleSave}
                  success={updateSuccess !== undefined}
                  error={updateError !== undefined}
                >
                  {updateSuccess && (
                    <Message
                      success
                      header="Technical contact form is updated"
                    />
                  )}
                  {updateError && (
                    <ErrorMessage
                      title="Error! fail to update technical contact"
                      {...updateError}
                    />
                  )}

                  <Form.Field>
                    <Form.Input
                      name="firstName"
                      fluid={"true"}
                      label="First name"
                      placeholder="First name"
                      onChange={this.handleChange}
                      value={this.state.form.firstName}
                      required
                      control="input"
                      max="36"
                      readOnly={isReadOnly}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      name="lastName"
                      fluid={"true"}
                      label="Last name"
                      placeholder="Last name"
                      onChange={this.handleChange}
                      value={this.state.form.lastName}
                      required
                      control="input"
                      max="36"
                      readOnly={isReadOnly}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      name="email"
                      fluid={"true"}
                      label="E-Mail"
                      icon="mail"
                      placeholder="E-Mail"
                      onChange={this.handleChange}
                      value={this.state.form.email}
                      required
                      control="input"
                      type="email"
                      maxLength="70"
                      readOnly={isReadOnly}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      name="phone"
                      fluid={"true"}
                      label="Phone"
                      placeholder="Phone"
                      icon="phone"
                      onChange={this.handleChange}
                      value={this.state.form.phone}
                      required
                      control="input"
                      max="36"
                      readOnly={isReadOnly}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      fluid
                      required={this.state.ssnRequired}
                      name="ssn"
                      label="Personal Identity Number"
                      placeholder="YYYYMMDDNNNN"
                      onChange={this.handleChange}
                      value={this.state.form.ssn}
                      minLength="12"
                      maxLength="12"
                      readOnly={isReadOnly}
                    />
                  </Form.Field>

                  {authorize(
                    authMapping.UPDATE_MEMBER,
                    roles,
                    <Segment>
                      {loading && (
                        <Dimmer active inverted>
                          <Loader active inline />
                        </Dimmer>
                      )}
                      <Button primary disabled={!showButton}>
                        Update
                      </Button>
                    </Segment>
                  )}
                </Form>
              </Segment>
            </SegmentGroup>
            <Divider hidden />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

TechnicalContactFormView.propTypes = {
  form: PropTypes.object
};

export default TechnicalContactFormView;
