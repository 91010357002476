import React from "react";
import PropTypes from "prop-types";
import {
  Header,
  Table,
  Container,
  Form,
  Input,
  Icon,
  Pagination,
  Message
} from "semantic-ui-react";
import { JsonView } from "../../../helpers/json/JsonView";
import { ErrorMessage } from "../../../common/error";

/**
 * This function take a slice(page id) of the search result, it's used by local paging  navigation.
 * @param {*} arr
 * @param {*} index
 * @param {*} page
 */
const slice = (arr, index, page) => {
  return arr.slice(index * page, index * page + page);
};
/**
 * General sortBy function this could be moved in to a common package.
 * @param {*} key
 * @param {*} reverse
 */
const sortBy = (key, reverse) => {
  return (a, b) => {
    let valueA;
    let valueB;
    if (key === headerFTLS[1].name) {
      valueA = a[key].toLowerCase();
      valueB = b[key].toLowerCase();
    } else if (key === headerSAML[1].name) {
      valueA = a[key].toLowerCase();
      valueB = b[key].toLowerCase();
    } else {
      valueA = a[key];
      valueB = b[key];
    }

    if (valueA < valueB) {
      return reverse ? 1 : -1;
    }
    if (valueA > valueB) {
      return reverse ? -1 : 1;
    }

    if (key !== headerFTLS[1].name) {
      valueA = a[headerFTLS[1].name].toLowerCase();
      valueB = b[headerFTLS[1].name].toLowerCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    } else if (key !== headerSAML[1].name) {
      valueA = a[headerSAML[1].name].toLowerCase();
      valueB = b[headerSAML[1].name].toLowerCase();
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    }

    return 0;
  };
};

const headerSAML = [
  {
    id: 1,
    name: "customerId",
    displayName: "Member ID",
    textAlign: "left",
    initDecs: false
  },
  {
    id: 2,
    name: "name",
    displayName: "Member name",
    textAlign: "left",
    initDecs: true
  },
  {
    id: 3,
    name: "active",
    displayName: "Active",
    textAlign: "center",
    initDecs: true
  },
  {
    id: 4,
    name: "count",
    displayName: "Metadata",
    textAlign: "center",
    initDecs: true
  },
  {
    id: 5,
    name: "hasAo",
    displayName: "Idp Allow",
    textAlign: "center",
    initDecs: true
  },
  {
    id: 6,
    name: "hasSp",
    displayName: "Sp Allow",
    textAlign: "center",
    initDecs: true
  }
];

const headerFTLS = [
  {
    id: 1,
    name: "customerId",
    displayName: "Member ID",
    textAlign: "left",
    initDecs: false
  },
  {
    id: 2,
    name: "name",
    displayName: "Member name",
    textAlign: "left",
    initDecs: true
  },
  {
    id: 3,
    name: "active",
    displayName: "Active",
    textAlign: "center",
    initDecs: true
  },
  {
    id: 4,
    name: "count",
    displayName: "Metadata",
    textAlign: "center",
    initDecs: true
  },
  {
    id: 5,
    name: "hasAo",
    displayName: "Client Allow",
    textAlign: "center",
    initDecs: true
  },
  {
    id: 6,
    name: "hasSp",
    displayName: "Server Allow",
    textAlign: "center",
    initDecs: true
  }
];

export const MembersTableView = ({
  title,
  data,
  handleSearch,
  handleSort,
  handlePaginationChange,
  activeSortColumn,
  activePage = 1,
  sortState,
  page = 5,
  loading,
  federationId,
  loadingMembers,
  federationType
}) => {
  let sortedData = [];
  let total = 0;

  if (data && data.success) {
    const { success } = { ...data };
    total = success.length;

    sortedData = slice(
      success.sort(sortBy(activeSortColumn, sortState[activeSortColumn])),
      activePage - 1,
      page
    );
  }

  return (
    <Container fluid>
      <Header as="h1">{title}</Header>
      <Form>
        <Form.Group widths="2">
          <Form.Field>
            <Input
              name="query"
              autoFocus={true}
              placeholder="Search..."
              onChange={handleSearch}
              size="tiny"
              icon="search"
              loading={loadingMembers}
            />
          </Form.Field>
        </Form.Group>
      </Form>
      {total > 0 && (
        <Table celled striped selectable sortable={true}>
          <Table.Header>
            {federationType && federationType === "SAML" && (
              <Table.Row>
                {headerSAML.map(
                  ({
                    id,
                    name,
                    displayName,
                    textAlign = "center",
                    padding = {},
                    initDecs = false
                  }) => (
                    <Table.HeaderCell
                      key={id}
                      textAlign={textAlign}
                      style={{ padding }}
                      onClick={() => handleSort({ name, initDecs })}
                    >
                      {displayName}
                    </Table.HeaderCell>
                  )
                )}
              </Table.Row>
            )}
            {federationType && federationType === "FedTLS" && (
              <Table.Row>
                {headerFTLS.map(
                  ({
                    id,
                    name,
                    displayName,
                    textAlign = "center",
                    padding = {},
                    initDecs = false
                  }) => (
                    <Table.HeaderCell
                      key={id}
                      textAlign={textAlign}
                      style={{ padding }}
                      onClick={() => handleSort({ name, initDecs })}
                    >
                      {displayName}
                    </Table.HeaderCell>
                  )
                )}
              </Table.Row>
            )}
          </Table.Header>

          <Table.Body sortable={"true"}>
            {sortedData &&
              sortedData.map(
                ({ customerId, name, count, hasAo, hasSp, active }, index) => (
                  <Table.Row
                    key={index}
                    onClick={() =>
                      (window.location = `/#/federation/${federationId}/members/show/${customerId}/`)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <Table.Cell
                      textAlign="left"
                      style={{ paddingRight: "3em", width: "160px" }}
                      sorted="ascending"
                    >
                      {customerId}
                    </Table.Cell>
                    <Table.Cell textAlign="left">{name}</Table.Cell>
                    <Table.Cell textAlign="center" style={{ width: "160px" }}>
                      {active === true && (
                        <Icon color="green" name="checkmark" size="large" />
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center" style={{ width: "60px" }}>
                      {count}
                    </Table.Cell>
                    <Table.Cell textAlign="center" style={{ width: "160px" }}>
                      {hasAo === true && (
                        <Icon color="green" name="checkmark" size="large" />
                      )}
                    </Table.Cell>
                    <Table.Cell textAlign="center" style={{ width: "160px" }}>
                      {hasSp === true && (
                        <Icon color="green" name="checkmark" size="large" />
                      )}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="6">
                {total > 1 && (
                  <Pagination
                    floated="right"
                    activePage={activePage}
                    pointing
                    secondary
                    totalPages={total > 0 ? Math.ceil(total / page) : 0}
                    onPageChange={handlePaginationChange}
                  />
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      )}
      {data && data.success && total === 0 && (
        <Message warning>No members found! {}</Message>
      )}

      {data && data.error && <ErrorMessage title="Error" {...data.error} />}
      {false && <JsonView title="Component state:" json={this.state} />}
      {false && <JsonView title="Search response" json={sortedData} />}
    </Container>
  );
};
MembersTableView.propTypes = {
  title: PropTypes.string,
  activePage: PropTypes.number,
  activeSortColumn: PropTypes.string,
  page: PropTypes.number,
  data: PropTypes.object,
  sortState: PropTypes.object,
  handleSearch: PropTypes.func.isRequired,
  handleSort: PropTypes.func,
  handlePaginationChange: PropTypes.func,
  federationType: PropTypes.string
};
export default MembersTableView;
