export const federationInfoRespState = props => {
  const { federationInfoResp } = props;
  if (federationInfoResp) {
    switch (federationInfoResp.type) {
      case "FEDERATION_INFO_SUCCESS":
        return { federation: federationInfoResp.data };
      case "FEDERATION_INFO_FAILURE":
        return { error: true };
      default:
        console.log("FEDERATION_INFO NOT READY");
    }
  }
};
