import { fetchWrapped, GET, POST, PUT, DELETE } from "../api";

const API_ENDPOINT = "/fedreg/apis/organization/api/federation";
// const API_ENDPOINT = "/fedreg/apis/organization/api/member";
const TECH_AGENT = "techagent";

export const fetchMemberSearchApi = ({
  federationId,
  query,
  filterBy = "name"
}) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/search?search=${escape(
      encodeURIComponent(query)
    )}&name=${filterBy}`,
    {
      method: "GET"
    }
  );

export const createMemberApi = ({ federationId, data }) => {
  return fetchWrapped(`${API_ENDPOINT}/${federationId}/member`, {
    method: "POST",
    body: JSON.stringify({
      ...data
    })
  });
};

export const deleteMemberApi = ({ federationId, customerId }) =>
  fetchWrapped(`${API_ENDPOINT}/${federationId}/member/${customerId}`, {
    method: DELETE
  });

export const fetchMemberApi = ({ federationId, customerId }) =>
  fetchWrapped(`${API_ENDPOINT}/${federationId}/member/${customerId}`, {
    method: GET
  });

export const validateCustomerId = ({ federationId, customerId }) =>
  fetchWrapped(`${API_ENDPOINT}/${federationId}/member/${customerId}/valid`, {
    method: GET
  });

export const updateMemberApi = ({ federationId, customerId, data }) =>
  fetchWrapped(`${API_ENDPOINT}/${federationId}/member/${customerId}`, {
    method: PUT,
    body: JSON.stringify(data)
  });

export const activateMemberApi = ({ federationId, customerId, active }) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/active?active=${active}`,
    {
      method: PUT
    }
  );

export const updateTechnicalContactApi = ({
  federationId,
  customerId,
  userId,
  data
}) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/techcontact/${userId}`,
    {
      method: PUT,
      body: JSON.stringify(data)
    }
  );

export const fetchTechnicalAgentsApi = ({ federationId, customerId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/${TECH_AGENT}`,
    {
      method: GET
    }
  );

export const createTechnicalAgentApi = ({ federationId, customerId, data }) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/${TECH_AGENT}`,
    {
      method: POST,
      body: JSON.stringify(data)
    }
  );

export const updateTechnicalAgentApi = ({
  federationId,
  customerId,
  userId,
  data
}) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/${TECH_AGENT}/${userId}`,
    {
      method: PUT,
      body: JSON.stringify(data)
    }
  );

export const fetchTechnicalAgentApi = ({ federationId, customerId, userId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/${TECH_AGENT}/${userId}`,
    {
      method: GET
    }
  );

export const deleteTechnicalAgentApi = ({ federationId, customerId, userId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/${federationId}/member/${customerId}/${TECH_AGENT}/${userId}`,
    {
      method: DELETE
    }
  );
