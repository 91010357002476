import * as authMapping from "./authMapping";

const authorize = (action, roles, component) => {
  if (isUserAuthorized(action, roles)) {
    return component;
  } else {
    return;
  }
};

const isUserAuthorized = (action, roles) => {
  const permittedRoles = authMapping[action];
  let userAuthorized = false;
  if (roles) {
    roles.forEach(role => {
      if (permittedRoles.indexOf(role) >= 0) {
        userAuthorized = true;
      }
    });
  }
  return userAuthorized;
};

export { authorize, isUserAuthorized };
