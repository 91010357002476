import { AUTH_SUCCESS, AUTH_FAILURE } from "./actionTyps";

export const userSession = (state = {}, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return { ...state, ...{ authenticated: true, ...action } };
    case AUTH_FAILURE:
      return { ...state, ...{ authenticated: false } };
    default:
      return state;
  }
};
