import React from "react";
import PropTypes from "prop-types";
import { Message, MessageContent } from "semantic-ui-react";

// TODO make mail configuration per federation
const MailLink = () => (
  <div className="ui label">
    <i className="mail icon" />
    <a className="detail" href="mailto:federationer@internetstiftelsen.se">
      federationer@internetstiftelsen.se
    </a>
  </div>
);

const messageText = {
  SAML:
    "Please check that the file is valid XML and in accordance with SAML 2.0 Metadata standards." +
    " If you are having further problems with uploading the metadata, please contact support at ",
  FedTLS:
    "Please check that the file is valid FedTLS JSon and in accordance with FedTLS Metadata standards." +
    " If you are having further problems with uploading the metadata, please contact support at "
};

const commonCausesText = {
  SAML: [
    "The file contains metadata for several entities.",
    "XML namespaces are incorrect.",
    "The metadata root element is anything other than EntityDescriptor."
  ],
  FedTLS: ["JSon file is not valid.", "The entity_id  owns by other member"]
};

export const WarningMessageParse = ({
  title = "Something went wrong.",
  statusCode,
  statusMessage,
  message,
  type
}) => (
  <Message warning>
    <Message.Header>{title}</Message.Header>
    <MessageContent style={{ fontSize: 12 }} content={messageText[type]} />
    <MailLink />
    <Message.List style={{ fontSize: 12 }}>
      Common causes:
      {commonCausesText[type].map((text, i) => (
        <Message.Item key={i}>{text}</Message.Item>
      ))}
      {message && <React.Fragment>Detailed info: </React.Fragment>}
      {message && <Message.Item>{message}</Message.Item>}
    </Message.List>
  </Message>
);

WarningMessageParse.propTypes = {
  title: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  statusMessage: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(["SAML", "FedTLS"])
};

export default WarningMessageParse;
