import React from "react";
import PropTypes from "prop-types";

import {
  Form,
  Segment,
  Button,
  Header,
  Message,
  Dimmer,
  Loader
} from "semantic-ui-react";

import { JsonView } from "../../../helpers/json/JsonView";
import { ErrorMessage } from "../../../common/error";
import {
  authorize,
  isUserAuthorized
} from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";

export class ResellerFormView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      title: props.title,
      submitText: props.submitText,
      reseller: props.reseller,
      handleSubmit: props.handleSubmit,
      saveSuccess: props.saveSuccess,
      saveError: props.saveError,
      dirtyForm: false,
      isReadOnly: !isUserAuthorized(
        authMapping.UPDATE_RESELLER,
        props.userSession.roles
      )
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { saveSuccess, saveError } = nextProps;
    if (saveSuccess) {
      this.setState({
        reseller: saveSuccess,
        saveSuccess,
        dirtyForm: false,
        loading: false
      });
    }
    if (saveError) {
      this.setState({ saveError, loading: false });
    }
  }

  handleInputChange = event => {
    const target = event.target;
    this.setState({
      reseller: {
        ...this.state.reseller,
        [target.name]: target.value
      },
      saveSuccess: undefined,
      saveError: undefined,
      dirtyForm: true
    });
  };

  handleSave = () => {
    this.setState({ loading: true });
    this.props.handleSubmit(this.state.reseller);
  };

  render() {
    const {
      title,
      submitText,
      reseller,
      saveSuccess,
      saveError,
      dirtyForm,
      loading,
      isReadOnly
    } = this.state;

    const roles = this.props.userSession.roles;

    return (
      <React.Fragment>
        {false && <JsonView title="Component state:" json={this.state} />}
        <Header as="h2">{title}</Header>
        {saveError && (
          <ErrorMessage title="Failed to save reseller" {...saveError} />
        )}
        {saveSuccess && <Message success header="Reseller has been updated" />}
        {reseller && (
          <Segment>
            <Form onSubmit={this.handleSave}>
              <Form.Field>
                <Form.Input
                  name="name"
                  label="Name"
                  placeholder="Name"
                  onChange={this.handleInputChange}
                  value={reseller && reseller.name}
                  required
                  readOnly={isReadOnly}
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  name="prefixCode"
                  label="Prefix"
                  placeholder="Prefix"
                  onChange={this.handleInputChange}
                  value={reseller.prefixCode}
                  required
                  readOnly={isReadOnly}
                />
              </Form.Field>
              {authorize(
                authMapping.DELETE_RESELLER,
                roles,
                <Segment>
                  {loading && (
                    <Dimmer active inverted>
                      <Loader active inline />
                    </Dimmer>
                  )}
                  <Button type="submit" primary disabled={!dirtyForm}>
                    {submitText}
                  </Button>
                </Segment>
              )}
            </Form>
          </Segment>
        )}
      </React.Fragment>
    );
  }
}

ResellerFormView.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  reseller: PropTypes.object
};

export default ResellerFormView;
