import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actionCreators from "../../store/ducks/index";
import { PageResellers } from "../../pages/federation/resellers";

function mapStateToProps(state) {
  return {
    userSession: state.userSession,
    resellersFetchResp: state.resellersFetchResp,
    resellerFetchResp: state.resellerFetchResp,
    resellerCreateResp: state.resellerCreateResp,
    resellerUpdateResp: state.resellerUpdateResp,
    resellerDeleteResp: state.resellerDeleteResp,
    resellerUsersFetchResp: state.resellerUsersFetchResp,
    resellerUserCreateResp: state.resellerUserCreateResp,
    resellerUserDeleteResp: state.resellerUserDeleteResp,
    resellerUserUpdateResp: state.resellerUserUpdateResp,
    dynamicConfig: state.dynamicConfig,
    federationInfoResp: state.federationInfoResp,
    shoutoutResp: state.shoutoutResp
  };
}

export function mapDispatchToProps(dispatch) {
  return bindActionCreators(actionCreators, dispatch);
}

export const PageResellersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageResellers);

export default PageResellersContainer;
