import {
  LOAD_MEMBER_METADATA_LIST_SUCCESS,
  LOAD_MEMBER_METADATA_LIST_FAILURE,
  LOAD_FEDERATION_METADATA_LIST_SUCCESS,
  LOAD_FEDERATION_METADATA_LIST_FAILURE,
  LOAD_METADATA_VALIDATE_SUCCESS,
  LOAD_METADATA_VALIDATE_FAILURE,
  UPLOAD_METADATA_SUCCESS,
  UPLOAD_METADATA_FAILURE,
  LOAD_METADATA_SUCCESS,
  LOAD_METADATA_FAILURE,
  UPDATE_METADATA_SUCCESS,
  UPDATE_METADATA_FAILURE,
  DELETE_METADATA_SUCCESS,
  DELETE_METADATA_FAILURE,
  APPROVE_METADATA_SUCCESS,
  APPROVE_METADATA_FAILURE,
  LOAD_FEDERATION_MD_COUNT_SUCCESS,
  LOAD_FEDERATION_MD_COUNT_FAILURE
} from "./actionTypes";

import {
  loadSuccessReducer,
  loadFailureReducer,
  loadSuccessReducerById,
  loadFailureReducerById,
  updateSuccessReducerById,
  updateFailureReducerById,
  deleteFailureReducerById,
  deleteSuccessReducerById
  //   createFailureReducer,
  //   createSuccessReducer,
  //   createFailureReducerById,
  //   createSuccessReducerById
} from "../common/reducer";

export const memberMetadataListFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_MEMBER_METADATA_LIST_SUCCESS:
      return loadSuccessReducer(state, action);
    case LOAD_MEMBER_METADATA_LIST_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const federationMetadataListFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_FEDERATION_METADATA_LIST_SUCCESS:
      return loadSuccessReducer(state, action);
    case LOAD_FEDERATION_METADATA_LIST_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const federationMetadataValidateFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_METADATA_VALIDATE_SUCCESS:
      return loadSuccessReducer(state, action);
    case LOAD_METADATA_VALIDATE_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const federationMemberMDCountResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_FEDERATION_MD_COUNT_SUCCESS:
      return loadSuccessReducer(state, action);
    case LOAD_FEDERATION_MD_COUNT_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const metadataUploadResp = (state = {}, action) => {
  switch (action.type) {
    case UPLOAD_METADATA_SUCCESS:
      return updateSuccessReducerById(state, action.customerId, action);
    case UPLOAD_METADATA_FAILURE:
      return updateFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const metadataFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_METADATA_SUCCESS:
      return loadSuccessReducerById(state, action.entityId, action);
    case LOAD_METADATA_FAILURE:
      return loadFailureReducerById(state, action.entityId, action);
    default:
      return state;
  }
};

export const metadataUpdateResp = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_METADATA_SUCCESS:
      return updateSuccessReducerById(state, action.entityId, action);
    case UPDATE_METADATA_FAILURE:
      return updateFailureReducerById(state, action.entityId, action);
    default:
      return state;
  }
};

export const metadataDeleteResp = (state = {}, action) => {
  switch (action.type) {
    case DELETE_METADATA_SUCCESS:
      return deleteSuccessReducerById(state, action.entityId, action);
    case DELETE_METADATA_FAILURE:
      return deleteFailureReducerById(state, action.entityId, action);
    default:
      return state;
  }
};

export const metadataApproveResp = (state = {}, action) => {
  switch (action.type) {
    case APPROVE_METADATA_SUCCESS:
      return updateSuccessReducerById(state, action.entityId, action);
    case APPROVE_METADATA_FAILURE:
      return updateFailureReducerById(state, action.entityId, action);
    default:
      return state;
  }
};
