import React from "react";
import PropTypes from "prop-types";

import {
  Button,
  Segment,
  Header,
  Modal,
  Message,
  Icon,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { ErrorMessage } from "../../../common/error";

export class ResellerDeleteView extends React.Component {
  constructor({
    prefixCode,
    name,
    deleteSuccess,
    deleteError,
    resellerDeleteResp,
    noOfMembers,
    federationId
  }) {
    super();

    this.state = {
      prefixCode,
      name,
      deleteSuccess,
      deleteError,
      resellerDeleteResp,
      noOfMembers,
      federationId
    };
  }

  UNSAFE_componentWillReceiveProps({ resellerDeleteResp }) {
    if (resellerDeleteResp) {
      const resp = resellerDeleteResp[this.state.prefixCode];
      if (resp) {
        this.setState({ loading: false });
        if (resp.deleted) {
          this.props.reload();
          this.handleClose();
        } else {
          this.setState({ deleteError: resp.error });
        }
      }
    }
  }

  handleSubmit = () => {
    const { prefixCode } = this.state;
    if (prefixCode) {
      this.setState({ loading: true });
      const { federationId } = this.state;
      this.props.deleteResellerRequest({ federationId, prefixCode });
    }
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render() {
    const { name, deleteError, prefixCode, noOfMembers, loading } = this.state;

    return (
      <Modal
        trigger={<Icon size="large" name="trash" onClick={this.handleOpen} />}
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description style={{ maxWidth: "100%" }}>
            <Header as="h1">Delete reseller - ({prefixCode})</Header>
            {deleteError && <ErrorMessage error {...deleteError} />}
            <Message>
              Are you sure you want to delete <strong>{name}</strong>?<br />
              {noOfMembers > 1 && (
                <div>
                  <strong>{noOfMembers}</strong> members will be deleted
                </div>
              )}
              {noOfMembers === 1 && (
                <div>
                  <strong>{noOfMembers}</strong> member will be deleted
                </div>
              )}
            </Message>

            <Segment>
              {loading && (
                <Dimmer active inverted>
                  <Loader active inline />
                </Dimmer>
              )}
              <Button type="submit" color="red" onClick={this.handleSubmit}>
                Delete
              </Button>
              <Button type="cancel" secondary onClick={this.handleClose}>
                Cancel
              </Button>
            </Segment>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

ResellerDeleteView.propTypes = {
  deleteText: PropTypes.string,
  prefixCode: PropTypes.string.isRequired
};

export default ResellerDeleteView;
