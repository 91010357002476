export const metadataEx = `<?xml version='1.0' encoding='UTF-8'?>
<md:EntityDescriptor xmlns:md="urn:oasis:names:tc:SAML:2.0:metadata" xmlns:ds="http://www.w3.org/2000/09/xmldsig#" xmlns:mdattr="urn:oasis:names:tc:SAML:metadata:attribute" xmlns:mdrpi="urn:oasis:names:tc:SAML:metadata:rpi" xmlns:mdui="urn:oasis:names:tc:SAML:metadata:ui" xmlns:pyff="http://pyff.io/NS" xmlns:saml="urn:oasis:names:tc:SAML:2.0:assertion" xmlns:saml2="urn:oasis:names:tc:SAML:2.0:assertion" xmlns:shibmd="urn:mace:shibboleth:metadata:1.0" xmlns:xrd="http://docs.oasis-open.org/ns/xri/xrd-1.0" xmlns:xs="http://www.w3.org/2001/XMLSchema" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns="urn:oasis:names:tc:SAML:2.0:metadata" entityID="https://www.bestallningsportalen.sll.se/ULFH/AuthServices" ID="_20180814T092957Z" validUntil="2018-08-16T09:29:57Z" cacheDuration="PT60M">
  <ds:Signature><ds:SignedInfo><ds:CanonicalizationMethod Algorithm="http://www.w3.org/TR/2001/REC-xml-c14n-20010315"/><ds:SignatureMethod Algorithm="http://www.w3.org/2001/04/xmldsig-more#rsa-sha256"/><ds:Reference URI="#_20180814T092957Z"><ds:Transforms><ds:Transform Algorithm="http://www.w3.org/2000/09/xmldsig#enveloped-signature"/><ds:Transform Algorithm="http://www.w3.org/2001/10/xml-exc-c14n#WithComments"/></ds:Transforms><ds:DigestMethod Algorithm="http://www.w3.org/2001/04/xmlenc#sha256"/><ds:DigestValue>WFEJfTXM3GdRILjGt5lMhaQRbobNX4AtxxJNsh0+F7E=</ds:DigestValue></ds:Reference></ds:SignedInfo><ds:SignatureValue>i+1HIEC0zOofjDNI6zlUGrCHnVucAbOFslYv67tlphsyZzHxBovKzb1LHdFoStdoGe3E4Bg67UPMbFRUWuZKL74qugjsOLp9Eemf/O5ndUDA4srU6HTEGwV4OTOTLta8JWNA2g8FCO0K6fY+m42UxNuWmWWsuR4tttgO2z1rn5Rys/ODwSANBKQK8A333WDLyEneKQA+Se0ivGEDWu12Pr1S7SvYtlUfoIKSE2tBAcksoMU+wvrS5CqyklHi3MeleRlEMrmfx+AnPamEwn6ZLSEnS54v9JAqjBQpSEgA9soF2LLAcFhELYTEkAAaBpeJ2+5xNQjDCdP3/RLQO6h6BA==</ds:SignatureValue><ds:KeyInfo><ds:X509Data><ds:X509Certificate>MIIDEzCCAfugAwIBAgIJAJSGn2oQmH0bMA0GCSqGSIb3DQEBBQUAMCAxHjAcBgNV
BAMMFU1ldGFkYXRhIFNpZ25lciBvbWJ1ZDAeFw0xODAyMTYxMTEwMjJaFw0xODAz
MTgxMTEwMjJaMCAxHjAcBgNVBAMMFU1ldGFkYXRhIFNpZ25lciBvbWJ1ZDCCASIw
DQYJKoZIhvcNAQEBBQADggEPADCCAQoCggEBAL5s5sCTPR5jKK4XVmvnHtJBoKB4
Nk1IrlAdSOKrGiaIuZ+1XRQ1D1PXIFGd44KB5TW5M7wl/n5TRzeBolL6UkfBKZXl
GhbY+pk1I2DBjPPD0tj5QlKiI35fE+2NiYhHoacaFdSu56d+01DKiLx9gckAZsOG
t/UzRNf0SBcOmQCkece7n4v0g3J4TO5pG8yfx0THcqUNfpyJiJEs+e4yfPh4H3V4
nlQodPxA1OwzO6nHTWIzy18HZTsudk9uECUu3k8DXHn/SCifZxx9RjhuBEVwmooz
l7/Imrsn6q595XN6SAHkID9wmTml18y/HLk9ijhUHlcWDyE7iAmsJawt4xUCAwEA
AaNQME4wHQYDVR0OBBYEFDjj0KPFu1vdorOBQLQ1e4f2wr6+MB8GA1UdIwQYMBaA
FDjj0KPFu1vdorOBQLQ1e4f2wr6+MAwGA1UdEwQFMAMBAf8wDQYJKoZIhvcNAQEF
BQADggEBAA8ABjcB/0RNrLXb4e6wNrtulnM6l979PTvgcBJnbj8UJPGqklCarIQX
6fkYOaIpbJ58UcwJoTixsd2QA8cLMQlrOaPLrKMptSNHlLNHR09EY7Z+dZn0/Y9m
zeCgTvqAM6mnaK90Y7HVfGzRIuY30kHL2plSmMdNZ3y2YAb1ZNAquPKF1upvx9m6
O0nvBEWBblr9lml2fgTlZtXum8zXRe13FHkShc7nxlhlsnsLVbpBoDIouYUSiQsA
5bsZf1vMxvtmqKUNqNasIHBde1BqBpuqeqItQU3ROifkZqigAWNlQ+o35TggKAYs
QiFb4X6fw5wsVblAN7rEOnwdr7G7q/8=</ds:X509Certificate></ds:X509Data></ds:KeyInfo></ds:Signature><md:SPSSODescriptor protocolSupportEnumeration="urn:oasis:names:tc:SAML:2.0:protocol">
    <md:Extensions>
      <DiscoveryResponse xmlns="urn:oasis:names:tc:SAML:profiles:SSO:idp-discovery-protocol" Binding="urn:oasis:names:tc:SAML:profiles:SSO:idp-discovery-protocol" Location="https://www.bestallningsportalen.sll.se/ULFH/AuthServices/SignIn" index="0" isDefault="true"/>
    </md:Extensions>
    <md:AssertionConsumerService Binding="urn:oasis:names:tc:SAML:2.0:bindings:HTTP-POST" Location="https://www.bestallningsportalen.sll.se/ULFH/AuthServices/Acs" index="0" isDefault="true"/>
  </md:SPSSODescriptor>
  <md:Organization>
    <md:OrganizationName xml:lang="sv">Stockholms Läns Landsting</md:OrganizationName>
    <md:OrganizationDisplayName xml:lang="sv">Stockholms Läns Landsting</md:OrganizationDisplayName>
    <md:OrganizationURL xml:lang="sv">http://www.sll.se/</md:OrganizationURL>
  </md:Organization>
  <md:ContactPerson contactType="support">
    <md:Company>Kentor</md:Company>
    <md:EmailAddress>support-sll@kentor.se</md:EmailAddress>
  </md:ContactPerson>
  <md:ContactPerson contactType="technical">
    <md:EmailAddress>support-sll@kentor.se</md:EmailAddress>
  </md:ContactPerson>
</md:EntityDescriptor>
  `;

export const metadataMock = [
  {
    approved: "2018-10-09T15:04:41.582Z",
    approvedBy: "Test user",
    entityId: "EntityId1",
    metadata: metadataEx,
    submitted: "2018-10-09T15:04:41.582Z",
    submittedBy: "Test user"
  },
  {
    entityId: "EntityId2",
    metadata: metadataEx,
    submitted: "2018-09-12T09:31:41.582Z",
    submittedBy: "Test user"
  },
  {
    entityId: "EntityId3",
    metadata: metadataEx,
    submitted: "2018-02-12T19:31:41.582Z",
    submittedBy: "Test user"
  },
  {
    approved: "2018-10-22T09:31:41.582Z",
    approvedBy: "Test user",
    entityId: "EntityId4",
    metadata: metadataEx,
    submitted: "2018-10-22T09:31:41.582Z",
    submittedBy: "Test user"
  },
  {
    approved: "2018-06-12T09:04:41.582Z",
    approvedBy: "Test user",
    entityId: "EntityId5",
    metadata: metadataEx,
    submitted: "2018-06-12T09:04:41.582Z",
    submittedBy: "Test user"
  },
  {
    approved: "2018-08-25T09:09:41.582Z",
    approvedBy: "Test user",
    entityId: "EntityId6",
    metadata: metadataEx,
    submitted: "2018-08-25T09:09:41.582Z",
    submittedBy: "Test user"
  }
];
