import React from "react";
import PropTypes from "prop-types";

import {
  Header,
  Container,
  Tab,
  Segment,
  SegmentGroup
} from "semantic-ui-react";

import { JsonView } from "../../../helpers/json/JsonView";
import { ResellerUserTableView } from "../list";
import { ResellerFormView } from "./";
import { ErrorMessage } from "../../../common/error";

const panes = [
  {
    menuItem: "General information"
  },
  {
    menuItem: "Reseller user"
  }
];

export class ResellerEditFormView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      mock: false,
      activeIndex: 0,
      panes,
      validationError: false,
      prefixCode: props.prefixCode,
      federationId: props.federationId
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resellerFetchResp, resellerUpdateResp } = nextProps;
    this.loadData({ resellerFetchResp, resellerUpdateResp });
  }

  componentDidMount = () => {
    const { prefixCode } = this.state;
    this.fetchReseller({ prefixCode });
  };

  loadData({ resellerFetchResp, resellerUpdateResp }) {
    if (resellerFetchResp) {
      const resp = resellerFetchResp[this.state.prefixCode];
      if (resp && resp.fetched !== undefined) {
        if (resp.fetched) {
          const data = resp.data;
          this.setState({
            reseller: data
          });
        } else {
          this.setState({
            fetchResellerError: resp.error
          });
        }
      }
    }
    if (resellerUpdateResp) {
      const resp = resellerUpdateResp[this.state.prefixCode];
      if (resp && resp.updated !== undefined) {
        if (resp.updated) {
          this.setState({
            updateResellerSuccess: resp.data,
            prefixCode: resp.data.prefixCode,
            updateResellerError: undefined
          });
          if (resp.data.prefixCode !== this.state.prefixCode) {
            const { federationId } = this.state;
            const redirectLink = `/federation/${federationId}/resellers/${resp.data.prefixCode}/edit/`;
            this.props.history.push(redirectLink);
          }
        } else {
          this.setState({
            updateResellerSuccess: undefined,
            updateResellerError: resp.error
          });
        }
      }
    }
  }

  fetchReseller({ prefixCode }) {
    if (this.state.mock) {
      this.setState({
        reseller: {
          prefixCode: "IIS",
          name: "Test Agent",
          active: true
        }
      });
    } else {
      const { federationId } = this.state;
      this.props.loadResellerRequest({ federationId, prefixCode });
    }
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex: activeIndex });
  };

  updateReseller = data => {
    const { federationId } = this.state;
    const payload = {
      federationId,
      prefixCode: this.state.prefixCode,
      data: data
    };
    this.props.updateResellerRequest(payload);
  };

  render() {
    const {
      reseller = null,
      prefixCode,
      updateResellerSuccess,
      updateResellerError,
      fetchResellerError,
      federationId
    } = this.state;

    return (
      <React.Fragment>
        {false && <JsonView title="Component state:" json={this.state} />}
        {fetchResellerError && !reseller && (
          <ErrorMessage
            title="Failed to fetch Reseller"
            {...fetchResellerError}
          />
        )}
        {reseller && (
          <Container fluid>
            <Header as="h1" floated="left">
              {reseller.name}
            </Header>
            <Tab
              style={{ width: "200%" }}
              renderActiveOnly={true}
              activeIndex={this.state.activeIndex}
              panes={panes}
              onTabChange={this.handleTabChange}
            />

            {this.state.activeIndex === 1 && (
              <React.Fragment>
                <SegmentGroup>
                  <Segment>
                    <ResellerUserTableView
                      prefixCode={prefixCode}
                      federationId={federationId}
                      {...this.props}
                    />
                  </Segment>
                </SegmentGroup>
              </React.Fragment>
            )}
            {this.state.activeIndex === 0 && (
              <ResellerFormView
                title="General information"
                submitText="Update"
                reseller={reseller}
                handleSubmit={this.updateReseller}
                saveError={updateResellerError}
                saveSuccess={updateResellerSuccess}
                userSession={this.props.userSession}
              />
            )}
          </Container>
        )}
      </React.Fragment>
    );
  }
}

ResellerEditFormView.propTypes = {
  prefixCode: PropTypes.object
};

export default ResellerEditFormView;
