import {
  LOAD_RESELLERS_REQUEST,
  LOAD_RESELLERS_SUCCESS,
  LOAD_RESELLERS_FAILURE,
  LOAD_RESELLER_REQUEST,
  LOAD_RESELLER_SUCCESS,
  LOAD_RESELLER_FAILURE,
  LOAD_RESELLER_USERS_REQUEST,
  LOAD_RESELLER_USERS_SUCCESS,
  LOAD_RESELLER_USERS_FAILURE,
  CREATE_RESELLER_REQUEST,
  CREATE_RESELLER_SUCCESS,
  CREATE_RESELLER_FAILURE,
  UPDATE_RESELLER_REQUEST,
  UPDATE_RESELLER_SUCCESS,
  UPDATE_RESELLER_FAILURE,
  DELETE_RESELLER_REQUEST,
  DELETE_RESELLER_SUCCESS,
  DELETE_RESELLER_FAILURE,
  CREATE_RESELLER_USER_REQUEST,
  CREATE_RESELLER_USER_SUCCESS,
  CREATE_RESELLER_USER_FAILURE,
  DELETE_RESELLER_USER_REQUEST,
  DELETE_RESELLER_USER_SUCCESS,
  DELETE_RESELLER_USER_FAILURE,
  UPDATE_RESELLER_USER_REQUEST,
  UPDATE_RESELLER_USER_SUCCESS,
  UPDATE_RESELLER_USER_FAILURE
} from "./actionTypes";

///////////////////////////////////////////////////////////////////////////////
// Load all resellers action
///////////////////////////////////////////////////////////////////////////////
export const loadResellersRequest = ({ federationId }) => {
  return {
    federationId: federationId,
    type: LOAD_RESELLERS_REQUEST
  };
};

export const loadResellersSuccess = ({ data }) => {
  return {
    type: LOAD_RESELLERS_SUCCESS,
    data
  };
};

export const loadResellersFailure = ({ error }) => {
  return {
    type: LOAD_RESELLERS_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Load reseller action
///////////////////////////////////////////////////////////////////////////////
export const loadResellerRequest = ({ federationId, prefixCode }) => {
  return {
    federationId: federationId,
    type: LOAD_RESELLER_REQUEST,
    prefixCode
  };
};

export const loadResellerSuccess = ({ prefixCode, data }) => {
  return {
    type: LOAD_RESELLER_SUCCESS,
    prefixCode,
    data
  };
};

export const loadResellerFailure = ({ prefixCode, error }) => {
  return {
    type: LOAD_RESELLER_FAILURE,
    prefixCode,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Create reseller action
///////////////////////////////////////////////////////////////////////////////
export const createResellerRequest = ({ federationId, prefixCode, data }) => {
  return {
    federationId: federationId,
    type: CREATE_RESELLER_REQUEST,
    prefixCode,
    data
  };
};

export const createResellerSuccess = ({ prefixCode, data }) => {
  return {
    type: CREATE_RESELLER_SUCCESS,
    prefixCode,
    data
  };
};

export const createResellerFailure = ({ prefixCode, error }) => {
  return {
    type: CREATE_RESELLER_FAILURE,
    prefixCode,
    error
  };
};
///////////////////////////////////////////////////////////////////////////////
// Update reseller action
///////////////////////////////////////////////////////////////////////////////
export const updateResellerRequest = ({ federationId, prefixCode, data }) => {
  return {
    federationId: federationId,
    type: UPDATE_RESELLER_REQUEST,
    prefixCode,
    data
  };
};

export const updateResellerSuccess = ({ prefixCode, data }) => {
  return {
    type: UPDATE_RESELLER_SUCCESS,
    prefixCode,
    data
  };
};

export const updateResellerFailure = ({ prefixCode, error }) => {
  return {
    type: UPDATE_RESELLER_FAILURE,
    prefixCode,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Delete reseller action
///////////////////////////////////////////////////////////////////////////////
export const deleteResellerRequest = ({ federationId, prefixCode }) => {
  return {
    federationId: federationId,
    type: DELETE_RESELLER_REQUEST,
    prefixCode
  };
};

export const deleteResellerSuccess = ({ prefixCode }) => {
  return {
    type: DELETE_RESELLER_SUCCESS,
    prefixCode
  };
};

export const deleteResellerFailure = ({ prefixCode, error }) => {
  return {
    type: DELETE_RESELLER_FAILURE,
    prefixCode,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Load reseller users action
///////////////////////////////////////////////////////////////////////////////
export const loadResellerUsersRequest = ({ federationId, prefixCode }) => {
  return {
    federationId: federationId,
    type: LOAD_RESELLER_USERS_REQUEST,
    prefixCode
  };
};

export const loadResellerUsersSuccess = ({ data }) => {
  return {
    type: LOAD_RESELLER_USERS_SUCCESS,
    data
  };
};

export const loadResellerUsersFailure = ({ error }) => {
  return {
    type: LOAD_RESELLER_USERS_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Create reseller users action
///////////////////////////////////////////////////////////////////////////////
export const createResellerUserRequest = ({
  federationId,
  prefixCode,
  data
}) => {
  return {
    federationId: federationId,
    type: CREATE_RESELLER_USER_REQUEST,
    prefixCode,
    data
  };
};

export const createResellerUserSuccess = ({ data }) => {
  return {
    type: CREATE_RESELLER_USER_SUCCESS,
    data
  };
};

export const createResellerUserFailure = ({ error }) => {
  return {
    type: CREATE_RESELLER_USER_FAILURE,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Delete reseller user action
///////////////////////////////////////////////////////////////////////////////
export const deleteResellerUserRequest = ({
  federationId,
  userId,
  prefixCode
}) => {
  return {
    federationId: federationId,
    type: DELETE_RESELLER_USER_REQUEST,
    userId,
    prefixCode
  };
};

export const deleteResellerUserSuccess = ({ userId }) => {
  return {
    type: DELETE_RESELLER_USER_SUCCESS,
    userId
  };
};

export const deleteResellerUserFailure = ({ userId, error }) => {
  return {
    type: DELETE_RESELLER_USER_FAILURE,
    userId,
    error
  };
};

///////////////////////////////////////////////////////////////////////////////
// Update reseller user action
///////////////////////////////////////////////////////////////////////////////
export const updateResellerUserRequest = ({
  federationId,
  userId,
  prefixCode,
  data
}) => {
  return {
    federationId: federationId,
    type: UPDATE_RESELLER_USER_REQUEST,
    userId,
    prefixCode,
    data
  };
};

export const updateResellerUserSuccess = ({ userId, data }) => {
  return {
    type: UPDATE_RESELLER_USER_SUCCESS,
    userId,
    data
  };
};

export const updateResellerUserFailure = ({ userId, error }) => {
  return {
    type: UPDATE_RESELLER_USER_FAILURE,
    userId,
    error
  };
};
