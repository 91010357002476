import React from "react";

import { MemberCardView } from "../../../modules/member/card";
import { Secured } from "../../secure/Secured";

export const PageMemberDashboard = props => (
  <Secured {...props}>
    {props.userSession && props.userSession.realm && (
      <MemberCardView customerId={props.match.params.customerId} {...props} />
    )}
  </Secured>
);

export default PageMemberDashboard;
