export const createSuccessReducer = (state, action) => {
  return { ...state, ...{ created: true, ...action } };
};

export const createFailureReducer = (state, action) => {
  return { ...state, ...{ created: false, ...action } };
};

export const createSuccessReducerById = (state, id, action) => {
  return { ...state, ...{ [id]: { created: true, ...action } } };
};
export const createFailureReducerById = (state, id, action) => {
  return { ...state, ...{ [id]: { created: false, ...action } } };
};

export const loadSuccessReducer = (state, action) => {
  return { ...state, ...{ fetched: true, ...action } };
};

export const loadFailureReducer = (state, action) => {
  return { ...state, ...{ fetched: false, ...action } };
};

export const loadSuccessReducerById = (state, id, action) => {
  return {
    ...state,
    ...{ [id]: { fetched: true, ...action } }
  };
};

export const loadFailureReducerById = (state, id, action) => {
  return {
    ...state,
    ...{ [id]: { fetched: false, ...action } }
  };
};

export const updateSuccessReducerById = (state, id, action) => {
  return { ...state, ...{ [id]: { updated: true, ...action } } };
};

export const updateFailureReducerById = (state, id, action) => {
  return { ...state, ...{ [id]: { updated: false, ...action } } };
};

export const deleteSuccessReducerById = (state, id, action) => {
  return { ...state, ...{ [id]: { deleted: true, ...action } } };
};

export const deleteFailureReducerById = (state, id, action) => {
  return { ...state, ...{ [id]: { deleted: false, ...action } } };
};
