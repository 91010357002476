import { ParsePnr } from "./pnr/parsePnr";

export function validatePnr(data, options) {
  return ParsePnr(data, options);
}

export function normalizePnr(data, options) {
  let pnr = ParsePnr(data, options);
  if (pnr.valid) {
    return pnr.normalized;
  } else {
    return pnr;
  }
}
