import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

export const UserForm = ({ form, handleChange, error, req }) => {
  const { technicalContact = {} } = form;
  const { firstName } = technicalContact;
  const { lastName } = technicalContact;
  const { email } = technicalContact;
  const { phone } = technicalContact;
  const { ssn } = technicalContact;
  return (
    <React.Fragment>
      <Form.Field>
        <Form.Input
          fluid
          required={true}
          name="firstName"
          label="First name"
          placeholder="First name"
          onChange={handleChange}
          value={technicalContact && firstName}
          autoFocus
          maxLength="36"
          autoComplete="off"
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          fluid
          required={true}
          name="lastName"
          label="Last name"
          placeholder="Last name"
          onChange={handleChange}
          value={technicalContact && lastName}
          maxLength="36"
          autoComplete="off"
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          fluid
          required={true}
          name="email"
          label="E-Mail"
          icon="mail"
          placeholder="E-Mail"
          onChange={handleChange}
          value={technicalContact && email}
          type="email"
          maxLength="70"
          autoComplete="off"
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          fluid
          required={true}
          name="phone"
          label="Phone"
          placeholder="Phone"
          icon="phone"
          onChange={handleChange}
          value={technicalContact && phone}
          maxLength="36"
          autoComplete="off"
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          fluid
          required={req}
          name="ssn"
          label="Personal Identity Number"
          placeholder="YYYYMMDDNNNN"
          onChange={handleChange}
          value={technicalContact && ssn}
          minLength="12"
          maxLength="12"
          autoComplete="off"
        />
      </Form.Field>
    </React.Fragment>
  );
};

UserForm.propTypes = {
  handleChange: PropTypes.func.isRequired,
  form: PropTypes.object
};

export default UserForm;
