import React, { Component } from "react";
import {
  Container,
  Header,
  Segment,
  Message,
  Divider,
  Loader
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { ApproveEntityList } from "./list/ApproveEntityList";
import { MetaDataApproveDiffView } from "./approve/MetaDataApproveDiffView";
import { JsonView } from "../../helpers/json/JsonView";

import { EntityApproveView } from "./form/EntityApproveView";
import { ErrorMessage } from "../../common/error";
import { metadataMock } from "./example/mockdata";

export class MetaDataApproveView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activePage: 0,
      mock: props.mock,
      federationId: props.userSession.realm
    };
  }

  componentDidMount = () => {
    const { federationId } = this.state;
    if (federationId && !this.state.metadataFetched) {
      this.setState({
        federationId,
        metadataFetched: true,
        loadingEntities: true
      });
      this.fetchMetadataEntities(federationId);
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      federationMetadataListFetchResp,
      metadataFetchResp,
      userSession
    } = nextProps;
    if (federationMetadataListFetchResp) {
      if (federationMetadataListFetchResp.fetched) {
        const entities = federationMetadataListFetchResp.data.sort((a, b) =>
          a.submittedTime > b.submittedTime
            ? 1
            : b.submittedTime > a.submittedTime
            ? -1
            : 0
        );
        this.setState({
          entities,
          total: entities.length,
          loadingEntities: false
        });
      } else {
        this.setState({
          fetchListError: federationMetadataListFetchResp.error,
          total: 0,
          loadingEntities: false
        });
      }
    }

    if (metadataFetchResp) {
      const { activeEntityId } = this.state;
      const resp = metadataFetchResp[activeEntityId];
      if (resp) {
        if (resp.fetched) {
          this.setState({ activeEntity: resp.data, loadingEntity: false });
        } else {
          this.setState({
            fetchMetadataError: resp.error,
            loadingEntity: false
          });
        }
      }
    }
    if (userSession && !this.state.metadataFetched) {
      this.setState({ federationId: userSession.realm, metadataFetched: true });
      this.fetchMetadataEntities(userSession.realm);
    }
  }

  fetchMetadataEntities = federationId => {
    this.setState({ loadingEntities: true });
    if (this.state.mock) {
      this.setState({ entities: metadataMock, total: metadataMock.length });
    } else {
      this.props.loadFederationMetadataListRequest({
        federationId: federationId
      });
    }
  };

  handlePageChange = (page, entityId, entity) => {
    this.setState({
      activePage: page,
      activeEntityId: entityId,
      activeEntity: undefined,
      fetchMetadataError: undefined
    });
    if (page === 1) {
      this.setState({ loadingEntity: true });
      this.props.loadMetadataRequest({
        federationId: this.state.federationId,
        entityId: entityId
      });
    }
  };

  handleApprove = (entityId, approve, message) => {
    const { federationId } = this.state;
    this.props.approveMetadataRequest({
      federationId,
      entityId,
      approve,
      message
    });
  };

  reloadMetadata = federationId => {
    this.setState({ loadingEntities: true });
    this.props.loadFederationMetadataListRequest({ federationId });
    this.setState({
      activePage: 0
    });
  };

  entitiesToApprove = entities => {
    return entities.filter(
      entity =>
        entity.aggregationValidationStatus === "ok" ||
        entity.aggregationValidationStatus === null ||
        entity.aggregationValidationStatus === "disabled"
    );
  };

  entitiesWithValidationErrors = entities => {
    return entities.filter(
      entity => entity.aggregationValidationStatus === "error"
    );
  };

  render() {
    const {
      activePage,
      entities = [],
      activeEntityId,
      activeEntity,
      fetchMetadataError,
      fetchListError,
      federationId,
      loadingEntities,
      loadingEntity
    } = this.state;
    return (
      <React.Fragment>
        {false && <JsonView title="State:" json={this.state} />}
        {activePage === 0 && (
          <Container fluid>
            <Header as="h1">Entities</Header>
            <Segment>
              {loadingEntities && <Loader active inline="centered" />}
              {this.entitiesToApprove(entities) &&
                this.entitiesToApprove(entities).length > 0 && (
                  <Container fluid>
                    <Header as="h3">Entities to approve:</Header>
                    <ApproveEntityList
                      entities={this.entitiesToApprove(entities)}
                      handlePageChange={this.handlePageChange}
                    />
                  </Container>
                )}
              <Divider hidden />
              {this.entitiesWithValidationErrors(entities) &&
                this.entitiesWithValidationErrors(entities).length > 0 && (
                  <Container fluid>
                    <Header as="h3">Entities with validation errors:</Header>
                    <ApproveEntityList
                      entities={this.entitiesWithValidationErrors(entities)}
                      handlePageChange={this.handlePageChange}
                    />
                  </Container>
                )}
              {entities.length === 0 && !fetchListError && !loadingEntities && (
                <Message>No metadata entities waiting for approval.</Message>
              )}
              {fetchListError && (
                <ErrorMessage
                  title="Fail to fetch metadata entities"
                  {...fetchListError}
                />
              )}
            </Segment>
          </Container>
        )}
        {activePage === 1 && (
          <Segment>
            <button
              onClick={e => {
                e && e.preventDefault();
                this.handlePageChange(0);
              }}
            >
              Entities
            </button>
            / {activeEntityId}
            <Header as="h1">
              Approve metadata{" "}
              {activeEntity && <span>- {activeEntity.memberName}</span>}
            </Header>
            <Container fluid>
              {loadingEntity && <Loader active inline="centered" />}
              {activeEntity && (
                <MetaDataApproveDiffView
                  entityId={activeEntityId}
                  entityResponse={activeEntity}
                />
              )}
              {fetchMetadataError && (
                <ErrorMessage
                  title="Could not fetch metadata entity"
                  {...fetchMetadataError}
                />
              )}
            </Container>
            <Divider hidden />
            {activeEntity &&
              (activeEntity.entityToApprove.aggregationValidationStatus ===
                "ok" ||
                activeEntity.entityToApprove.aggregationValidationStatus ===
                  null ||
                activeEntity.entityToApprove.aggregationValidationStatus ===
                  "disabled") && (
                <Container>
                  <EntityApproveView
                    approve={true}
                    federationId={federationId}
                    entityId={activeEntityId}
                    handleSubmit={this.handleApprove}
                    reloadMetadata={this.reloadMetadata}
                    metadataApproveResp={this.props.metadataApproveResp}
                    customerId={activeEntity.entityToApprove.customerId}
                    federationMetadataValidateFetchResp={
                      this.props.federationMetadataValidateFetchResp
                    }
                    loadMetadataValidateRequest={
                      this.props.loadMetadataValidateRequest
                    }
                  />
                  <EntityApproveView
                    approve={false}
                    federationId={federationId}
                    entityId={activeEntityId}
                    handleSubmit={this.handleApprove}
                    reloadMetadata={this.reloadMetadata}
                    metadataApproveResp={this.props.metadataApproveResp}
                    customerId={activeEntity.entityToApprove.customerId}
                    aggregationValidationStatus={
                      activeEntity.aggregationValidationStatus
                    }
                    federationMetadataValidateFetchResp={
                      this.props.federationMetadataValidateFetchResp
                    }
                    loadMetadataValidateRequest={
                      this.props.loadMetadataValidateRequest
                    }
                  />
                </Container>
              )}
          </Segment>
        )}
      </React.Fragment>
    );
  }
}

MetaDataApproveView.propTypes = {
  mock: PropTypes.bool
};

export default MetaDataApproveView;
