import React from "react";
import {
  Button,
  Form,
  Segment,
  SegmentGroup,
  Radio,
  Header,
  Message,
  Divider,
  Dimmer,
  Loader
} from "semantic-ui-react";
// import { member } from "../mock/memberData";
import { JsonView } from "../../../helpers/json/JsonView";
import PropTypes from "prop-types";
import { ErrorMessage, ErrorValidationMessage } from "../../../common/error";

import {
  authorize,
  isUserAuthorized
} from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";

export class MemberFormEditView extends React.Component {
  constructor({
    title,
    saveText,
    step = 1,
    showGeneralInfoForm = true,
    showTechnicalContactForm = false,
    form = null,
    customerId,
    federationType
  }) {
    super();

    this.state = {
      title,
      saveText,
      step,
      showGeneralInfoForm,
      showTechnicalContactForm,
      form,
      dirtyForm: false,
      validationError: false,
      customerId,
      federationType
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleBoolean = this.toggleBoolean.bind(this);
    this.toggleNumber = this.toggleNumber.bind(this);
  }

  componentDidMount() {
    if (this.props.userSession) {
      this.setState({
        isReadOnly: !isUserAuthorized(
          authMapping.UPDATE_MEMBER,
          this.props.userSession.roles
        )
      });
    } else {
      console.error("userSession not set!");
    }
  }

  UNSAFE_componentWillReceiveProps({
    form,
    updateSuccess,
    error,
    updateError,
    validationError
  }) {
    if (form && this.state.dirtyForm === false) {
      this.setState({ form });
    }

    if (updateSuccess) {
      this.setState({
        updateSuccess,
        updateError: undefined,
        validationError: undefined,
        dirtyForm: false,
        loading: false
      });
    }
    if (error) {
      this.setState({ error, updateError: undefined, loading: false });
    }

    if (validationError || validationError === false) {
      this.setState({
        validationError,
        updateSuccess: undefined,
        updateError: undefined,
        loading: false
      });
    }
    if (updateError) {
      this.setState({ updateError, updateSuccess: undefined, loading: false });
    }
  }

  handleChange(event) {
    const target = event.target;

    this.setState({
      dirtyForm: true,
      status: "edit",
      validationError: undefined,
      updateSuccess: undefined,
      form: {
        ...this.state.form,
        ...{ [target.name]: target.value }
      }
    });
  }

  toggleBoolean = name => {
    const state = this.state.form[name];
    const newState = { ...this.state.form, ...{ [name]: !state } };

    this.setState({
      dirtyForm: true,
      form: newState
    });
  };
  toggleNumber = name => {
    const state = this.state.form[name];
    const newState = {
      ...this.state.form,
      ...{ [name]: state !== 1 ? 1 : 0 }
    };

    this.setState({ form: newState });
  };

  handleSave() {
    if (this.state.showGeneralInfoForm) {
      if (!this.hasValidationError(this.state.form.name)) {
        this.setState({ loading: true });
        this.props.handleSubmit(this.state.form);
      }
    }
  }

  hasValidationError = value => {
    const status = value === undefined || value === "";
    return status;
  };

  render() {
    const {
      form,
      saveText = "Save",
      showGeneralInfoForm,
      updateSuccess,
      updateError,
      dirtyForm,
      validationError,
      loading,
      isReadOnly,
      federationType
    } = this.state;

    return (
      <React.Fragment>
        {showGeneralInfoForm === true && form && (
          <React.Fragment>
            <Header as="h2">{form && form.name}</Header>
            <SegmentGroup>
              <Segment>
                {false && <JsonView title="FORM" json={this.state} />}
                <Form
                  success={updateSuccess !== undefined}
                  error={
                    updateError !== undefined || validationError !== undefined
                  }
                >
                  {updateSuccess && (
                    <Message success header="General info form is updated" />
                  )}
                  {updateError && (
                    <ErrorMessage
                      title="Fail to update general info form"
                      {...updateError}
                    />
                  )}
                  <Form.Field>
                    <Form.Input
                      name={"customerId"}
                      label="CustomerID"
                      placeholder="CustomerID"
                      value={form.customerId}
                      control="input"
                      style={{ background: "#DCDCDC" }}
                      readOnly={true}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      name="name"
                      label="Name"
                      placeholder="Name"
                      onChange={this.handleChange}
                      value={form.name}
                      required
                      autoFocus
                      readOnly={isReadOnly}
                    />
                  </Form.Field>
                  {!validationError &&
                    federationType &&
                    federationType === "SAML" && (
                      <Message content="One of Idp or Sp has to be marked as allowed" />
                    )}
                  {!validationError &&
                    federationType &&
                    federationType === "FedTLS" && (
                      <Message content="One of Client or Server has to be marked as allowed" />
                    )}
                  {validationError && (
                    <ErrorValidationMessage
                      title=""
                      message={validationError}
                    />
                  )}
                  {federationType && federationType === "SAML" && (
                    <div>
                      <Form.Field>
                        <Radio
                          name="hasAo"
                          toggle
                          label="Allow Idp"
                          onChange={() => this.toggleBoolean("hasAo")}
                          checked={form.hasAo}
                          readOnly={isReadOnly}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          name="hasSp"
                          toggle
                          label="Allow Sp"
                          onChange={() => this.toggleBoolean("hasSp")}
                          checked={form.hasSp}
                          readOnly={isReadOnly}
                        />
                      </Form.Field>
                    </div>
                  )}
                  {federationType && federationType === "FedTLS" && (
                    <div>
                      <Form.Field>
                        <Radio
                          name="hasAo"
                          toggle
                          label="Allow Client"
                          onChange={() => this.toggleBoolean("hasAo")}
                          checked={form.hasAo}
                          readOnly={isReadOnly}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          name="hasSp"
                          toggle
                          label="Allow Server"
                          onChange={() => this.toggleBoolean("hasSp")}
                          checked={form.hasSp}
                          readOnly={isReadOnly}
                        />
                      </Form.Field>
                    </div>
                  )}
                  {this.props.userSession &&
                    authorize(
                      authMapping.UPDATE_MEMBER,
                      this.props.userSession.roles,
                      <Segment>
                        {loading && (
                          <Dimmer active inverted>
                            <Loader active inline />
                          </Dimmer>
                        )}
                        <Button
                          primary
                          disabled={!dirtyForm}
                          onClick={this.handleSave}
                        >
                          {saveText}
                        </Button>
                      </Segment>
                    )}
                </Form>
              </Segment>
            </SegmentGroup>
            <Divider hidden />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

MemberFormEditView.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  form: PropTypes.object,
  federationType: PropTypes.string
};

export default MemberFormEditView;
