import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /*componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
    logError(error, info);
  }*/

  render() {
    return (
      <React.Fragment>
        {this.state.hasError && (
          <div>
            <h1> Something went wrong.</h1>
            <pre>info: {JSON.stringify(this.state.info, null, 2)}</pre>
            <pre>error: {JSON.stringify(this.state.error, null, 2)}</pre>
          </div>
        )}
        {!this.state.hasError && <div>{this.props.children}</div>}
      </React.Fragment>
    );
  }
}

export default ErrorBoundary;
