/**
 * @return {boolean}
 */
export function DetermineRealmTrial(federationId) {
  let result = true;
  let trial,
    acc = "";
  // sanity check
  if (federationId) {
    trial = federationId && federationId.indexOf("-trial") >= 0;
    acc = federationId && federationId.indexOf("-acc") >= 0;
    if (trial || acc) {
      result = false;
    }
  }
  return result;
}

export default DetermineRealmTrial;
