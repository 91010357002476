import { createHashHistory } from "history";
import { applyMiddleware, createStore, compose } from "redux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { logger } from "redux-logger";

import * as ReselectTools from "reselect-tools";
//import reducerRegistry from "./register";
import createSagaMiddleware from "redux-saga";
import { rootReducer } from "./ducks";
import rootSaga from "./sagas";
import * as selectors from "./ducks/member/selectors";
const initialState = [];
const sagaMiddleware = createSagaMiddleware();

export const history = createHashHistory();

// Preserve initial state for not-yet-loaded reducers
/*const combine = reducers => {
  const reducerNames = Object.keys(reducers);
  Object.keys(initialState).forEach(item => {
    if (reducerNames.indexOf(item) === -1) {
      reducers[item] = (state = null) => state;
    }
  });
  return combineReducers(reducers);
};*/

//reducerRegistry.register(refreshToken);
//const reducer = combine(reducerRegistry.getReducers());

const enhancers = [];

const middleware = [routerMiddleware(history), logger, sagaMiddleware];

if (process.env.NODE_ENV === "development") {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension());
  }
}

const store = createStore(
  connectRouter(history)(rootReducer), // new root reducer with router state
  initialState,
  compose(
    applyMiddleware(...middleware),
    ...enhancers
  )
);
sagaMiddleware.run(rootSaga);
// Replace the store's reducer whenever a new reducer is registered.
/*reducerRegistry.setChangeListener(reducers => {
  store.replaceReducer(combine(reducers));
});
*/

ReselectTools.getStateWith(() => store.getState()); // allows you to get selector inputs and outputs
ReselectTools.registerSelectors(selectors); // register string names for selectors

export default store;
