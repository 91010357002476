import React from "react";
import PropTypes from "prop-types";
import { MemberSearch } from "../../../modules/member/list/MemberSearch";
import { Page } from "../../Page";

export const PageMembers = ({
  query = "*",
  orderBy = "name",
  desc = true,
  searchMemberRequest,
  memberSearchResp,
  userSession,
  dynamicConfig,
  federationInfoResp,
  shoutoutResp
}) => {
  return (
    <div>
      {userSession && userSession.realm && federationInfoResp && (
        <Page
          federationId={userSession.realm}
          dynamicConfig={dynamicConfig}
          shoutoutResp={shoutoutResp}
        >
          <MemberSearch
            query={query}
            orderBy={orderBy}
            desc={desc}
            searchMemberRequest={searchMemberRequest}
            memberSearchResp={memberSearchResp}
            federationInfoResp={federationInfoResp}
          />
        </Page>
      )}
    </div>
  );
};

PageMembers.propTypes = {
  query: PropTypes.string,
  orderBy: PropTypes.string,
  desc: PropTypes.bool,
  searchMemberRequest: PropTypes.func.isRequired,
  memberSearchResp: PropTypes.object.isRequired,
  fetchMembersMDCountRequest: PropTypes.func,
  federationMemberMDCountResp: PropTypes.object,
  federationInfoResp: PropTypes.object.isRequired
};

export default PageMembers;
