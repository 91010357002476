import React from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";

export const ErrorMessage = ({
  title = "Something went wrong.",
  statusCode,
  statusMessage,
  message
}) => (
  <Message
    error
    header={title}
    content={
      (message &&
        `Http code: ${statusCode}, ${statusMessage}. Message: ${message}.`) ||
      `Http code: ${statusCode}, ${statusMessage}.`
    }
  />
);

ErrorMessage.propTypes = {
  title: PropTypes.string.isRequired,
  statusCode: PropTypes.number,
  statusMessage: PropTypes.string,
  message: PropTypes.string
};

export default ErrorMessage;
