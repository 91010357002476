import React from "react";

import { Container } from "semantic-ui-react";

import { BaseLayout } from "../layout";
import { AppContext } from "../App";

export const Page = (
  {
    showLogin = true,
    children,
    keycloak = undefined,
    history,
    federationId,
    dynamicConfig,
    shoutoutResp
  },
  props
) => (
  <AppContext.Consumer>
    {app => (
      <BaseLayout
        inverted={true}
        showLogin={showLogin}
        keycloak={keycloak}
        history={history}
        federationId={federationId}
        dynamicConfig={dynamicConfig}
        shoutoutResp={shoutoutResp}
      >
        <Container fluid>{children}</Container>
      </BaseLayout>
    )}
  </AppContext.Consumer>
);

export default Page;
