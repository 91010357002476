import React from "react";

import { Button, Icon, FormGroup, Form } from "semantic-ui-react";

import { keycloakInstance } from "../../keycloak";

const keycloakLogout = () => {
  keycloakInstance.keycloak.logout({
    redirectUri: window.location.origin + "/start.html"
  });
};

export class LoggedInUser extends React.Component {
  constructor() {
    super();
    this.logout = this.logout.bind(this);
  }

  logout() {
    keycloakLogout();
  }

  render() {
    const { userInfo } = this.props.userSession;
    return (
      <React.Fragment>
        {userInfo && (
          <FormGroup
            horizontal={"true"}
            style={{ float: "right", padding: "10px 0" }}
          >
            <Form.Field>
              <Icon name="user outline" />
              <label>{userInfo.name}</label>
            </Form.Field>
            <Form.Field>
              <label>{userInfo.email}</label>
            </Form.Field>
            <Form.Field>
              <label>{userInfo.phone}</label>
            </Form.Field>
            <Button primary onClick={() => this.logout()}>
              Logout
            </Button>
          </FormGroup>
        )}
      </React.Fragment>
    );
  }
}

export default LoggedInUser;
