export const federations = {
    "sambi": {
        integrityPolicy: "https://www.sambi.se/om/integritetspolicy/",
        mail: "info@sambi.se",
        address: "www.sambi.se",
        addressLink: "https://www.sambi.se"
    },
    "sambi-trial": {
        integrityPolicy: "https://www.sambi.se/om/integritetspolicy/",
        mail: "info@sambi.se",
        address: "www.sambi.se",
        addressLink: "https://www.sambi.se"
    },
    "sambi-acc": {
        integrityPolicy: "https://www.sambi.se/om/integritetspolicy/",
        mail: "info@sambi.se",
        address: "www.sambi.se",
        addressLink: "https://www.sambi.se"
    },
    "skolfed": {
        integrityPolicy: "https://www.skolfederation.se/om/integritetspolicy/",
        mail: "info@skolfederation.se",
        address: "www.skolfederation.se",
        addressLink: "https://www.skolfederation.se"
    },
    "skolfed-trial": {
        integrityPolicy: "https://www.skolfederation.se/om/integritetspolicy/",
        mail: "info@skolfederation.se",
        address: "www.skolfederation.se",
        addressLink: "https://www.skolfederation.se"
    },
    "kontosynk": {
        integrityPolicy: "https://www.skolfederation.se/om/integritetspolicy/",
        mail: "info@skolfederation.se",
        address: "www.skolfederation.se",
        addressLink: "https://www.skolfederation.se"
    },
    "kontosynk-trial": {
        integrityPolicy: "https://www.skolfederation.se/om/integritetspolicy/",
        mail: "info@skolfederation.se",
        address: "www.skolfederation.se",
        addressLink: "https://www.skolfederation.se"
    }
};
