// Used to map actions to authMapping
export const CREATE_FEDERATION_USER = "CREATE_FEDERATION_USER";
export const READ_FEDERATION_USER = "READ_FEDERATION_USER";
export const UPDATE_FEDERATION_USER = "UPDATE_FEDERATION_USER";
export const DELETE_FEDERATION_USER = "DELETE_FEDERATION_USER";
export const CREATE_FEDERATION = "CREATE_FEDERATION";
export const READ_FEDERATION = "READ_FEDERATION";
export const UPDATE_FEDERATION = "UPDATE_FEDERATION";
export const DELETE_FEDERATION = "DELETE_FEDERATION";
export const LIST_RESELLER_USER = "LIST_RESELLER_USER";
export const CREATE_RESELLER_USER = "CREATE_RESELLER_USER";
export const READ_RESELLER_USER = "READ_RESELLER_USER";
export const UPDATE_RESELLER_USER = "UPDATE_RESELLER_USER";
export const DELETE_RESELLER_USER = "DELETE_RESELLER_USER";
export const LIST_RESELLER = "LIST_RESELLER";
export const CREATE_RESELLER = "CREATE_RESELLER";
export const READ_RESELLER = "READ_RESELLER";
export const UPDATE_RESELLER = "UPDATE_RESELLER";
export const DELETE_RESELLER = "DELETE_RESELLER";
export const LIST_MEMBER = "LIST_MEMBER";
export const CREATE_MEMBER = "CREATE_MEMBER";
export const READ_MEMBER = "READ_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const CREATE_TECH_AGENT = "CREATE_TECH_AGENT";
export const DELETE_TECH_AGENT = "CREATE_TECH_AGENT";
export const READ_TECH_AGENT = "READ_TECH_AGENT";
export const UPDATE_TECH_AGENT = "UPDATE_TECH_AGENT";
export const HANDLE_METADATA = "HANDLE_METADATA";
export const APPROVE_METADATA = "APPROVE_METADATA";
export const ACTIVATE_MEMBER = "ACTIVATE_MEMBER";
export const READ_ADMIN = "READ_ADMIN";
