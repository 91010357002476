import { fetchWrapped, GET, POST, PUT, DELETE } from "../api";

const API_ENDPOINT = "/fedreg/apis/organization/api";
// const baseUrl = "/fedreg/apis/organization/api/reseller";

export const fetchResellersApi = ({ federationId }) =>
  fetchWrapped(`${API_ENDPOINT}/federation/${federationId}/reseller`, {
    method: "GET"
  });

export const createResellerApi = ({ federationId, data }) => {
  return fetchWrapped(`${API_ENDPOINT}/federation/${federationId}/reseller`, {
    method: "POST",
    body: JSON.stringify({
      ...data
    })
  });
};

export const deleteResellerApi = ({ federationId, prefixCode }) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}`,
    {
      method: DELETE
    }
  );

export const fetchResellerApi = ({ federationId, prefixCode }) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}`,
    {
      method: GET
    }
  );

export const updateResellerApi = ({ federationId, prefixCode, data }) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}`,
    {
      method: PUT,
      body: JSON.stringify(data)
    }
  );

export const fetchResellerUsersApi = ({ federationId, prefixCode }) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}/user/`,
    {
      method: GET
    }
  );

export const createResellerUserApi = ({ federationId, prefixCode, data }) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}/user/`,
    {
      method: POST,
      body: JSON.stringify(data)
    }
  );

export const updateResellerUserApi = ({
  federationId,
  prefixCode,
  userId,
  data
}) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}/user/${userId}`,
    {
      method: PUT,
      body: JSON.stringify(data)
    }
  );

export const deleteResellerUserApi = ({ federationId, prefixCode, userId }) =>
  fetchWrapped(
    `${API_ENDPOINT}/federation/${federationId}/reseller/${prefixCode}/user/${userId}`,
    {
      method: DELETE
    }
  );
