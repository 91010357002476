import { call, put } from "redux-saga/effects";
// import { push } from "react-router-redux";
import {
  fetchResellersApi,
  fetchResellerApi,
  fetchResellerUsersApi,
  createResellerApi,
  updateResellerApi,
  deleteResellerApi,
  createResellerUserApi,
  deleteResellerUserApi,
  updateResellerUserApi
} from "./api";

import {
  loadResellersSuccess,
  loadResellersFailure,
  loadResellerSuccess,
  loadResellerFailure,
  loadResellerUsersSuccess,
  loadResellerUsersFailure,
  createResellerSuccess,
  createResellerFailure,
  updateResellerSuccess,
  updateResellerFailure,
  deleteResellerSuccess,
  deleteResellerFailure,
  createResellerUserSuccess,
  createResellerUserFailure,
  deleteResellerUserSuccess,
  deleteResellerUserFailure,
  updateResellerUserSuccess,
  updateResellerUserFailure
} from "../../ducks/reseller/actions";

const logError = (error, type) =>
  console.info(`api -> saga -> ${type} -> error:`, error);

// FETCH_RESELLERS
function* fetchResellers(action) {
  const { federationId } = action;
  const { resp, error } = yield call(fetchResellersApi, { federationId });
  if (resp) {
    const data = yield resp.json();
    yield put(
      loadResellersSuccess({
        data
      })
    );
  } else if (error) {
    logError("fetchResellers", error);
    yield put(
      loadResellersFailure({
        error: yield error
      })
    );
  }
}

// FETCH_RESELLER
function* fetchReseller(action) {
  const { federationId, prefixCode } = action;
  const { resp, error } = yield call(fetchResellerApi, {
    federationId,
    prefixCode
  });
  if (resp) {
    const data = yield resp.json();
    yield put(
      loadResellerSuccess({
        prefixCode: action.prefixCode,
        data
      })
    );
  } else if (error) {
    logError("fetchReseller", error);
    yield put(
      loadResellerFailure({
        prefixCode: action.prefixCode,
        error: yield error
      })
    );
  }
}

// CREATE_RESELLER
function* createReseller(action) {
  let data = action.data;
  const { federationId } = action;

  data.federationId = federationId;
  const { resp = false, error = false } = yield call(createResellerApi, {
    federationId,
    data: data
  });

  if (resp) {
    yield put(
      createResellerSuccess({
        prefixCode: action.prefixCode,
        data
      })
    );
  } else if (error) {
    logError("createReseller", error);
    yield put(
      createResellerFailure({
        prefixCode: action.prefixCode,
        error: yield error
      })
    );
  }
}

// UPDATE_RESELLER
function* updateReseller(action) {
  const { federationId } = action;
  const data = { ...action.data, federationId };

  const { resp = false, error = false } = yield call(updateResellerApi, {
    federationId,
    prefixCode: action.prefixCode,
    data: data
  });

  if (resp) {
    yield put(
      updateResellerSuccess({
        prefixCode: action.prefixCode,
        data
      })
    );
  } else if (error) {
    logError("updateReseller", error);
    yield put(
      updateResellerFailure({
        prefixCode: action.prefixCode,
        error: yield error
      })
    );
  }
}
// DELETE_RESELLER
function* deleteReseller(action) {
  const { federationId } = action;
  const data = { ...action.data, federationId };

  const { resp = false, error = false } = yield call(deleteResellerApi, {
    federationId,
    prefixCode: action.prefixCode,
    data: data
  });

  if (resp) {
    yield put(
      deleteResellerSuccess({
        prefixCode: action.prefixCode
      })
    );
  } else if (error) {
    logError("deleteReseller", error);
    yield put(
      deleteResellerFailure({
        prefixCode: action.prefixCode,
        error: yield error
      })
    );
  }
}

// FETCH_RESELLER_USERS
function* fetchResellerUsers(action) {
  const { federationId, prefixCode } = action;
  const { resp, error } = yield call(fetchResellerUsersApi, {
    federationId,
    prefixCode
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      loadResellerUsersSuccess({
        data
      })
    );
  } else if (error) {
    logError("fetchResellerUsers", error);
    yield put(
      loadResellerUsersFailure({
        prefixCode,
        error: yield error
      })
    );
  }
}

// CREATE_RESELLER_USER
function* createResellerUser(action) {
  const { federationId, prefixCode } = action;
  const { resp, error } = yield call(createResellerUserApi, {
    federationId,
    prefixCode,
    data: action.data
  });

  if (resp) {
    const data = yield resp.json();
    yield put(
      createResellerUserSuccess({
        data
      })
    );
  } else if (error) {
    logError("createResellerUser", error);
    yield put(
      createResellerUserFailure({
        error: yield error
      })
    );
  }
}

// DELETE_RESELLER_USER
function* deleteResellerUser(action) {
  const { federationId, prefixCode } = action;
  const { resp, error } = yield call(deleteResellerUserApi, {
    federationId,
    prefixCode,
    userId: action.userId
  });

  if (resp) {
    yield put(
      deleteResellerUserSuccess({
        userId: action.userId
      })
    );
  } else if (error) {
    logError("deleteResellerUser", error);
    yield put(
      deleteResellerUserFailure({
        userId: action.userId,
        error: yield error
      })
    );
  }
}

// DELETE_RESELLER_USER
function* updateResellerUser(action) {
  const { federationId, prefixCode, userId } = action;
  const { resp, error } = yield call(updateResellerUserApi, {
    federationId,
    prefixCode,
    userId,
    data: action.data
  });

  if (resp) {
    yield put(
      updateResellerUserSuccess({
        userId: action.userId,
        data: action.data
      })
    );
  } else if (error) {
    logError("updateResellerUser", error);
    yield put(
      updateResellerUserFailure({
        userId: action.userId,
        error: yield error
      })
    );
  }
}

export {
  fetchResellers,
  fetchReseller,
  fetchResellerUsers,
  createReseller,
  updateReseller,
  deleteReseller,
  createResellerUser,
  deleteResellerUser,
  updateResellerUser
};
