import React from "react";
import PropTypes from "prop-types";
import { Message } from "semantic-ui-react";

export const ErrorValidationMessage = ({
  title = "Validation error.",
  message
}) => <Message error header={title} content={message} />;

ErrorValidationMessage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string
};

export default ErrorValidationMessage;
