import React from "react";

import { Grid } from "semantic-ui-react";
import { Top } from "./top/iis/Top";

import { Footer } from "./footer/Footer";

import { ErrorBoundary } from "../common/error";

export class BaseLayout extends React.Component {
  constructor({
    inverted = false,
    showLogin = true,
    federationId,
    shoutoutResp
  }) {
    super();
    this.state = { inverted: inverted, showLogin: showLogin, federationId };
  }

  render() {
    const { inverted, showLogin, federationId } = this.state;
    return (
      <div className="site">
        <ErrorBoundary>
          <Top inverted={inverted} showLogin={showLogin} {...this.props} />

          <Grid
            className="site-content"
            padded={true}
            stackable={true}
            columns={3}
          >
            <Grid.Column width="2" />
            <Grid.Column width="12">{this.props.children}</Grid.Column>
            <Grid.Column width="2" floated="right" />
          </Grid>
        </ErrorBoundary>
        <Footer federationId={federationId} />
      </div>
    );
  }
}
export default BaseLayout;
