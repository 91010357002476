import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import App from "./App";
import { HashRouter as Router } from "react-router-dom";
import store, { history } from "./store";
import "filepond/dist/filepond.min.css";
import "semantic-ui-css/semantic.min.css";
import { initKeyCloak } from "./keycloak";
import { loadConfig } from "./config";

const container = document.getElementById("root");
const root = createRoot(container);
const REFRESH_TOKEN_EVERY = 1 * 60 * 1000;

const hash = window.location.hash;
const url =
  hash.indexOf("&") === -1 ? hash : hash.substring(0, hash.indexOf("&"));
const paths = url.split("/");
let federationId;
if (paths[0] === "#" && paths[1] === "federation") {
  federationId = paths[paths.indexOf("federation") + 1];
}
//Get theme for federation
switch (federationId) {
  case "skolfed":
    import("./css/skolfed/semantic-fedreg-ui.css");
    break;
  case "skolfed-trial":
    import("./css/skolfed/semantic-fedreg-ui.css");
    break;
  case "sambi":
    import("./css/sambi/semantic-fedreg-ui.css");
    break;
  case "sambi-trial":
    import("./css/sambi/semantic-fedreg-ui.css");
    break;
  case "sambi-acc":
    import("./css/sambi/semantic-fedreg-ui.css");
    break;
  case "kontosynk-trial":
    import("./css/skolfed/semantic-fedreg-ui.css");
    break;
  case "kontosynk":
    import("./css/skolfed/semantic-fedreg-ui.css");
    break;
  case "fedvis-trial":
    import("./css/sambi/semantic-fedreg-ui.css");
    break;
  case "fedvis-acc":
    import("./css/sambi/semantic-fedreg-ui.css");
    break;
  case "fedvis":
    import("./css/sambi/semantic-fedreg-ui.css");
    break;
  default:
    import("semantic-ui-css/semantic.min.css");
    break;
}

if (federationId) {
  initKeyCloak(federationId, keycloak => {
    keycloak
      .init({
        onLoad: "login-required",
        checkLoginIframe: false,
        promiseType: "native"
      })
      .then(authenticated => {
        const roles = keycloak.realmAccess.roles;
        keycloak.loadUserProfile().then(profile => {
          keycloak.loadUserInfo().then(userInfo => {
            const { attributes } = profile;
            store.dispatch({
              type: "AUTH_SUCCESS",
              userInfo: { ...userInfo, ...attributes },
              roles,
              realm: federationId
            });
          });
        });
        // We also load dynamic config from /config.json
        loadConfig().then(config => {
          console.log(
            "Load and dispatch dynamic config from /config.json to redux global state.",
            "Override this config file in the production environment",
            config
          );
          store.dispatch({
            type: "CONFIG_SUCCESS",
            config: { ...config }
          });
        });
        store.dispatch({
          type: "FEDERATION_INFO_REQUEST",
          federationId: federationId
        });
        store.dispatch({
          type: "SHOUTOUT_REQUEST"
        });
        root.render(
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <Router>
                {authenticated === true ? (
                  <App federationId={federationId} config />
                ) : (
                  <div>Not authenticated</div>
                )}
              </Router>
            </ConnectedRouter>
          </Provider>
        );
        setInterval(
          () =>
            keycloak
              .updateToken()
              .then(refreshed => {
                console.info("TOKEN REFRESHED:", refreshed);
              })
              .catch(() => {
                console.info("LOGOUT IN SET INTERVAL");
                keycloak.login(window.location.origin + "/start.html");
              }),
          REFRESH_TOKEN_EVERY
        );
      });
  });
} else {
  window.location = "/start.html";
}
