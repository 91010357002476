export const LOAD_MEMBER_METADATA_LIST_REQUEST =
  "LOAD_MEMBER_METADATA_LIST_REQUEST";
export const LOAD_MEMBER_METADATA_LIST_SUCCESS =
  "LOAD_MEMBER_METADATA_LIST_SUCCESS";
export const LOAD_MEMBER_METADATA_LIST_FAILURE =
  "LOAD_MEMBER_METADATA_LIST_FAILURE";

export const LOAD_METADATA_VALIDATE_REQUEST = "LOAD_METADATA_VALIDATE_REQUEST";
export const LOAD_METADATA_VALIDATE_SUCCESS = "LOAD_METADATA_VALIDATE_SUCCESS";
export const LOAD_METADATA_VALIDATE_FAILURE = "LOAD_METADATA_VALIDATE_FAILURE";

export const LOAD_FEDERATION_METADATA_LIST_REQUEST =
  "LOAD_FEDERATION_METADATA_LIST_REQUEST";
export const LOAD_FEDERATION_METADATA_LIST_SUCCESS =
  "LOAD_FEDERATION_METADATA_LIST_SUCCESS";
export const LOAD_FEDERATION_METADATA_LIST_FAILURE =
  "LOAD_FEDERATION_METADATA_LIST_FAILURE";

export const UPLOAD_METADATA_REQUEST = "UPLOAD_METADATA_REQUEST";
export const UPLOAD_METADATA_SUCCESS = "UPLOAD_METADATA_SUCCESS";
export const UPLOAD_METADATA_FAILURE = "UPLOAD_METADATA_FAILURE";

export const LOAD_METADATA_REQUEST = "LOAD_METADATA_REQUEST";
export const LOAD_METADATA_SUCCESS = "LOAD_METADATA_SUCCESS";
export const LOAD_METADATA_FAILURE = "LOAD_METADATA_FAILURE";

export const UPDATE_METADATA_REQUEST = "UPDATE_METADATA_REQUEST";
export const UPDATE_METADATA_SUCCESS = "UPDATE_METADATA_SUCCESS";
export const UPDATE_METADATA_FAILURE = "UPDATE_METADATA_FAILURE";

export const DELETE_METADATA_REQUEST = "DELETE_METADATA_REQUEST";
export const DELETE_METADATA_SUCCESS = "DELETE_METADATA_SUCCESS";
export const DELETE_METADATA_FAILURE = "DELETE_METADATA_FAILURE";

export const APPROVE_METADATA_REQUEST = "APPROVE_METADATA_REQUEST";
export const APPROVE_METADATA_SUCCESS = "APPROVE_METADATA_SUCCESS";
export const APPROVE_METADATA_FAILURE = "APPROVE_METADATA_FAILURE";

export const LOAD_FEDERATION_MD_COUNT_REQUEST =
  "LOAD_FEDERATION_MD_COUNT_REQUEST";
export const LOAD_FEDERATION_MD_COUNT_SUCCESS =
  "LOAD_FEDERATION_MD_COUNT_SUCCESS";
export const LOAD_FEDERATION_MD_COUNT_FAILURE =
  "LOAD_FEDERATION_MD_COUNT_FAILURE";
