import { takeLatest } from "redux-saga/effects";

import {
  SEARCH_MEMBER_REQUEST,
  CREATE_MEMBER_REQUEST,
  UPDATE_MEMBER_REQUEST,
  ACTIVATE_MEMBER_REQUEST,
  UPDATE_TECHNICAL_CONTACT_REQUEST,
  DELETE_MEMBER_REQUEST,
  LOAD_MEMBER_REQUEST,
  VALIDATE_MEMBER_REQUEST,
  CREATE_TECHNICAL_AGENT_REQUEST,
  UPDATE_TECHNICAL_AGENT_REQUEST,
  DELETE_TECHNICAL_AGENT_REQUEST,
  LOAD_TECHNICAL_AGENT_REQUEST,
  LOAD_TECHNICAL_AGENTS_REQUEST
} from "../ducks/member/actionTyps";
import {
  fetchMember,
  validateMember,
  updateMember,
  activateMember,
  updateTechnicalContact,
  createMember,
  searchMember,
  deleteMember
} from "./member/member";

import {
  fetchTechnicalAgent,
  fetchTechnicalAgents,
  updateTechnicalAgent,
  createTechnicalAgent,
  deleteTechnicalAgent
} from "./member/technicalAgent";

//RESELLERS
import {
  LOAD_RESELLERS_REQUEST,
  LOAD_RESELLER_REQUEST,
  CREATE_RESELLER_REQUEST,
  UPDATE_RESELLER_REQUEST,
  DELETE_RESELLER_REQUEST,
  LOAD_RESELLER_USERS_REQUEST,
  CREATE_RESELLER_USER_REQUEST,
  DELETE_RESELLER_USER_REQUEST,
  UPDATE_RESELLER_USER_REQUEST
} from "../ducks/reseller/actionTypes";

import {
  fetchResellers,
  fetchReseller,
  createReseller,
  updateReseller,
  deleteReseller,
  fetchResellerUsers,
  createResellerUser,
  deleteResellerUser,
  updateResellerUser
} from "./reseller/reseller";

//METADATA
import {
  LOAD_MEMBER_METADATA_LIST_REQUEST,
  LOAD_FEDERATION_METADATA_LIST_REQUEST,
  LOAD_METADATA_VALIDATE_REQUEST,
  UPLOAD_METADATA_REQUEST,
  LOAD_METADATA_REQUEST,
  UPDATE_METADATA_REQUEST,
  DELETE_METADATA_REQUEST,
  APPROVE_METADATA_REQUEST,
  LOAD_FEDERATION_MD_COUNT_REQUEST
} from "../ducks/metadata/actionTypes";

import {
  fetchMemberMetadataList,
  fetchFederationMetadataList,
  // fetchFederationMembersMDCount,
  fetchFederationMDCount,
  fetchMetadataValidate,
  uploadMetadata,
  fetchMetadata,
  updateMetadata,
  deleteMetadata,
  approveMetadata
} from "./metadata/metadata";

//FEDERATION_INFO
import { FEDERATION_INFO_REQUEST } from "../ducks/federation/actionTypes";

import { fetchFederationInfo } from "./federation/federationInfo";

//CONTENT-ADMIN
import { SHOUTOUT_REQUEST } from "../ducks/contentadmin/actionTypes";

import { fetchShoutout } from "./contentadmin/shoutout";

export default function* rootSaga() {
  yield takeLatest(DELETE_MEMBER_REQUEST, deleteMember);
  yield takeLatest(UPDATE_MEMBER_REQUEST, updateMember);
  yield takeLatest(LOAD_METADATA_VALIDATE_REQUEST, fetchMetadataValidate);

  yield takeLatest(ACTIVATE_MEMBER_REQUEST, activateMember);
  yield takeLatest(UPDATE_TECHNICAL_CONTACT_REQUEST, updateTechnicalContact);
  yield takeLatest(LOAD_MEMBER_REQUEST, fetchMember);
  yield takeLatest(VALIDATE_MEMBER_REQUEST, validateMember);
  yield takeLatest(CREATE_MEMBER_REQUEST, createMember);
  yield takeLatest(SEARCH_MEMBER_REQUEST, searchMember);
  yield takeLatest(LOAD_TECHNICAL_AGENT_REQUEST, fetchTechnicalAgent);
  yield takeLatest(LOAD_TECHNICAL_AGENTS_REQUEST, fetchTechnicalAgents);
  yield takeLatest(CREATE_TECHNICAL_AGENT_REQUEST, createTechnicalAgent);
  yield takeLatest(UPDATE_TECHNICAL_AGENT_REQUEST, updateTechnicalAgent);
  yield takeLatest(DELETE_TECHNICAL_AGENT_REQUEST, deleteTechnicalAgent);
  yield takeLatest(LOAD_RESELLERS_REQUEST, fetchResellers);
  yield takeLatest(LOAD_RESELLER_REQUEST, fetchReseller);
  yield takeLatest(CREATE_RESELLER_REQUEST, createReseller);
  yield takeLatest(UPDATE_RESELLER_REQUEST, updateReseller);
  yield takeLatest(DELETE_RESELLER_REQUEST, deleteReseller);
  yield takeLatest(LOAD_RESELLER_USERS_REQUEST, fetchResellerUsers);
  yield takeLatest(CREATE_RESELLER_USER_REQUEST, createResellerUser);
  yield takeLatest(DELETE_RESELLER_USER_REQUEST, deleteResellerUser);
  yield takeLatest(UPDATE_RESELLER_USER_REQUEST, updateResellerUser);
  yield takeLatest(LOAD_MEMBER_METADATA_LIST_REQUEST, fetchMemberMetadataList);
  yield takeLatest(
    LOAD_FEDERATION_METADATA_LIST_REQUEST,
    fetchFederationMetadataList
  );
  yield takeLatest(UPLOAD_METADATA_REQUEST, uploadMetadata);
  yield takeLatest(LOAD_METADATA_REQUEST, fetchMetadata);
  yield takeLatest(UPDATE_METADATA_REQUEST, updateMetadata);
  yield takeLatest(DELETE_METADATA_REQUEST, deleteMetadata);
  yield takeLatest(APPROVE_METADATA_REQUEST, approveMetadata);
  yield takeLatest(FEDERATION_INFO_REQUEST, fetchFederationInfo);
  yield takeLatest(LOAD_FEDERATION_MD_COUNT_REQUEST, fetchFederationMDCount);
  yield takeLatest(SHOUTOUT_REQUEST, fetchShoutout);
}
