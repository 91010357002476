import {
  FEDERATION_INFO_FAILURE,
  FEDERATION_INFO_SUCCESS
} from "./actionTypes";
import { loadFailureReducer, loadSuccessReducer } from "../common/reducer";

export const federationInfoResp = (state = {}, action) => {
  switch (action.type) {
    case FEDERATION_INFO_SUCCESS:
      return loadSuccessReducer(state, action);
    case FEDERATION_INFO_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};
