import {
  SEARCH_MEMBER_SUCCESS,
  SEARCH_MEMBER_FAILURE,
  CREATE_MEMBER_SUCCESS,
  CREATE_MEMBER_WARNING,
  CREATE_MEMBER_FAILURE,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  ACTIVATE_MEMBER_SUCCESS,
  ACTIVATE_MEMBER_FAILURE,
  UPDATE_TECHNICAL_CONTACT_SUCCESS,
  UPDATE_TECHNICAL_CONTACT_FAILURE,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  LOAD_MEMBER_SUCCESS,
  LOAD_MEMBER_FAILURE,
  VALIDATE_MEMBER_SUCCESS,
  VALIDATE_MEMBER_FAILURE,
  LOAD_TECHNICAL_AGENTS_SUCCESS,
  LOAD_TECHNICAL_AGENTS_FAILURE,
  CREATE_TECHNICAL_AGENT_SUCCESS,
  CREATE_TECHNICAL_AGENT_FAILURE,
  UPDATE_TECHNICAL_AGENT_SUCCESS,
  UPDATE_TECHNICAL_AGENT_FAILURE,
  DELETE_TECHNICAL_AGENT_SUCCESS,
  DELETE_TECHNICAL_AGENT_FAILURE
} from "./actionTyps";
import {
  loadSuccessReducer,
  loadFailureReducer,
  loadSuccessReducerById,
  loadFailureReducerById,
  updateSuccessReducerById,
  updateFailureReducerById,
  deleteFailureReducerById,
  deleteSuccessReducerById,
  createFailureReducer,
  createSuccessReducer,
  createFailureReducerById,
  createSuccessReducerById
} from "../common/reducer";

export const memberSearchResp = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_MEMBER_SUCCESS:
      return loadSuccessReducer(state, action);
    case SEARCH_MEMBER_FAILURE:
      return loadFailureReducer(state, action);
    default:
      return state;
  }
};

export const memberFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_MEMBER_SUCCESS:
      return loadSuccessReducerById(state, action.customerId, action);
    case LOAD_MEMBER_FAILURE:
      return loadFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const memberValidateResp = (state = {}, action) => {
  switch (action.type) {
    case VALIDATE_MEMBER_SUCCESS:
      return loadSuccessReducerById(state, action.customerId, action);
    case VALIDATE_MEMBER_FAILURE:
      return loadFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const memberCreateResp = (state = {}, action) => {
  switch (action.type) {
    case CREATE_MEMBER_SUCCESS:
      return createSuccessReducerById(state, action.createMemberId, action);
    case CREATE_MEMBER_WARNING:
      return createSuccessReducerById(state, action.createMemberId, action);
    case CREATE_MEMBER_FAILURE:
      return createFailureReducerById(state, action.createMemberId, action);
    default:
      return state;
  }
};

export const memberUpdateResp = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MEMBER_SUCCESS:
      return updateSuccessReducerById(state, action.customerId, action);
    case UPDATE_MEMBER_FAILURE:
      return updateFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const memberActivateResp = (state = {}, action) => {
  switch (action.type) {
    case ACTIVATE_MEMBER_SUCCESS:
      return updateSuccessReducerById(state, action.customerId, action);
    case ACTIVATE_MEMBER_FAILURE:
      return updateFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const technicalContactUpdateResp = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TECHNICAL_CONTACT_SUCCESS:
      return updateSuccessReducerById(state, action.userId, action);
    case UPDATE_TECHNICAL_CONTACT_FAILURE:
      return updateFailureReducerById(state, action.userId, action);
    default:
      return state;
  }
};

export const memberDeleteResp = (state = {}, action) => {
  switch (action.type) {
    case DELETE_MEMBER_SUCCESS:
      return deleteSuccessReducerById(state, action.customerId, action);
    case DELETE_MEMBER_FAILURE:
      return deleteFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const technicalAgentsFetchResp = (state = {}, action) => {
  switch (action.type) {
    case LOAD_TECHNICAL_AGENTS_SUCCESS:
      return loadSuccessReducerById(state, action.customerId, action);
    case LOAD_TECHNICAL_AGENTS_FAILURE:
      return loadFailureReducerById(state, action.customerId, action);
    default:
      return state;
  }
};

export const technicalAgentCreateResp = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TECHNICAL_AGENT_SUCCESS:
      return createSuccessReducer(state, action);
    case CREATE_TECHNICAL_AGENT_FAILURE:
      return createFailureReducer(state, action);
    default:
      return state;
  }
};

export const technicalAgentUpdateResp = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TECHNICAL_AGENT_SUCCESS:
      return updateSuccessReducerById(state, action.userId, action);
    case UPDATE_TECHNICAL_AGENT_FAILURE:
      return updateFailureReducerById(state, action.userId, action);
    default:
      return state;
  }
};

export const technicalAgentDeleteResp = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TECHNICAL_AGENT_SUCCESS:
      return deleteSuccessReducerById(state, action.userId, action);
    case DELETE_TECHNICAL_AGENT_FAILURE:
      return deleteFailureReducerById(state, action.userId, action);
    default:
      return state;
  }
};
