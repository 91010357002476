import React from "react";
import { Message, Icon } from "semantic-ui-react";
import { FedTLSErrorDataView } from "../upload/FedTLSErrorDataView";

export class MetadataValidationStatus extends React.Component {
  constructor({ entityId }) {
    super();
    this.state = { entityId: entityId };
  }

  componentDidMount() {
    this.loadAndValidateMetadata();
    this.props.onChange("loading", true);
  }

  UNSAFE_componentWillReceiveProps({
    federationMetadataValidateFetchResp,
    entityId
  }) {
    if (entityId) {
      if (this.state.entityId !== entityId) {
        this.loadAndValidateMetadata();
        this.setState({ entityId: entityId, loading: true });
      }
    }
    if (entityId && federationMetadataValidateFetchResp) {
      if (
        federationMetadataValidateFetchResp.type ===
        "LOAD_METADATA_VALIDATE_SUCCESS"
      ) {
        const { data } = federationMetadataValidateFetchResp;
        if (data) {
          const { valid, validationErrors, entityId, fedType } = data;
          const validationStatus = {
            fedType: fedType,
            valid: valid,
            validationErrors: validationErrors
          };
          this.setState({
            loading: false,
            entityValidationStatus: {
              [entityId]: validationStatus
            }
          });
        }
      } else if (
        federationMetadataValidateFetchResp.type ===
        "LOAD_METADATA_VALIDATE_FAILURE"
      ) {
        this.setState({
          loading: false,
          validationError: true
        });
      } else {
        this.setState({
          loading: false
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.state.loading !== undefined) {
      this.props.onChange("loading", this.state.loading);
    }
  }

  loadAndValidateMetadata() {
    const {
      loadMetadataValidateRequest,
      federationId,
      entityId,
      customerId,
      approval = undefined
    } = this.props;
    if (!federationId || !customerId || !entityId) {
      console.info(
        "Props not set!! (check federationId, customerId or entityId"
      );
    }
    if (loadMetadataValidateRequest) {
      loadMetadataValidateRequest({
        federationId: federationId,
        customerId: customerId,
        entityId: entityId,
        approval: approval
      });
    } else {
      console.info("ERROR redux action loadMetadataValidateRequest not fund!");
    }
  }

  isMetadataValid(entityId) {
    return (
      this.state.entityValidationStatus &&
      this.state.entityValidationStatus[entityId] &&
      this.state.entityValidationStatus[entityId].valid === true
    );
  }
  getValidationStatus(entityId) {
    return (
      this.state.entityValidationStatus &&
      this.state.entityValidationStatus[entityId]
    );
  }
  getValidationErrorsByEntityId(entityId) {
    if (!entityId) {
      console.error("Error entityId should not be null");
      return;
    }
    if (!this.state.entityValidationStatus[entityId].validationErrors) {
      console.error("Error validationErrors should not be null");
      return;
    }
    return Object.keys(
      this.state.entityValidationStatus[entityId].validationErrors
    );
  }

  getValidationErrorsByEntityIdAndValidatorKey(entityId, validatorKey) {
    if (!entityId || !validatorKey) {
      console.error("Error entityId or validatorKey should not be null");
      return;
    }
    return this.state.entityValidationStatus[entityId].validationErrors[
      validatorKey
    ];
  }

  render() {
    const { entityId } = this.state;
    return (
      <React.Fragment>
        {!this.getValidationStatus(entityId) && (
          <Message
            warning
            header={
              <div className="header">
                <span>Wait...</span> <Icon name="info circle" />
              </div>
            }
            content={
              <div style={{ maxWidth: "600px" }}>
                <p>{`Checking metadata for current validation status...`}</p>
              </div>
            }
          />
        )}
        {this.isMetadataValid(entityId) === false && (
          <Message
            warning
            header={
              <div className="header">
                <span>This metadata file is not valid!</span>{" "}
                <Icon name="warning sign" />
              </div>
            }
            content={
              <div style={{ maxWidth: "600px" }}>
                <p>{`We have discovered that in your most recent publication, 
                  your metadata does not validate against the current schema 
                  and we want you to upload an updated metadata file.
                `}</p>

                {this.getValidationErrorsByEntityId(entityId) &&
                  this.getValidationErrorsByEntityId(entityId).map(
                    validatorKey => (
                      <div>
                        <h3>{validatorKey}:</h3>
                        <ul>
                          {this.getValidationErrorsByEntityIdAndValidatorKey(
                            entityId,
                            validatorKey
                          ).map((item, index) => (
                            <li key={index}>
                              {this.getValidationStatus(entityId).fedType ===
                                "SAML" && <span>{item}</span>}
                              {this.getValidationStatus(entityId).fedType ===
                                "FedTLS" && (
                                <FedTLSErrorDataView
                                  data={item}
                                  verbose={false}
                                />
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  )}
              </div>
            }
          />
        )}

        {this.state.validationError && this.state.validationError === false && (
          <Message
            warning
            header={
              <div className="header">
                <span>Something went wrong!!</span> <Icon name="warning sign" />
              </div>
            }
            content={
              <div style={{ maxWidth: "600px" }}>
                <p>{`Something went wrong while trying to validate the metadata file, try later or activate anyway.`}</p>
              </div>
            }
          />
        )}

        {this.state.validationError && this.state.validationError === false && (
          <Message
            warning
            header={
              <div className="header">
                <span>Something went wrong!!</span> <Icon name="warning sign" />
              </div>
            }
            content={
              <div style={{ maxWidth: "600px" }}>
                <p>{`Something went wrong while trying to validate the metadata file, try later or activate anyway.`}</p>
              </div>
            }
          />
        )}

        {this.isMetadataValid(entityId) === true && (
          <Message
            header={
              <div className="header">
                <span>This metadata is valid!</span>{" "}
                <Icon name="check circle" color="green" />
              </div>
            }
            content={
              <div style={{ maxWidth: "600px", color: "black" }}>
                <p>{`Metadata is valid agains the current schema rule set.`}</p>
              </div>
            }
          />
        )}
      </React.Fragment>
    );
  }
}

export default MetadataValidationStatus;
