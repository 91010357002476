import React from "react";

import { Accordion, Icon, Container } from "semantic-ui-react";

export class JsonView extends React.Component {
  constructor({ title = "View json data", json = {} }) {
    super();
    this.state = { activeIndex: -1, json: json, title };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick({ activeIndex }) {
    const newVal = activeIndex === 0 ? -1 : -0;
    this.setState({ activeIndex: newVal });
  }
  componentDidMount() {
    const { json = {} } = this.props;
    this.setState({ json });
  }
  UNSAFE_componentWillReceiveProps({ json = {} }) {
    this.setState({ json: { ...json } });
  }
  render = () => {
    const { title, activeIndex, json = {} } = this.state;
    return (
      <Accordion>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={() => this.handleClick({ activeIndex })}
        >
          <Icon name="dropdown" />
          {title}
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <Container>
            <pre>{JSON.stringify(json, null, 2)}</pre>
          </Container>
        </Accordion.Content>
      </Accordion>
    );
  };
}
export default JsonView;
