import React from "react";
import {
  Button,
  Segment,
  SegmentGroup,
  Header,
  Modal,
  Form,
  Dimmer,
  Loader
} from "semantic-ui-react";
import PropTypes from "prop-types";
import { UserForm } from "./UserForm";
import {
  ErrorMessage,
  WarningMessageWrappedAsList
} from "../../../common/error";
import { validatePnr } from "../../../helpers";
import { DetermineRealmTrial } from "../../../utils/common/DetermineRealmTrial";
import { JsonView } from "../../../helpers/json/JsonView";

export class TechnicalAgentFormView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      saveText: props.saveText,
      customerId: props.customerId,
      formOrginal: props.technicalAgent,
      form: props.technicalAgent,
      handleAdd: props.handleAdd,
      handleUpdate: props.handleUpdate,
      federationId: props.federationId,
      formIsDirty: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      ssnRequired: DetermineRealmTrial(this.props.federationId)
      // ssnValid: ValidatePnr(this.props.form.ssn).valid,
    });
  }

  UNSAFE_componentWillReceiveProps({
    technicalAgentCreateResp,
    technicalAgentUpdateResp
  }) {
    const { modalOpen } = this.state;
    if (modalOpen) {
      if (technicalAgentCreateResp) {
        this.loadTechnicalAgentCreateResp({
          technicalAgentCreateResp
        });
      }
      if (technicalAgentUpdateResp) {
        this.loadTechnicalAgentUpdateResp({
          technicalAgentUpdateResp
        });
      }
    }
  }

  handleChange(event) {
    let { name, value } = event.target;
    let validSSN = {}; // result from pnr validate
    let validSSNInput = true;

    event.preventDefault();

    if (name === "ssn" && value !== undefined) {
      validSSN = validatePnr(value, {
        strict: true,
        forgiving: true
      });
      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && value.length < 12) {
        validSSNInput = false;
      }
    } else if (this.state.form.ssn) {
      validSSN = validatePnr(this.state.form.ssn, {
        strict: true,
        forgiving: true
      });
      validSSNInput = validSSN && validSSN.valid === true;
      if (validSSNInput && this.state.form.ssn.length < 12) {
        validSSNInput = false;
      }
    }

    this.setState({
      formIsDirty: true,
      ssnValid: validSSNInput,
      form: {
        ...this.state.form,
        ...{ [name]: value }
      }
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
      formIsDirty: false
    });
    if (this.state.form.userId) {
      this.updateTechnicalAgent(this.state.form.userId);
    } else {
      this.createTechnicalAgent();
    }
  }

  loadTechnicalAgentCreateResp({ technicalAgentCreateResp }) {
    if (technicalAgentCreateResp) {
      const resp = technicalAgentCreateResp;
      if (resp.created) {
        this.setState({ loading: false });
        this.handleClose();
        this.props.reloadData(this.state.customerId);
      } else if (!resp.created) {
        this.setState({
          technicalAgentCreateSuccess: undefined,
          technicalAgentCreateError: resp.error,
          loading: false
        });
      }
    }
  }

  loadTechnicalAgentUpdateResp({ technicalAgentUpdateResp }) {
    if (technicalAgentUpdateResp) {
      const {
        technicalAgentUpdateSuccess,
        technicalAgentUpdateError
      } = this.state;
      const { userId } = this.state.form;
      const resp = technicalAgentUpdateResp[userId];
      if (resp && resp.updated && resp.data !== technicalAgentUpdateSuccess) {
        this.setState({
          technicalAgentUpdateSuccess: resp.data,
          technicalAgentUpdateError: undefined,
          formIsDirty: false,
          loading: false,
          form: { ...technicalAgentUpdateSuccess },
          formOrginal: { ...technicalAgentUpdateSuccess }
        });
        this.handleClose();
        this.props.reloadData();
      } else if (
        resp &&
        !resp.updated &&
        resp.error !== technicalAgentUpdateError
      ) {
        this.setState({
          technicalAgentUpdateSuccess: undefined,
          technicalAgentUpdateError: resp.error,
          loading: false
        });
      }
    }
  }

  updateTechnicalAgent = () => {
    this.props.updateTechnicalAgentRequest({
      federationId: this.state.federationId,
      customerId: this.state.customerId,
      userId: this.state.form.userId,
      data: { ...this.state.form }
    });
  };

  createTechnicalAgent = () => {
    this.props.createTechnicalAgentRequest({
      federationId: this.state.federationId,
      customerId: this.state.customerId,
      data: { ...this.state.form }
    });
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => {
    // form input is cached and form data needs to be roledback to original form state,
    // So original form sate is stored in this.state.formOrginal.
    let form;
    if (this.state.form.userId) {
      form =
        this.state.formIsDirty === true
          ? this.state.formOrginal
          : this.state.form;
    } else {
      form = {};
    }
    this.props.reloadData(this.state.customerId);
    this.setState({
      modalOpen: false,
      technicalAgentCreateSuccess: undefined,
      technicalAgentCreateError: undefined,
      form: form,
      formIsDirty: false
    });
  };

  render() {
    const {
      title,
      saveText = "Save",
      form,
      updateError,
      technicalAgentCreateError,
      technicalAgentUpdateError,
      formIsDirty,
      ssnRequired,
      ssnValid,
      loading
    } = this.state;

    let showButton = false;
    if (ssnRequired && form && form.ssn) {
      showButton = formIsDirty && ssnValid;
    } else if (formIsDirty && form && form.ssn) {
      showButton = formIsDirty && ssnValid;
    } else if (formIsDirty || (form && form.ssn)) {
      showButton = formIsDirty;
    }

    return (
      <Modal
        trigger={
          <Button secondary onClick={this.handleOpen}>
            {title}
          </Button>
        }
        open={this.state.modalOpen}
        onClose={this.handleClose}
        closeIcon
      >
        <Modal.Content image>
          <Modal.Description>
            <Header as="h1">{title}</Header>
            {false && <JsonView title="STATE" json={this.state} />}
            {false && <JsonView title="PROPS" json={this.props} />}
            <SegmentGroup disabled={true}>
              <Segment>
                {technicalAgentUpdateError && (
                  <ErrorMessage
                    title="Update technical agent failed!"
                    {...technicalAgentUpdateError}
                  />
                )}
                {technicalAgentCreateError &&
                  technicalAgentCreateError.statusCode === 400 && (
                    <WarningMessageWrappedAsList
                      title="Warning! Technical agent created!"
                      {...technicalAgentCreateError}
                    />
                  )}
                {technicalAgentCreateError &&
                  technicalAgentCreateError.statusCode !== 400 && (
                    <ErrorMessage
                      title="Failed to create Technical Agent!"
                      {...technicalAgentCreateError}
                    />
                  )}
                <Form
                  onSubmit={this.handleSubmit} // error={error !== undefined}
                >
                  <UserForm
                    form={{ technicalContact: form }}
                    error={updateError}
                    handleChange={this.handleChange}
                    req={ssnRequired}
                  />
                  <Segment>
                    {loading && (
                      <Dimmer active inverted>
                        <Loader active inline />
                      </Dimmer>
                    )}
                    {technicalAgentCreateError && (
                      <Button type="close" primary onClick={this.handleClose}>
                        Close
                      </Button>
                    )}
                    {!technicalAgentCreateError && (
                      <React.Fragment>
                        <Button type="submit" primary disabled={!showButton}>
                          {saveText}
                        </Button>
                        <Button
                          type="cancel"
                          secondary
                          onClick={this.handleClose}
                        >
                          Cancel
                        </Button>
                      </React.Fragment>
                    )}
                  </Segment>
                </Form>
              </Segment>
            </SegmentGroup>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

TechnicalAgentFormView.propTypes = {
  title: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  form: PropTypes.object
};

export default TechnicalAgentFormView;
