import React from "react";
import { MemberFormView } from "./MemberFormView";
import { MemberFormEditView } from "./MemberFormEditView";
import { TechnicalAgentFormView } from "./TechnicalAgentFormView";
import { TechnicalAgentDeleteView } from "./TechnicalAgentDeleteView";

const MemberFormCreateView = props => (
  <MemberFormView title="Add member" saveText="Save" {...props} />
);
const MemberFormUpdateView = ({ success = null, error = null }, props) => (
  <MemberFormEditView
    title="Edit member"
    saveText="Save"
    form={{ ...success }}
    {...props}
  />
);

export {
  MemberFormCreateView,
  MemberFormUpdateView,
  TechnicalAgentFormView,
  TechnicalAgentDeleteView,
  MemberFormView,
  MemberFormEditView
};
