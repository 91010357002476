import React from "react";

export const FedTLSErrorDataView = ({ data, verbose = true }) => {
  const dataJson =
    data !== undefined && data.constructor === String
      ? JSON.parse(data)
      : data.constructor === Object
      ? data
      : undefined;
  return (
    <ul>
      {dataJson === undefined && <div>Could not parse error message!</div>}
      {dataJson &&
        dataJson.causingExceptions &&
        dataJson.causingExceptions.map((child, index) => {
          return (
            <React.Fragment key={index}>
              {child.message && (
                <li>
                  <span>Message: </span>
                  <span>{child.message}</span>
                </li>
              )}
              {child.schemaLocation && (
                <li>
                  <span>Location: </span>
                  <span>{child.schemaLocation}</span>
                </li>
              )}
              {verbose === true && child.pointerToViolation && (
                <li>
                  <span>Pointer: </span>
                  <span>{child.pointerToViolation}</span>
                </li>
              )}

              {child.causingExceptions && (
                <FedTLSErrorDataView data={child} verbose={verbose} />
              )}
            </React.Fragment>
          );
        })}
    </ul>
  );
};
