import { CONFIG_SUCCESS, CONFIG_FAILURE } from "./actionTypes";

export const dynamicConfig = (state = {}, action) => {
  switch (action.type) {
    case CONFIG_SUCCESS:
      return { ...state, ...{ enabled: true, ...action } };
    case CONFIG_FAILURE:
      return { ...state, ...{ enabled: false } };
    default:
      return state;
  }
};
