import React from "react";
import PropTypes from "prop-types";

import { Header, Table, Divider, Message } from "semantic-ui-react";
import { JsonView } from "../../../helpers/json/JsonView";
import { ResellerUserFormView } from "../form";
import { ResellerUserDeleteView } from "../form";
import { authorize } from "../../../common/auth/authorization";
import * as authMapping from "../../../common/auth/authConstants";

export class ResellerUserTableView extends React.Component {
  constructor(props) {
    super();

    this.state = {
      mock: false,
      prefixCode: props.prefixCode,
      federationId: props.federationId,
      roles: props.userSession.roles
    };
    this.handleResellerUserTableReload = this.handleResellerUserTableReload.bind(
      this
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { resellerUsersFetchResp, resellerUserCreateResp } = nextProps;
    this.loadData({
      resellerUsersFetchResp,
      resellerUserCreateResp
    });
  }

  componentDidMount = () => {
    if (this.props.prefixCode) {
      this.fetchResellerUsers(this.props.prefixCode);
    }
  };

  fetchResellerUsers = prefixCode => {
    if (this.state.mock) {
      this.setState({
        resellerUsers: [
          {
            name: "Test Name",
            email: "mail@mail.se",
            phone: "023123456",
            active: "true"
          }
        ],
        total: 1
      });
    } else {
      const { federationId } = this.state;
      this.props.loadResellerUsersRequest({ federationId, prefixCode });
    }
  };

  loadData({ resellerUsersFetchResp, resellerUserUpdateResp }) {
    if (resellerUsersFetchResp && resellerUsersFetchResp.fetched) {
      if (resellerUsersFetchResp.fetched) {
        const data = resellerUsersFetchResp.data;
        this.setState({
          resellerUsers: data
        });
      } else {
        this.setState({
          fetchResellersError: resellerUsersFetchResp.error.statusMessage
        });
      }
    }
    if (
      resellerUserUpdateResp &&
      resellerUserUpdateResp.updated !== undefined
    ) {
      this.setState({ resellerUserUpdateResp });
    }
  }

  createResellerUser = data => {
    const { prefixCode, federationId } = this.state;
    this.props.createResellerUserRequest({
      federationId,
      prefixCode,
      data
    });
  };
  updateResellerUser = (data, userId) => {
    const { prefixCode, federationId } = this.state;
    this.props.updateResellerUserRequest({
      federationId,
      prefixCode,
      userId,
      data
    });
  };

  handleOpen = () => {
    this.child.handleOpen();
  };

  handleResellerUserTableReload() {
    const { prefixCode, federationId } = this.state;
    this.props.loadResellerUsersRequest({ federationId, prefixCode });
  }

  render() {
    const {
      resellerUsers,
      prefixCode,
      saveResellerUserSuccess,
      saveResellerUserError,
      federationId,
      roles
    } = this.state;

    return (
      <React.Fragment>
        {false && <JsonView title="Component state:" json={this.state} />}
        <Header as="h2">Reseller Users</Header>
        {!resellerUsers ||
          (resellerUsers.length === 0 && (
            <Message>
              Add reseller users.
              <Divider hidden />
            </Message>
          ))}
        {resellerUsers && resellerUsers.length > 0 && (
          <Table celled striped selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell key={1} textAlign="center">
                  RESELLER USER NAME
                </Table.HeaderCell>
                <Table.HeaderCell key={2} textAlign="center">
                  RESELLER USER EMAIL
                </Table.HeaderCell>

                <Table.HeaderCell key={3} textAlign="center">
                  RESELLER USER PHONE NUMBER
                </Table.HeaderCell>
                {authorize(
                  authMapping.UPDATE_RESELLER_USER,
                  roles,
                  <Table.HeaderCell key={4} textAlign="center">
                    EDIT
                  </Table.HeaderCell>
                )}

                {authorize(
                  authMapping.DELETE_RESELLER_USER,
                  roles,
                  <Table.HeaderCell key={5} textAlign="center">
                    DELETE
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {resellerUsers.map(
                ({ userId, firstName, lastName, email, phone }, index) => (
                  <Table.Row key={userId} style={{ cursor: "pointer" }}>
                    <Table.Cell textAlign="center">
                      {firstName} {lastName}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{email}</Table.Cell>
                    <Table.Cell textAlign="center">{phone}</Table.Cell>
                    {authorize(
                      authMapping.UPDATE_RESELLER_USER,
                      roles,
                      <Table.Cell textAlign="center">
                        <ResellerUserFormView
                          federationId={federationId}
                          userId={userId}
                          handleSave={this.updateResellerUser}
                          handleUpdateResp={this.handleResellerUserTableReload}
                          title="Edit reseller user"
                          saveText="Update"
                          form={resellerUsers[index]}
                          saveError={saveResellerUserError}
                          saveSuccess={saveResellerUserSuccess}
                          resellerUserUpdateResp={
                            this.props.resellerUserUpdateResp
                          }
                        />
                      </Table.Cell>
                    )}

                    {authorize(
                      authMapping.DELETE_RESELLER_USER,
                      roles,
                      <Table.Cell textAlign="center">
                        <ResellerUserDeleteView
                          floated="right"
                          userId={userId}
                          prefixCode={prefixCode}
                          name={`${firstName} ${lastName}`}
                          handleDelete={this.handleResellerUserTableReload}
                          deleteResellerUserRequest={
                            this.props.deleteResellerUserRequest
                          }
                          resellerUserDeleteResp={
                            this.props.resellerUserDeleteResp
                          }
                          federationId={federationId}
                        />
                      </Table.Cell>
                    )}
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        )}
        {authorize(
          authMapping.CREATE_RESELLER_USER,
          roles,
          <ResellerUserFormView
            federationId={federationId}
            handleSave={this.createResellerUser}
            handleCreateResp={this.handleResellerUserTableReload}
            title="Add reseller user"
            saveText="Save"
            form={{}}
            resellerUserCreateResp={this.props.resellerUserCreateResp}
          />
        )}
      </React.Fragment>
    );
  }
}

ResellerUserTableView.propTypes = {
  prefixCode: PropTypes.string
};

export default ResellerUserTableView;
