const federationoperator = "federationoperator",
  superadmin = "superadmin",
  reselleruser = "reselleruser",
  technicalcontact = "technicalcontact",
  technicalagent = "technicalagent";

// superadmin is not yet implemented

//Federation users
export const CREATE_FEDERATION_USER = [federationoperator, superadmin];
export const READ_FEDERATION_USER = [federationoperator, superadmin];
export const UPDATE_FEDERATION_USER = [federationoperator, superadmin];
export const DELETE_FEDERATION_USER = [federationoperator, superadmin];

//Federations
export const CREATE_FEDERATION = [superadmin];
export const READ_FEDERATION = [superadmin];
export const UPDATE_FEDERATION = [superadmin];
export const DELETE_FEDERATION = [superadmin];

//Reseller users
export const LIST_RESELLER_USER = [
  reselleruser,
  federationoperator,
  superadmin
];
export const CREATE_RESELLER_USER = [federationoperator, superadmin];
export const READ_RESELLER_USER = [
  reselleruser,
  federationoperator,
  superadmin
];
export const UPDATE_RESELLER_USER = [federationoperator, superadmin];
export const DELETE_RESELLER_USER = [federationoperator, superadmin];

//Resellers
export const LIST_RESELLER = [reselleruser, federationoperator, superadmin];
export const CREATE_RESELLER = [federationoperator, superadmin];
export const READ_RESELLER = [reselleruser, federationoperator, superadmin];
export const UPDATE_RESELLER = [federationoperator, superadmin];
export const DELETE_RESELLER = [federationoperator, superadmin];

//Members
export const ACTIVATE_MEMBER = [reselleruser, federationoperator, superadmin];

export const LIST_MEMBER = [
  technicalagent,
  technicalcontact,
  reselleruser,
  federationoperator,
  superadmin
];
export const CREATE_MEMBER = [federationoperator, superadmin];
export const READ_MEMBER = [
  technicalagent,
  technicalcontact,
  reselleruser,
  federationoperator,
  superadmin
];
export const UPDATE_MEMBER = [federationoperator, superadmin];
export const DELETE_MEMBER = [federationoperator, superadmin];

//Technical agents
export const CREATE_TECH_AGENT = [
  technicalcontact,
  reselleruser,
  federationoperator,
  superadmin
];
export const DELETE_TECH_AGENT = [
  technicalcontact,
  reselleruser,
  federationoperator,
  superadmin
];

export const UPDATE_TECH_AGENT = [
  technicalcontact,
  reselleruser,
  federationoperator,
  superadmin
];

//Metadata
export const HANDLE_METADATA = [
  technicalagent,
  technicalcontact,
  reselleruser,
  federationoperator,
  superadmin
];

export const APPROVE_METADATA = [federationoperator, superadmin];

//Admin
export const READ_ADMIN = [federationoperator];
